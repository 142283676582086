import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: false,
  success: "",
  message: "",
  errMsg: "",
  token: "",
  twoFactor: "",
  verified: false,
};

export const Login = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        message: "Logged in Successfully",
        token: action.payload.token,
        twoFactor: action.payload.isTwoFAEnabled,
        email: action.payload.clientInformation.email,
      };

    case ActionTypes.LOGIN_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_EMAIL:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
      };

    case ActionTypes.FAIL_VERIFY_EMAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        message: action.payload.message,
      };

    case ActionTypes.VERIY_TWO_FACTOR:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        message: action.payload.message,
        token: action.payload.token,
        twoFactor: action.payload.isTwoFAEnabled,
        email: action.payload.clientInformation.email,
        verified: true,
      };

    case ActionTypes.FAIL_VERIFY_TWO_FACTOR:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
        verified: false,
      };

    case ActionTypes.REMOVE_LOGIN:
      return {
        message: "",
        errMsg: "",
        twoFactor: "",
        success: false,
      };
    default:
      return state;
  }
};
