import React, { useEffect, useState } from "react";
import edit from "../../Assets/Icons/edit-2.svg";
import Button from "../../Component/Button/Button";
import questionMarkIcon from "../../Assets/Icons/arrow-up.svg";
import { shallowEqual, useSelector } from "react-redux";
import Message from "../../Component/Message/Message";
import { MdDeleteForever } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";

const SecurityModal = ({
  setTwoFactorModal,
  setPasswordModal,
  setEditBiddingPasswordModal,
  setTwoFactorDisablePopupModal,
  setAddBiddingPasswordModal,
  setRemoveBiddingPasswordPopupModal,
  myInfo,
}) => {
  const [msg, setMsg] = useState();
  const message = useSelector((state) => state.twoFactor.message, shallowEqual);

  useEffect(() => {
    setMsg(message);
  }, [message]);

  // add two factor authentication
  const handleTwoFactor = () => {
    setTwoFactorModal(true);
    window.scrollTo(0, 0);
  };

  // remove two factor button click
  const handleRemoveTwoFactor = () => {
    setTwoFactorDisablePopupModal(true);
    window.scrollTo(0, 0);
  };

  // delete bidding password button
  const handleDeleteBiddingPassword = () => {
    setRemoveBiddingPasswordPopupModal(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="">
      {message && (
        <Message
          className="text-green-500 mt-6 text-3xl font-bold"
          message={msg}
          setMessage={setMsg}
        />
      )}
      {/* password */}
      <div className="flex items-center justify-between w-full md:w-2/4 mt-10">
        <div className="flex items-center gap-x-3">
          <h5 className="font-bold text-primary text-lg md:text-2xl">
            Password
          </h5>
          <img
            src={questionMarkIcon}
            alt="question mark"
            className="w-5 h-5 md:w-6 md:h-6"
          />
        </div>
        <div
          className="flex items-center gap-x-2 cursor-pointer"
          onClick={() => setPasswordModal(true)}
        >
          <img src={edit} alt="edit icon" />
          <p className="text-primary font-medium">Edit</p>
        </div>
      </div>
      <div className="flex w-1/4 mt-8 justify-between items-center">
        <h1 className="text-secondary">Password</h1>

        <div className="flex items-center gap-x-5">
          <p>:</p>
          <p className="text-xl font-bold">xxxxxxxxxxxxxxxx</p>
        </div>
      </div>

      <hr className="mt-14" />

      {/* bidding password */}
      <div className="flex items-center justify-between w-full md:w-2/4 mt-12">
        <div className="flex items-center gap-x-3">
          <h5 className="font-bold text-primary text-lg md:text-2xl">
            Bidding Password
          </h5>
          <img
            src={questionMarkIcon}
            alt="question mark"
            className="w-5 h-5 md:w-6 md:h-6"
          />
        </div>

        {myInfo.isBiddingPasswordEnabled ? (
          <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => setEditBiddingPasswordModal(true)}
          >
            <img src={edit} alt="edit icon" />
            <p className="text-primary font-medium">Edit</p>
          </div>
        ) : (
          <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => setAddBiddingPasswordModal(true)}
          >
            <IoIosAddCircleOutline size={25} className="text-primary" />
            <p className="text-primary font-medium">Add</p>
          </div>
        )}
      </div>
      <div className="flex w-11/12 md:w-1/2 lg:w-1/3 mt-8 justify-between items-center">
        <h1 className="text-secondary">Password</h1>
        <div className="flex items-center justify-between w-full gap-x-14 md:gap-x-20">
          <div className="flex items-center gap-x-5">
            <p>:</p>
            <p className="text-xl font-bold">xxxxxxxxxxxxxxxx</p>
          </div>
          {myInfo.isBiddingPasswordEnabled && (
            <MdDeleteForever
              className="text-2xl text-red-500 cursor-pointer"
              onClick={handleDeleteBiddingPassword}
            />
          )}
        </div>
      </div>

      <hr className="mt-14" />

      {/* two factor authentication */}
      <div className="flex items-center justify-between w-full md:w-2/4 mt-12">
        <div className="flex items-center gap-x-3">
          <h5 className="font-bold text-primary text-lg md:text-2xl">
            Two Factor Authentication
          </h5>
          <img
            src={questionMarkIcon}
            alt="question mark"
            className="w-5 h-5 md:w-6 md:h-6"
          />
        </div>
      </div>

      {myInfo?.isTwoFAEnabled ? (
        <Button
          value="Disable"
          className="border font-medium rounded-2xl  py-4 px-7 mt-10 border-red-500 text-red-500"
          handleClick={handleRemoveTwoFactor}
        />
      ) : (
        <Button
          value="Setup Authentication"
          className={`border border-primary text-primary font-medium rounded-2xl  py-4 px-7 mt-10`}
          handleClick={handleTwoFactor}
        />
      )}
    </div>
  );
};

export default SecurityModal;
