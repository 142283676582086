import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import Message from "../../../Component/Message/Message";
import {
  fetchAddBiddingPassword,
  removeProfileData,
} from "../../../Redux/Profile/action";

const BiddingPasswordModalPopup = ({ setBiddingPasswordModal }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const dispatch = useDispatch();
  // retrieve cookie
  const token = Cookies.get("token");

  const errMsg = useSelector((state) => state.profile.errMsg, shallowEqual);
  const msg = useSelector((state) => state.profile.message, shallowEqual);

  useEffect(() => {
    setErrMessage(errMsg);
  }, [errMsg]);

  useEffect(() => {
    setSuccessMessage(msg);
  }, [msg]);

  // set formik initial values
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required *"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchAddBiddingPassword(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeProfileData());
    }, 5000);
  };

  useEffect(() => {
    successMessage && setBiddingPasswordModal(false);
  }, [successMessage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 left-4 md:left-[17%] lg:left-1/4 pt-10 pb-16">
      <div className="w-11/12 md:w-3/4 mx-auto">
        <h4 className="font-bold text-3xl text-primary text-center">
          Bidding Password
        </h4>

        {errMessage && (
          <Message
            className="text-red-500 mt-6"
            message={errMessage}
            setMessage={errMessage}
          />
        )}

        <div className="mt-10">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className=" w-full">
                  <h1 className="text-center text-red-500 text-sm font-bold">
                    Bidding Password must be a Number
                  </h1>
                  <div className="mt-5">
                    <FormikControl
                      label="Password"
                      placeholder="Enter Your Password"
                      control="password"
                      name="password"
                      type={`${showPassword ? "number" : "password"}`}
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  </div>

                  <div className="mt-5">
                    <FormikControl
                      label="Confirm password"
                      placeholder="Confirm Your Password"
                      control="password"
                      name="confirmPassword"
                      type={`${showConfirmPassword ? "number" : "password"}`}
                      setShowPassword={setShowConfirmPassword}
                      showPassword={showConfirmPassword}
                    />
                  </div>
                  <div className="flex justify-between items-center gap-x-10 mt-8">
                    <div
                      className="border w-full text-sm md:text-base rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                      style={{ background: "#EBF0FA" }}
                      onClick={() => setBiddingPasswordModal(false)}
                    >
                      <Button value="Cancel" />
                    </div>

                    <div className="border w-full rounded-2xl text-sm md:text-base bg-primary cursor-pointer text-white font-medium py-4 text-center">
                      <Button value="Save Changes" type="submit" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BiddingPasswordModalPopup;
