import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const loginLoading = () => ({
  type: ActionTypes.LOGIN_LOADING,
});

export const removeLogin = () => ({
  type: ActionTypes.REMOVE_LOGIN,
});

export const fetchLogin = (body) => (dispatch) => {
  dispatch(loginLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/client/login`, body)
    .then(({ data }) => {
      Cookies.set("token", data.token, {
        expires: new Date(Date.now() + 5 * 60 * 60 * 1000),
        path: "/",
      });

      dispatch({
        type: ActionTypes.LOGIN,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.LOGIN_FAIL,
        payload: err.response.data,
      });
    });
};

export const fetchVerifyEmail = (body) => (dispatch) => {
  dispatch(loginLoading());
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/forgot-password`, body)
    .then(({ data }) => {
      if (data.isSuccess) {
        localStorage.setItem("verifyEmail", body.userName);
        dispatch({
          type: ActionTypes.VERIFY_EMAIL,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.FAIL_VERIFY_EMAIL,
        payload: err.response.data,
      });
    });
};

export const fetchResetPassword = (body) => (dispatch) => {
  dispatch(loginLoading());
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/reset-password`, body)
    .then(({ data }) => {
      if (data.isSuccess) {
        dispatch({
          type: ActionTypes.RESET_PASSWORD,
          payload: data,
        });
      }
    })
    .catch((err) => {
      console.log("errr", err);
    });
};

export const fetchVerifyUser = (body, token) => (dispatch) => {
  dispatch(loginLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/client/verify-two-fa-login`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      if (data.isSuccess) {
        Cookies.remove("token");
        Cookies.set("token", data.token);
        dispatch({
          type: ActionTypes.VERIY_TWO_FACTOR,
          payload: data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_VERIFY_TWO_FACTOR,
        payload: e.response.data,
      });
    });
};
