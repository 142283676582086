import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../Formik/Error";

const TextArea = ({ label, placeholder, name, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <Field
        id={name}
        name={name}
        {...rest}
        as="textarea"
        className="border border-gray-300 w-full p-2.5 h-36 text-base rounded-xl outline-gray-300"
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea;
