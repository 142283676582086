import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchVerifyEnableTwoFactor,
  removeTwoFactor,
} from "../../Redux/TwoFactor/action";
import Message from "../Message/Message";

const EnableTwoFactorOTP = ({ setOtpPopupModal }) => {
  const [otp, setOtp] = useState();
  const [errMsg, setErrMsg] = useState();

  const qrImage = useSelector((state) => state.twoFactor.qr, shallowEqual);

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const errMessage = useSelector(
    (state) => state.twoFactor.errMsg,
    shallowEqual
  );

  const verified = useSelector(
    (state) => state.twoFactor.verified,
    shallowEqual
  );

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const handleSubmit = () => {
    const data = {
      verificationCode: otp,
    };
    dispatch(fetchVerifyEnableTwoFactor(data, token));

    setTimeout(() => {
      dispatch(removeTwoFactor());
      setOtpPopupModal(false);
    }, [3000]);
  };

  useEffect(() => {
    if (verified === true) {
      setOtpPopupModal(false);
    }
  });

  return (
    <div className="absolute top-28 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 lg:w-2/5 pt-5 pb-12 left-4 md:left-[14%] lg:left-1/3">
      <h4 className="font-bold text-2xl text-primary px-5">
        Two Factor Authentication
      </h4>
      <hr className="w-11/12 mx-auto  mt-4" />

      {errMessage && (
        <Message
          className="text-red-500 mt-6"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}

      <div className="px-5 mt-3 mb-5">
        <p>
          Two-Factor Authentication provides an extra level of security to your
          Payroller login.
        </p>

        <p className="mt-4">
          1. Download Google Authenticator from{" "}
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            className="text-primary"
          >
            App Store{" "}
          </a>
          or{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ne&gl=US"
            className="text-primary"
          >
            Google Play{" "}
          </a>
        </p>
      </div>
      <div className="h-40 w-full mx-auto">
        <QRCode className="w-80 mx-auto h-full" value={qrImage} />
      </div>

      <div className="px-5 mt-5">
        <p>
          After scanning the QR Code, you will receive 6 digit code. You have to
          enter that code below.
        </p>

        <div className="mt-5">
          <input
            type="text"
            className="border w-full py-3 rounded-lg px-5 outline-none"
            placeholder="Enter Code"
            onChange={(e) => setOtp(e.target.value)}
          />

          <div className="w-11/12 mx-auto flex gap-x-10 items-center mt-8">
            <div
              className="rounded-2xl py-4 w-full text-center text-primary font-medium cursor-pointer"
              style={{ background: "#EBF0FA" }}
              onClick={() => {
                setOtpPopupModal(false);
              }}
            >
              Cancel
            </div>
            <div
              className="rounded-2xl py-4 w-full text-center bg-primary text-white font-medium cursor-pointer"
              onClick={handleSubmit}
            >
              Verify
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnableTwoFactorOTP;
