import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../Formik/Error";

const PhoneField = ({ label, placeholder, name, callingCode, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <div className="flex border border-gray-300 w-full px-2 py-2.5 text-base rounded-lg outline-gray-300 items-center gap-x-3">
        <div className="border-r px-2 flex items-center gap-x-2">
          <p>{callingCode}</p>
        </div>

        <Field
          id={name}
          name={name}
          {...rest}
          className="outline-none w-11/12"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default PhoneField;
