import React from "react";
import { BsImageAlt } from "react-icons/bs";

const NoImages = () => {
  return (
    <div
      className="w-full h-full flex justify-center items-center rounded-xl"
      style={{ background: "#F7F9FC" }}
    >
      <BsImageAlt className="text-5xl" style={{ color: "#BCCEDF" }} />
    </div>
  );
};

export default NoImages;

export const NoImagesFullRounded = () => {
  return (
    <div
      className="w-full h-full flex justify-center items-center rounded-full"
      style={{ background: "#F7F9FC" }}
    >
      <BsImageAlt className="text-2xl" style={{ color: "#BCCEDF" }} />
    </div>
  );
};
