import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  text: "",
  instagram: "",
  facebook: "",
  twitter: "",
  copyRight: "",
};

export const Footer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FOOTER_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        text: action.payload.logoSubText,
        copyRight: action.payload.copyRightText,
        facebook: action.payload.facebookLink,
        instagram: action.payload.instagramLink,
        twitter: action.payload.twitterLink,
      };

    default:
      return state;
  }
};
