import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const aboutUsLoading = () => ({
  type: ActionTypes.ABOUT_US_LOADING,
});

export const removeData = () => ({
  type: ActionTypes.REMOVE_ABOUT_US_DATA,
});

export const fetchGetAboutusData = () => (dispatch) => {
  dispatch(aboutUsLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/about-us/get-all`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ABOUT_US_DATA,
        payload: data,
      });
    });
};
