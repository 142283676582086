import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import error from "../../Assets/Images/404.png";
import Button from "../../Component/Button/Button";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleNotFound = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="pt-40 pb-20">
      <Helmet>
        <title>404 | Bidyl</title>
      </Helmet>
      <div className="w-2/3 mx-auto h-96">
        <img src={error} alt="404 not found" className="w-full h-full" />
      </div>

      <div
        className="w-fit mx-auto px-9 cursor-pointer bg-primary text-white rounded-lg py-4 text-center"
        onClick={handleNotFound}
      >
        <Button
          value="Go Back Home"
          className="uppercase font-medium tracking-wider"
        />
      </div>
    </div>
  );
};

export default PageNotFound;
