import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import searchIcon from "../../Assets/Icons/search.svg";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetMyBid,
  fetchSearchMyBid,
  removeProfileData,
} from "../../Redux/Profile/action";
import { convertDate } from "../../Utls/Moment";
import noBid from "../../Assets/Images/empty.svg";
import SinglePagnination from "../../Component/Pagination/SinglePagnination";
import Pagination from "../../Component/Pagination/Pagination";

const MyBidsModal = () => {
  const [searchBid, setSearchBid] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("descending");
  const [sortParameter, setSortParameter] = useState("id");
  const success = useSelector((state) => state.profile.success, shallowEqual);
  const myBid = useSelector((state) => state.profile.myBid, shallowEqual);
  const totalData = useSelector(
    (state) => state.profile.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.profile.totalPage,
    shallowEqual
  );
  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetMyBid(page, sort, sortParameter, token));

    return () => {
      dispatch(removeProfileData());
    };
  }, [token, page, sort, sortParameter]);

  useEffect(() => {
    dispatch(fetchSearchMyBid(searchBid, token));
  }, [searchBid, token]);

  const navigateTop = () => {
    window.scrollTo(0, 0);
  };

  // handle sort select box
  const handleSelectSort = (e) => {
    if (e.target.value === "All") {
      setSortParameter("id");
      setSort("descending");
    } else if (e.target.value === "Item Name") {
      setSortParameter("product.title");
      setSort("descending");
    } else if (e.target.value === "Item Number") {
      setSortParameter("product.id");
      setSort("descending");
    } else if (e.target.value === "Billing Date") {
      setSortParameter("addedOn");
      setSort("descending");
    } else if (e.target.value === "Billing Amount") {
      setSortParameter("bidAmount");
      setSort("descending");
    } else {
      setSortParameter("id");
      setSort("descending");
    }
  };

  useEffect(() => {
    !success && (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }, []);

  return (
    <div className={`mt-10 ${myBid.length ? "" : "mb-60"}`}>
      {/* search and filter */}
      <div className="flex justify-between items-center flex-wrap">
        <div className="w-full md:w-2/5 border rounded-xl py-2 px-2 gap-x-2 flex items-center">
          <img src={searchIcon} alt="search icon" className="py-1 w-8 h-8" />
          <input
            type="text"
            placeholder="Search by item number, item name, amount..."
            className="py-1 w-full outline-none px-3"
            onChange={(e) => setSearchBid(e.target.value)}
          />
        </div>

        <div className="w-full mt-5 md:mt-0 md:w-64 py-2 items-center flex justify-between">
          <p className="text-secondary">Sort By:</p>
          <select
            className="w-9/12 outline-none rounded-lg px-1 border py-2"
            onChange={handleSelectSort}
          >
            <option>All</option>
            <option>Item Name</option>
            <option>Item Number</option>
            <option>Billing Date</option>
            <option>Billing Amount</option>
          </select>
        </div>
      </div>

      {/* bids detail */}
      <div className="py-2 pb-20 align-middle w-full mt-5 md:mt-12 relative">
        <table className="table-auto w-full">
          <thead className="w-full">
            <tr className="border-b">
              <th
                className="p-4 md:p-6 text-left font-medium text-xs md-text-base text-secondary uppercase"
                scope="col"
              >
                Item
              </th>
              <th
                className="p-4 md:p-6 text-left font-medium text-xs md-text-base  text-secondary uppercase"
                scope="col"
              >
                Item Number
              </th>
              <th
                className="p-4 md:p-6 text-left font-medium text-xs md-text-base  text-secondary uppercase"
                scope="col"
              >
                Billing Date
              </th>
              <th
                className="p-4 md:p-6 text-left font-medium text-xs md-text-base  text-secondary uppercase"
                scope="col"
              >
                Bid Amount
              </th>
            </tr>
          </thead>

          {totalData === "" ? (
            <div className="w-full absolute">
              <Loading />
            </div>
          ) : myBid && myBid.length ? (
            <>
              <tbody className="bg-white">
                {myBid.map((data) => {
                  return (
                    <tr className="border-b" key={data.id}>
                      <td className="p-4 md:p-6 text-xs md:text-base whitespace-nowrap text-left overflow-hidden">
                        {data.item}
                      </td>
                      <td className="p-4 md:p-6 text-xs md:text-base px-5 md:px-16 text-left whitespace-nowrap">
                        {data.itemNumber}
                      </td>
                      <td className="p-4 md:p-6 text-xs md:text-base px-7 whitespace-nowrap items-center">
                        {convertDate(data.bidAddedTime)}
                      </td>
                      <td className="p-4 md:p-6 text-xs md:text-base px-5 md:px-16 whitespace-nowrap">
                        {data.bidAmount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <div className="w-full bottom-0 absolute">
                {totalPage > 5 ? (
                  <Pagination
                    totalData={totalData}
                    totalPage={totalPage}
                    page={page}
                    setPage={setPage}
                    navigateTop={navigateTop}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    navigateTop={navigateTop}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="w-80 h-80 top-36 left-[8%] md:left-1/3 absolute">
              <img src={noBid} alt="no bid" />
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default MyBidsModal;
