import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  subtotal: "",
  discount: "",
  total: "",
  orders: [],
  tempOrders: [],
  message: "",
  errMsg: "",
  isAdded: false,
};

export const Cart = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_ITEM_TO_CART:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.GET_CART_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.REMOVE_ORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
        message: action.payload?.message,
      };

    case ActionTypes.REMOVE_ALL_ORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
        message: action.payload?.message,
      };

    case ActionTypes.INCREASE_ORDER_QUANTITY:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.DECREASE_ORDER_QUANTITY:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.ADD_ITEM_TO_TEMP_CART:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.GET_TEMP_CART_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        tempOrders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.DELETE_TEMP_ORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        tempOrders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.DELETE_ALL_TEMP_ORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        tempOrders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.INCREASE_TEMP_ORDER_QUANTITY:
      return {
        ...state,
        isloading: false,
        success: true,
        tempOrders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.DECREASE_TEMP_ORDER_QUANTITY:
      return {
        ...state,
        isloading: false,
        success: true,
        tempOrders: action.payload?.orders,
        subtotal: action.payload?.subtotal,
        total: action.payload?.total,
        discount: action.payload?.discount,
      };

    case ActionTypes.ADD_TO_CART_FROM_TEMP_CART:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
        isAdded: true,
      };

    case ActionTypes.FAIL_ADD_TO_CART_FROM_TEMP_CART:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
        isAdded: false,
      };

    case ActionTypes.REMOVE_CART_DATA:
      return {
        ...state,
        isloading: true,
        success: false,
        subtotal: "",
        total: "",
        discount: "",
        message: "",
        isAdded: false,
      };

    default:
      return state;
  }
};
