import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import ProtectedRoute from "./Layout/ProtectedRoute";
import AboutUs from "./Pages/AboutUs";
import BuyBids from "./Pages/Buy-Bids/BuyBids";
import MyCart from "./Pages/Cart/MyCart";
import Categories from "./Pages/Categories/Index";
import CharityPartner from "./Pages/Charity/CharityPartner";
import CharitySection from "./Pages/Charity/CharitySection";
import Policy from "./Pages/Condition&Policy/Policy";
import TermAndCondition from "./Pages/Condition&Policy/TermAndCondition";
import Contact from "./Pages/ContactUs";
import Faq from "./Pages/FAQ";
import BuyGiftCard from "./Pages/GiftCard/BuyGiftCard";
import GiftCardForm from "./Pages/GiftCard/GiftCardForm";
import Home from "./Pages/Home";
import HowItWorks from "./Pages/HowItWorks";
import ForgetPassword from "./Pages/Login/Register/ForgetPassword";
import Login from "./Pages/Login/Register/Login";
import Register from "./Pages/Login/Register/Register";
import ResetPassword from "./Pages/Login/Register/ResetPassword";
import VerifyEmail from "./Pages/Login/Register/VerifyEmail";
import ProductDetail from "./Pages/Products/ProductDeatil";
import ProductListing from "./Pages/Products/ProductListing";
import Profile from "./Pages/Profile";
import Winners from "./Pages/Winners";
import Wishlist from "./Pages/WishList/Wishlist";
import { fetchGetMyWallet } from "./Redux/Profile/action";
import Cookies from "js-cookie";
import VerifyUser from "./Pages/Login/Register/VerifyUser";
import Checkout from "./Pages/Checkout";
import PageNotFound from "./Pages/404/PageNotFound";
import NonUserCart from "./Pages/Cart/NonUserCart";
import PaypalSuccess from "./Pages/Confirmation/PaypalSuccess";
import StripeSuccess from "./Pages/Confirmation/StripeSuccess";
import { fetchGetFooterData } from "./Redux/Footer/action";
import { fetchGetAllPages } from "./Redux/PageManagement/action";
import Loading from "./Component/Loading/Loading";
import StripeCancel from "./Pages/Confirmation/StripeCancel";

const App = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.pages.isloading, shallowEqual);

  const pages = useSelector((state) => state.pages.pages, shallowEqual);
  // retrieve token
  const token = Cookies.get("token");

  useEffect(() => {
    token && dispatch(fetchGetMyWallet(token));
  }, [token]);

  useEffect(() => {
    dispatch(fetchGetFooterData());
  }, []);

  useEffect(() => {
    dispatch(fetchGetAllPages());
  }, []);

  if (Cookies.get("token") && Cookies.get("token") === "undefined") {
    // remove the cookie
    Cookies.remove("token");
  }

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="bg-white relative">
      <BrowserRouter>
        <Header />
        <Routes>
          {/* home page routes */}
          {pages.homePage && <Route path="/" element={<Home />} />}
          <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-in?authenticate=false" element={<Login />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/email-verification" element={<VerifyUser />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* contact us page */}
          {pages.contactUs && (
            <Route path="/contact-us" element={<Contact />} />
          )}

          {/* terms and condition */}
          {pages.termsAndCondition && (
            <Route path="/term-&-Condition" element={<TermAndCondition />} />
          )}

          {/* privacy policy page */}
          {pages.privacyPolicy && (
            <Route path="/privacy-policy" element={<Policy />} />
          )}

          {/* winner page */}
          {pages.ourWinners && <Route path="/winners" element={<Winners />} />}

          {/* how it works page */}
          {pages.howItWorks && (
            <Route path="/how-it-works" element={<HowItWorks />} />
          )}

          {/* about us page */}
          {pages.aboutUs && <Route path="/about-us" element={<AboutUs />} />}

          {/* faq page */}
          {pages.faq && <Route path="/faq" element={<Faq />} />}

          <Route path="/products" element={<ProductListing />} />
          <Route path="/product" element={<ProductDetail />} />
          <Route path="/send-bids" element={<BuyGiftCard />} />
          <Route path="/send-bid" element={<GiftCardForm />} />

          {/* charity page */}
          {pages.charity && (
            <Route path="/charity-partner" element={<CharityPartner />} />
          )}
          {pages.charity && (
            <Route path="/charity-section" element={<CharitySection />} />
          )}

          <Route path="/buy-bids" element={<BuyBids />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/user-cart" element={<NonUserCart />} />
          <Route path="/paypal-success" element={<PaypalSuccess />} />
          <Route path="/stripe-success" element={<StripeSuccess />} />
          <Route path="stripe-cancel" element={<StripeCancel />} />
          <Route path="/paypal-success-temporary" element={<PaypalSuccess />} />
          <Route path="/stripe-success-temporary" element={<StripeSuccess />} />

          {/* Protected Routes */}
          <Route
            path="/wish-list"
            element={
              <ProtectedRoute>
                <Wishlist />
              </ProtectedRoute>
            }
          />

          <Route
            path="/my-profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route
            path="/my-cart"
            element={
              <ProtectedRoute>
                <MyCart />
              </ProtectedRoute>
            }
          />
          {/* end of protected routes */}

          {/* Page not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
