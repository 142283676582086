import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProductCardList from "../../Component/Card/ProductCardList";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetOwnWishlist,
  fetchRemoveFromWishlist,
  fetchSearchWishlist,
} from "../../Redux/Wishlist/action";
import Header from "./Header";
import heartIcon from "../../Assets/Icons/Heart Icon.svg";
import { useNavigate } from "react-router-dom";
import { fetchGetCategory } from "../../Redux/Category/action";
import Cookies from "js-cookie";
import noProduct from "../../Assets/Images/empty.svg";
import Pagination from "../../Component/Pagination/Pagination";
import SinglePagnination from "../../Component/Pagination/SinglePagnination";
import { Helmet } from "react-helmet";

const Wishlist = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const categories = useSelector(
    (state) => state.categories.category,
    shallowEqual
  );
  const dispatch = useDispatch();
  // retrieve cookie
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.wishlist.isloading,
    shallowEqual
  );

  const myWish = useSelector((state) => state.wishlist.myWish, shallowEqual);
  const totalData = useSelector(
    (state) => state.wishlist.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.wishlist.totalPage,
    shallowEqual
  );

  // remove item from wishlist
  const removeWishlist = (id) => {
    !token
      ? navigate("/sign-in")
      : dispatch(fetchRemoveFromWishlist(id, token));
  };

  useEffect(() => {
    searchValue.length
      ? dispatch(fetchSearchWishlist(token, searchValue))
      : dispatch(fetchGetOwnWishlist(page, token));
  }, [dispatch, searchValue, page]);

  useEffect(() => {
    dispatch(fetchGetCategory());
    window.scrollTo(0, 0);
  }, []);

  const navigateTop = () => {
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>My Wishlist | Bidyl</title>
      </Helmet>
      <div className="w-11/12 xl:w-3/4 mx-auto pt-40 pb-20">
        <Header
          totalData={myWish}
          categories={categories}
          setSearchValue={setSearchValue}
        />

        {/* wishlist card */}

        {totalData === "" ? (
          <div className="w-full">
            <Loading />
          </div>
        ) : myWish && myWish.length ? (
          <div className="mt-44 md:mt-24 lg:mt-10">
            {myWish.map((data) => {
              return (
                <div key={data.id} className="mt-10">
                  <ProductCardList
                    data={data}
                    icon={heartIcon}
                    handleWishlist={removeWishlist}
                  />
                </div>
              );
            })}
            <div className=" mt-20 w-full">
              {totalPage > 5 ? (
                <Pagination
                  totalData={totalData}
                  totalPage={totalPage}
                  setPage={setPage}
                  navigateTop={navigateTop}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                  navigateTop={navigateTop}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="mt-14 text-center w-80 h-80 mx-auto">
            <img src={noProduct} alt="no item img" />
          </div>
        )}
      </div>
    </>
  );
};

export default Wishlist;
