import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../Formik/Error";

const SelectField = ({ label, name, data, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <Field
        name={name}
        as="select"
        {...rest}
        className="border border-gray-300 w-full p-2.5 text-base rounded-lg outline-gray-300"
      >
        {data.map((item) => {
          return (
            <option value={item.name} key={item.code}>
              {item.name}
            </option>
          );
        })}
      </Field>
    </div>
  );
};

export default SelectField;
