import React, { useEffect, useState } from "react";
import timer from "../../../Assets/Icons/timer.svg";
import bidHammer from "../../../Assets/Icons/White-hammer.svg";
import flash from "../../../Assets/Icons/flash.svg";
import Loading from "../../../Component/Loading/Loading";
import dollarIcon from "../../../Assets/Icons/dollar-circle.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetProductByIdUser } from "../../../Redux/Product/action";
import Cookies from "js-cookie";
import { AiOutlineHeart } from "react-icons/ai";
import {
  fetchAddToWishlist,
  fetchGetOwnWishlist,
  fetchRemoveFromWishlist,
} from "../../../Redux/Wishlist/action";
import { useNavigate } from "react-router-dom";
import Refreshing from "../../../Component/Loading/Refreshing";

const Container = ({
  product,
  setAuthenticateCheck,
  setAutoBidPopup,
  handlePlaceABid,
  handleSetBidValue,
  bidValue,
}) => {
  const [auctionClosed, setAuctionClosed] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [images, setImages] = useState(product.images);

  // retrieve cookie
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const loading = useSelector((state) => state.product.isloading, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (product) {
      setImages(product?.images);
    }
  }, [product]);

  useEffect(() => {
    if (images) {
      setSelectedImage(images?.[0]);
    }
  }, [images]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // place a auto bid
  const handlePlaceAutoBid = () => {
    !token && setAuthenticateCheck(true);
    token && setAutoBidPopup(true);
    window.scrollTo(0, 0);
  };

  // hanlde add or remove item from wishlist
  const handleWishlist = (id, isInWishList) => {
    !token && navigate("/sign-in");

    if (isInWishList === true) {
      token && dispatch(fetchRemoveFromWishlist(id, token));
      token && dispatch(fetchGetProductByIdUser(id, token));
      token && dispatch(fetchGetOwnWishlist(1, token));
    } else {
      token && dispatch(fetchAddToWishlist(id, token));
      token && dispatch(fetchGetProductByIdUser(id, token));
      token && dispatch(fetchGetOwnWishlist(1, token));
    }
    token && dispatch(fetchGetProductByIdUser(id, token));
    token && dispatch(fetchGetOwnWishlist(1, token));
  };

  // create a new Date object with the UTC date and time
  const utcDate = new Date(
    product.status === "PENDING"
      ? product.auctionStartDate
      : product.auctionEndDate
  );

  // get the local timezone offset in minutes
  const offsetMinutes = utcDate.getTimezoneOffset();

  // create a new Date object with the local date and time
  const localDate = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);

  // counter
  const endDate = localDate.getTime();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(endDate));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getTimeLeft(endDate));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [endDate]);

  const { days, hours, minutes, seconds } = timeLeft;

  function getTimeLeft(endDate) {
    const totalSeconds = Math.floor((endDate - new Date().getTime()) / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds === 0 &&
      setAuctionClosed(true);

    setTimeout(() => {
      days === 0 &&
        hours === 0 &&
        minutes === 0 &&
        seconds === 0 &&
        setAuctionClosed(false);
      window.location.reload(false);
    }, 1000 * 2 * 60);
  }, [days, hours, minutes, seconds]);

  console.log("auction closed", auctionClosed);

  // ##################

  if (loading || !images || !product) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  if (auctionClosed) {
    return (
      <div className="w-full h-screen">
        <Refreshing />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-wrap md:flex-nowrap gap-x-8 xl:gap-x-20">
      {/* image section starts */}
      <div className="w-full md:w-1/2 overflow-hidden">
        <div className="w-full h-80 lg:h-96">
          <img
            src={selectedImage?.image}
            alt="product img"
            className="w-full h-full rounded-2xl"
          />
        </div>

        {/* mini image section */}
        <div className="mt-10  w-full overflow-hidden hover:overflow-x-auto relative">
          <div className="flex items-center gap-x-4 w-fit">
            {images.map((data) => {
              return (
                <div
                  className={`w-32 h-32 border p-2 rounded-2xl cursor-pointer ${
                    selectedImage?.id === data.id ? "border-green-600" : ""
                  }`}
                  key={data.id}
                  onClick={() => setSelectedImage(data)}
                >
                  <img
                    src={data.image}
                    alt="mini images"
                    className="w-full h-full rounded-2xl"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* image section ends */}

      {/* side container starts */}
      <div className="w-full mt-10 md:mt-0 md:w-1/2 px-2 md:px-6">
        <h5 className="font-bold text-2xl py-1">{product.title}</h5>
        {/* <p className="text-secondary mt-4">{product.description}</p> */}

        <div className="mt-7">
          <div className="flex items-center gap-x-6">
            <p className="text-secondary">
              {`${
                product.status === "PENDING" ? "Auction Starts" : "Auction ends"
              } `}
              :
            </p>
            <h6 className="font-bold text-sm lg:text-lg">
              {product.status === "PENDING"
                ? new Date(product.auctionStartDate).toLocaleString()
                : new Date(product.auctionEndDate).toLocaleString()}
            </h6>
          </div>
          <div className="flex items-center gap-x-6">
            <p className="text-secondary">Timezone:</p>
            <h6 className="font-bold text-sm lg:text-lg">{product.timeZone}</h6>
          </div>
          <div className="flex items-center gap-x-6">
            <p className="text-secondary">Total Bids:</p>
            <h6 className="font-bold text-sm lg:text-lg">{product.bidCount}</h6>
          </div>

          <div className="flex items-center gap-x-6">
            <p className="text-secondary">Product Status:</p>
            {auctionClosed ? (
              "Closed"
            ) : (
              <h6
                className={`${
                  product.status === "CLOSED"
                    ? "text-red-700"
                    : product.status === "PENDING"
                    ? "text-primary"
                    : "text-green-700"
                } font-bold text-sm lg:text-lg`}
              >
                {product.status}
              </h6>
            )}
          </div>
        </div>

        {/* timer section */}
        <div
          className="w-full flex flex-wrap items-center justify-between mt-7 py-4 px-3 rounded-2xl"
          style={{ background: "#F7F9FC" }}
        >
          <div className="flex gap-x-1 items-center text-xs md:text-sm xl:text-base">
            {product.status === "PENDING" ||
            product.status === "IN_PROGRESS" ? (
              <img src={timer} alt="timer" className="w-4 h-4 lg:w-6 lg:h-6" />
            ) : null}
            <p>{`${
              product.status === "PENDING"
                ? "Starts in"
                : product.status === "IN_PROGRESS"
                ? "Ends in"
                : "Status"
            } `}</p>
          </div>
          <div>
            {auctionClosed ? (
              "Closed"
            ) : (
              <h6
                className={`${
                  product.status === "IN_PROGRESS" ||
                  product.status === "PENDING"
                    ? "block"
                    : "hidden"
                } font-bold text-primary text-xs lg:text-sm 2xl:text-base`}
              >
                {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
              </h6>
            )}

            <h6
              className={`${
                product.status === "CLOSED" ||
                product.status === "WINNER_SELECTED"
                  ? "block"
                  : "hidden"
              } font-bold text-primary text-sm`}
            >
              {product.status}
            </h6>
          </div>
        </div>

        {/* timer section ends */}

        <div className="mt-7">
          <p className="font-bold">Place your Bid Here</p>
          <div className="flex flex-wrap lg:flex-nowrap justify-between items-center mt-3 gap-x-8">
            <div className="border rounded-xl py-2 w-full lg:w-10/12 flex gap-x-1 px-2 items-center">
              <img src={dollarIcon} alt="dollar icon" className="w-5 h-5" />
              <input
                type="number"
                className="w-full py-1 outline-none px-2"
                placeholder="Enter your bid amount"
                value={bidValue}
                onChange={(e) => handleSetBidValue(e)}
                disabled={product.status !== "IN_PROGRESS"}
              />
            </div>

            <button
              className="font-medium mt-5 lg:mt-0 flex gap-x-3 bg-primary py-3 md:py-4 w-1/2 text-white rounded-2xl justify-center items-center"
              disabled={product.status !== "IN_PROGRESS"}
              onClick={(e) => handlePlaceABid(product.id)}
            >
              <img src={bidHammer} alt="bidding hammer" />
              Place a Bid
            </button>
          </div>
        </div>

        <div className="mt-7 flex flex-wrap lg:flex-nowrap justify-between gap-x-4 2xl:gap-x-8">
          <button
            className="font-medium flex gap-x-3 border border-primary text-sm 2xl:text-base py-4 w-full 2xl:w-1/2 text-primary rounded-2xl justify-center items-center"
            disabled={product.status !== "IN_PROGRESS"}
            onClick={handlePlaceAutoBid}
          >
            <img src={flash} alt="flash" className="w-5 h-5" />
            Place Auto Bid
          </button>
          {product.isInWishList ? (
            <button
              className="text-red-500 border-red-500 mt-5 lg:mt-0 font-medium text-sm 2xl:text-base flex gap-x-3 py-4 border w-full 2xl:w-1/2 rounded-2xl justify-center items-center"
              onClick={() => handleWishlist(product.id, product.isInWishList)}
            >
              <AiOutlineHeart className="text-xl xl:text-2xl" />

              <h1> Remove from Wishlist</h1>
            </button>
          ) : (
            <button
              className="border-primary mt-5 lg:mt-0 text-primary font-medium text-sm 2xl:text-base flex gap-x-3 py-4 border w-full 2xl:w-1/2 rounded-2xl justify-center items-center"
              onClick={() => handleWishlist(product.id, product.isInWishList)}
            >
              <AiOutlineHeart size={25} />
              <h1> Add to Wishlist</h1>
            </button>
          )}
        </div>
      </div>
      {/* side container ends */}
    </div>
  );
};

export default Container;
