import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import Header from "./Header";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchResetPassword, removeLogin } from "../../../Redux/Login/action";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  // storing the key using slice
  const location = useLocation();
  const params = location.search;
  const key = params.slice(5);

  const dispatch = useDispatch();
  const success = useSelector((state) => state.login.success, shallowEqual);
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required *"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      email: localStorage.getItem("verifyEmail"),
      password: values.password,
      confirmPassword: values.confirmPassword,
      key: key,
    };

    dispatch(fetchResetPassword(body));
    setTimeout(() => {
      dispatch(removeLogin());
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    if (success === true) {
      navigate("/sign-in?path=/");
    }
  }, [success]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-11/12 md:w-9/12 xl:w-1/2 container mx-auto pt-36 mb-20">
      <Helmet>
        <title>Reset Password | Bidyl</title>
      </Helmet>
      <Header
        text="Reset your"
        message="Password"
        info="Please use a new password which is different from your previously used one."
      />
      <div className="text-center w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-10">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className=" w-full">
                <FormikControl
                  label="New Password"
                  placeholder="Enter your password"
                  control="password"
                  name="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
                <div className="mt-5">
                  <FormikControl
                    label="Confirm new password"
                    placeholder="Confirm your password"
                    control="password"
                    name="confirmPassword"
                    type={`${showCPassword ? "text" : "password"}`}
                    setShowPassword={setShowCPassword}
                    showPassword={showCPassword}
                  />
                </div>

                <div className="mt-7">
                  <Button
                    type="submit"
                    className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                    value="Change Password"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
