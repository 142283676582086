import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormikControl from "../../../Component/Formik/FormikControl";
import * as Yup from "yup";
import Header from "./Header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchSignup, removeRegister } from "../../../Redux/Register/action";
import Message from "../../../Component/Message/Message";
import PasswordChecklist from "react-password-checklist";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const Register = () => {
  // state to show password and confirm password field
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [accepted, setAccepted] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  // retrieve cookie
  const token = Cookies.get("token");

  // retrieve successMessage from redux
  const successMessage = useSelector(
    (state) => state.signup.message,
    shallowEqual
  );

  useEffect(() => {
    setSuccMsg(successMessage);
    successMessage && navigate("/sign-in");
  }, [successMessage]);

  // retrieve errorMessgae from redux
  const errMessage = useSelector((state) => state.signup.errMsg, shallowEqual);

  const navigate = useNavigate();

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const dispatch = useDispatch();

  useEffect(() => {
    token && navigate("/");
  }, [token]);

  // initial formik values
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  // formik form validation with Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required *"),
    lastName: Yup.string().required("Required *"),
    email: Yup.string().email("Invalid email").required("Required *"),
    password: Yup.string().required("Required *"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchSignup({ ...values, hasAccepted: accepted }));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeRegister());
    }, 10000);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mb-20">
      <Helmet>
        <title>Signup | Bidyl</title>
      </Helmet>
      <div className="pt-24">
        {succMsg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-green-500 text-white"
            message={succMsg}
            setMessage={setSuccMsg}
          />
        )}

        {errMsg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-red-500 text-white"
            message={errMsg}
            setMessage={setErrMsg}
          />
        )}
        <div className="w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-10">
          <Header
            text="Create"
            message="an Account"
            info="Creating an account will allow you to manage your activity"
          />

          <div className="text-center w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-10">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className=" w-full">
                    <div className="flex w-full justify-between gap-x-5 flex-col md:flex-row">
                      <div className="w-full">
                        <FormikControl
                          label="First Name"
                          placeholder="Eg: John"
                          control="input"
                          name="firstName"
                        />
                      </div>
                      <div className="w-full mt-5 md:mt-0">
                        <FormikControl
                          label="Last Name"
                          placeholder="Eg: Doe"
                          control="input"
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <FormikControl
                        label="Email Address"
                        placeholder="Enter your email"
                        control="input"
                        name="email"
                        type="email"
                      />
                    </div>
                    <div className="mt-5">
                      <FormikControl
                        label="Password"
                        placeholder="Enter your password"
                        control="password"
                        name="password"
                        type={`${showPassword ? "text" : "password"}`}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                      />
                    </div>

                    {formik.values.password && (
                      <div className="text-start mt-3 text-sm">
                        <p>Password should be: </p>
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                          ]}
                          minLength={4}
                          iconSize={0}
                          validColor="green"
                          value={formik.values.password}
                          messages={{
                            minLength: "Atleast 4 characters long",
                            specialChar: "Minimum 1 special character",
                            number: "Minimum 1 number",
                            capital: "Minimum 1 uppercase",
                          }}
                        />
                      </div>
                    )}

                    <div className="mt-5">
                      <FormikControl
                        label="Confirm password"
                        placeholder="Confirm your password"
                        control="password"
                        name="confirmPassword"
                        type={`${showCPassword ? "text" : "password"}`}
                        setShowPassword={setShowCPassword}
                        showPassword={showCPassword}
                      />
                    </div>

                    <div className="flex">
                      <input
                        type="checkbox"
                        className="w-12 h-12 mt-3"
                        onClick={() => setAccepted(!accepted)}
                      />
                      <h1 className="text-gray-400 mt-6">
                        By continuing, you acknowledge that you have read and
                        understood, and agree to Bidyl’s{" "}
                        <span className="text-primary font-bold">
                          <Link to="/term-&-Condition">
                            Terms & Conditions{" "}
                          </Link>
                        </span>
                        and{" "}
                        <span className="text-primary font-bold">
                          <Link to="/privacy-policy">Privacy Policy.</Link>
                        </span>
                      </h1>
                    </div>

                    <div className="mt-5">
                      <button
                        disabled={`${accepted ? "" : true}`}
                        type="submit"
                        className={`font-medium py-3 rounded-2xl text-white w-full ${
                          accepted ? "bg-primary " : "bg-blue-300"
                        }`}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="flex items-center justify-center gap-x-2 mt-3">
              <p>Already have an account?</p>
              <Link to="/sign-in">
                <p className="text-primary font-bold">Log in</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
