import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  getUrl: false,
  url: "",
  message: "",
  errMsg: "",
  orderId: "",
  titleText: "",
  subText: "",
};

export const Transaction = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_BILLING_INFORMATION:
      return {
        ...state,
        isloading: false,
        success: true,
        url: action.payload.redirectUrl,
        getUrl: true,
      };

    case ActionTypes.FAIL_ADD_BILLING_INFORMATION:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
        getUrl: false,
      };

    case ActionTypes.GET_PAYPAL_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        orderId: action.payload.orderId,
      };

    case ActionTypes.FAIL_PAYPAL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_STRIPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        orderId: action.payload.orderId,
      };

    case ActionTypes.FAIL_STRIPE:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.ADD_TEMP_BILLING_INFO:
      return {
        ...state,
        isloading: false,
        success: true,
        url: action.payload.redirectUrl,
        getUrl: true,
      };

    case ActionTypes.FAIL_ADD_TEMP_BILLING_INFO:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
        getUrl: false,
      };

    case ActionTypes.GET_TEMP_PAYPAL_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        orderId: action.payload.orderId,
      };

    case ActionTypes.FAIL_TEMP_PAYPAL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_TEMP_STRIPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        orderId: action.payload.orderId,
      };

    case ActionTypes.FAIL_TEMP_STRIPE:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_TRANSACTION_DETAIL:
      return {
        ...state,
        isloading: true,
        success: false,
        url: "",
        message: "",
        errMsg: "",
        getUrl: false,
        titleText: "",
        subText: "",
        orderId: "",
      };

    default:
      return state;
  }
};
