import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const buyBidsLoading = () => ({
  type: ActionTypes.BUY_BIDS_LOADING,
});

export const fetchGetBidPackage = () => (dispatch) => {
  dispatch(buyBidsLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/bid-package/get-all-active`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_BIDS_PACKAGE,
        payload: data,
      });
    });
};

export const fetchGetBidPageDetail = () => (dispatch) => {
  dispatch(buyBidsLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/bid-page/get-details`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DETAILS,
        payload: data,
      });
    });
};
