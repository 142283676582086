import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "../../../Component/Button/Button";
import Message from "../../../Component/Message/Message";
import {
  fetchRemoveBiddingPassword,
  removeProfileData,
} from "../../../Redux/Profile/action";

const RemoveBiddingPassword = ({ setRemoveBiddingPasswordPopupModal }) => {
  const [password, setPassord] = useState();
  const [errMsg, setErrMsg] = useState();

  const errMessage = useSelector((state) => state.profile.errMsg, shallowEqual);

  const success = useSelector((state) => state.profile.success, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  // handle submit
  const handleSubmit = () => {
    dispatch(fetchRemoveBiddingPassword(token, password));
    Cookies.remove("biddingPassword");
    setTimeout(() => {
      dispatch(removeProfileData());
    }, [2000]);
  };

  useEffect(() => {
    if (success === true) {
      setRemoveBiddingPasswordPopupModal(false);
    }
  });

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-2/3 lg:w-1/2 2xl:w-1/3 pt-10 pb-16 left-4 md:left-[20%] lg:left-1/4 2xl:left-1/3">
      <div className="w-11/12 md:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-3xl text-primary text-center">
          Remove Bidding Password
        </h4>
        <p className="text-center text-secondary py-1">
          To continue, please enter your password.
        </p>

        {errMessage && (
          <Message
            className="text-red-500 mt-6"
            message={errMsg}
            setMessage={setErrMsg}
          />
        )}

        <div className="mt-5">
          <label className="text-sm pb-2">Password</label>
          <input
            className="border border-gray-300 w-full p-2.5 mt-1 text-base rounded-lg outline-gray-300"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassord(e.target.value)}
          />
        </div>
        <div className="flex justify-between mt-6 gap-x-10 md:gap-x-20">
          <div
            className="w-full rounded-2xl text-center cursor-pointer"
            style={{ background: "#EBF0FA" }}
            onClick={() => setRemoveBiddingPasswordPopupModal(false)}
          >
            <Button
              value="Cancel"
              className="py-3 md:py-4 px-5 text-sm md:text-base md:px-7 text-primary font-medium "
            />
          </div>

          <div
            className="py-3 md:py-4 px-5 text-sm md:text-base md:px-7 w-full rounded-2xl text-center border text-red-500 border-red-500 cursor-pointer"
            onClick={handleSubmit}
          >
            <Button value="Remove" type="submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveBiddingPassword;
