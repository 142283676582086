export const CART_LOADING = "CART_LOADING";
export const GET_CART_DATA = "GET_CART_DATA";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const FAIL_TO_ADD_ITEM = "FAIL_TO_ADD_ITEM";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const REMOVE_ALL_ORDER = "REMOVE_ALL_ORDER";
export const INCREASE_ORDER_QUANTITY = "INCREASE_ORDER_QUANTITY";
export const DECREASE_ORDER_QUANTITY = "DECREASE_ORDER_QUANTITY";

export const GET_TEMP_CART_DATA = "GET_TEMP_CART_DATA";
export const ADD_ITEM_TO_TEMP_CART = "ADD_ITEM_TO_TEMP_CART";
export const FAIL_TO_ADD_ITEM_IN_TEMP_CART = "FAIL_TO_ADD_ITEM_IN_TEMP_CART";
export const DELETE_TEMP_ORDER = "DELETE_TEMP_ORDER";
export const DELETE_ALL_TEMP_ORDER = "DELETE_ALL_TEMP_ORDER";
export const INCREASE_TEMP_ORDER_QUANTITY = "INCREASE_TEMP_ORDER_QUANTITY";
export const DECREASE_TEMP_ORDER_QUANTITY = "DECREASE_TEMP_ORDER_QUANTITY";

export const ADD_TO_CART_FROM_TEMP_CART = "ADD_TO_CART_FROM_TEMP_CART";
export const FAIL_ADD_TO_CART_FROM_TEMP_CART =
  "FAIL_ADD_TO_CART_FROM_TEMP_CART";

export const REMOVE_CART_DATA = "REMOVE_CART_DATA";
