import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const partnerLoading = () => ({
  type: ActionTypes.PARTNER_LOADING,
});

export const removePartnerData = () => ({
  type: ActionTypes.REMOVE_PARTNER_DATA,
});

export const fetchGetAllPartners = () => (dispatch) => {
  dispatch(partnerLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/partners/get-active`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_PARTNERS,
        payload: data,
      });
    });
};

export const fetchGetPartnerData = () => (dispatch) => {
  dispatch(partnerLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/partners/page/get`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PARTNER_DATA,
        payload: data,
      });
    });
};
