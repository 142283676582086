import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import { fetchGetAllCountry } from "../../../Redux/ContactUs/action";
import {
  fetchUpdateInformation,
  removeProfileData,
} from "../../../Redux/Profile/action";
import arrowDown from "../../../Assets/Icons/arrow-down.svg";

const LocationDetailModalPopup = ({ setLocationDetailPopupModal, data }) => {
  const [showCountry, setShowCountry] = useState(false);
  const [country, setCountry] = useState(data.country);
  const [countryCode, setCountryCode] = useState();
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const countries = useSelector(
    (state) => state.contactus.countries,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetAllCountry(country));
  }, [dispatch, country]);

  // store the country in state
  const onClickDropdownOption = (country) => {
    setCountry(country);
    const data = countries.find((item) => item.name === country);
    setCountryCode(data.calling_code);
    setShowCountry(false);
  };

  // set formik initial values
  const initialValues = data || {
    streetAddress: "",
    state: "",
    postCode: "",
  };

  //  handle submit
  const onSubmit = (values, onSubmitProps) => {
    const data = {
      country: country,
      callingCode: countryCode,
      streetAddress: values.streetAddress,
      state: values.state,
      postCode: values.postCode,
    };
    dispatch(fetchUpdateInformation(data, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeProfileData());
    }, 5000);

    setLocationDetailPopupModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 md:left-[17%] lg:left-1/4 pt-10 pb-16">
      <h4 className="font-bold text-3xl text-primary text-center">
        Location Details
      </h4>

      <div className="w-3/4 mx-auto mt-16">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full">
                <label className="pb-2 font-medium flex items-center justify-between">
                  Country
                </label>
                <div
                  className="border border-gray-300 w-full cursor-pointer p-1.5 text-base rounded-lg outline-gray-300 flex justify-between"
                  onClick={() => setShowCountry(!showCountry)}
                >
                  <input
                    type="text"
                    className="w-full py-1 outline-none px-3 cursor-pointer"
                    placeholder="Search Countries"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                  <img src={arrowDown} alt="arrow" />
                </div>

                {showCountry ? (
                  <div
                    className={`absolute border w-3/4 mt-1 rounded-md border-gray-400 mb-5 bg-white shadow-md z-50 overflow-auto ${
                      countries.length < 5 ? "h-fit" : "h-56"
                    }`}
                  >
                    {countries?.map((item) => {
                      return (
                        <div
                          className=" cursor-pointer p-3 hover:bg-gray-200 text-sm"
                          key={item.code}
                          onClick={(e) => {
                            return (
                              e.preventDefault(),
                              onClickDropdownOption(item.name)
                            );
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>

              <div className="w-full mt-6">
                <FormikControl
                  label="Street Address"
                  placeholder="Your Street Address"
                  control="input"
                  name="streetAddress"
                />
              </div>

              <div className="w-full mt-6 flex justify-between items-center flex-wrap md:flex-nowrap gap-x-14">
                <div className="w-full">
                  <FormikControl
                    label="State"
                    placeholder="Your State"
                    control="input"
                    name="state"
                  />
                </div>
                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Post Code"
                    placeholder="Your Post Code"
                    control="input"
                    name="postCode"
                  />
                </div>
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-sm md:text-base cursor-pointer text-primary font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setLocationDetailPopupModal(false)}
                >
                  <Button value="Cancel" />
                </div>

                <Button
                  value="Save Changes"
                  type="submit"
                  className="border w-full text-sm md:text-base rounded-2xl bg-primary cursor-pointer text-white font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {showCountry && (
        <div
          className="absolute top-0 w-full h-full"
          onClick={() => setShowCountry(false)}
        ></div>
      )}
    </div>
  );
};

export default LocationDetailModalPopup;
