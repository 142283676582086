import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BidCard, { GridBidCard } from "../../Component/Card/BidCard";
import ContainerCard from "../../Component/Card/ContainerCard";
import WinnerReview, {
  GridWinnerReview,
} from "../../Component/Card/WinnerReview";
import Carousel from "../../Component/Carousell";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import { fetchGetHomeData } from "../../Redux/Home/action";
import Partners, { PartnersCard } from "../../Component/Card/PartnerCard";
import {
  fetchGetAllPartners,
  fetchGetPartnerData,
  removePartnerData,
} from "../../Redux/Partners/action";
import { Helmet } from "react-helmet";

const Home = () => {
  const loading = useSelector((state) => state.home.isloading, shallowEqual);
  const titleText = useSelector((state) => state.home.titleText, shallowEqual);
  const subText = useSelector((state) => state.home.subText, shallowEqual);

  const carouselImages = useSelector(
    (state) => state.home.carouselImages,
    shallowEqual
  );
  const bidTitleText = useSelector(
    (state) => state.home.bidTitleText,
    shallowEqual
  );
  const bidSubText = useSelector(
    (state) => state.home.bidSubText,
    shallowEqual
  );
  const winnerTitleText = useSelector(
    (state) => state.home.winnerTitleText,
    shallowEqual
  );
  const winnerSubText = useSelector(
    (state) => state.home.winnerSubText,
    shallowEqual
  );
  const bidPackages = useSelector(
    (state) => state.home.bidPackages,
    shallowEqual
  );

  const productWinners = useSelector(
    (state) => state.home.productWinners,
    shallowEqual
  );

  const partners = useSelector((state) => state.partner.partners, shallowEqual);

  const products = useSelector((state) => state.home.products, shallowEqual);
  const partnerData = useSelector(
    (state) => state.partner.partnerData,
    shallowEqual
  );
  const partnerLoading = useSelector(
    (state) => state.partner.isloading,
    shallowEqual
  );

  const ourProductTitleText = useSelector(
    (state) => state.home.ourProductTitleText,
    shallowEqual
  );
  const ourProductSubText = useSelector(
    (state) => state.home.ourProductSubText,
    shallowEqual
  );

  // category products
  const productHouses = products?.filter(
    (dataObj) => dataObj.category.id === 1
  );
  const productVehicles = products?.filter(
    (dataObj) => dataObj.category.id === 2
  );
  const productWatches = products?.filter(
    (dataObj) => dataObj.category.id === 3
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetHomeData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGetAllPartners());
    dispatch(fetchGetPartnerData());
    return () => {
      dispatch(removePartnerData());
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || partnerLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pb-20 pt-12">
      <Helmet>
        <title>Home | Bidyl</title>
      </Helmet>
      {/* carousel */}
      <Carousel
        titleText={titleText}
        subText={subText}
        images={carouselImages}
      />
      {/* buy bid section */}
      <div className="mt-10 md:mt-20">
        <div className="mx-auto flex items-center justify-center flex-col text-center w-11/12 xl:w-1/2">
          <TitleContainer title={bidTitleText} paragraph={bidSubText} />
        </div>

        {/* bid packages */}
        <div className="w-11/12 xl:w-9/12 mx-auto">
          {bidPackages && bidPackages.length > 4 ? (
            <BidCard data={bidPackages} />
          ) : (
            <GridBidCard data={bidPackages} />
          )}
        </div>
      </div>

      {/* container card */}
      {products && products.length ? (
        <div className="mt-16 md:mt-24 w-11/12 xl:w-3/4 mx-auto">
          <div className="mx-auto flex items-center justify-center flex-col text-center w-11/12 xl:w-3/4 mb-7">
            <TitleContainer
              title={ourProductTitleText}
              paragraph={ourProductSubText}
            />
          </div>
          <ContainerCard
            data={productHouses}
            className="flex gap-x-5 md:gap-x-10 h-fit md:h-96 w-full md:px-5 mt-3"
          />

          <ContainerCard
            data={productVehicles}
            className="flex flex-row-reverse gap-x-5 md:gap-x-10 h-fit md:h-96 md:px-5 mt-10"
          />

          <ContainerCard
            data={productWatches}
            className="flex gap-x-5 md:gap-x-10 h-fit md:h-96 md:px-5 mt-10"
          />
        </div>
      ) : null}

      {productWinners && productWinners.length ? (
        <div className="mt-0 md:mt-20 w-11/12 xl:w-9/12 mx-auto ">
          <div className="w-11/12 xl:w-3/4 mx-auto">
            <TitleContainer title={winnerTitleText} paragraph={winnerSubText} />
          </div>

          {productWinners.length > 3 ? (
            <WinnerReview data={productWinners} />
          ) : (
            <GridWinnerReview data={productWinners} />
          )}
        </div>
      ) : null}

      {partnerData && partnerData.length ? (
        <hr className="mt-20 w-3/4 mx-auto" />
      ) : null}

      <div className="w-11/12 xl:w-9/12 mx-auto mt-10">
        <TitleContainer
          title={partnerData.titleText}
          paragraph={partnerData.subText}
        />
        {partners.length > 4 ? (
          <Partners data={partners} />
        ) : (
          <PartnersCard data={partners} />
        )}
      </div>
    </div>
  );
};

export default Home;
