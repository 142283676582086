import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Component/Button/Button";
import FormikControl from "../../Component/Formik/FormikControl";
import { fetchSendGift, removeGiftData } from "../../Redux/GiftCard/action";
import Cookies from "js-cookie";
import Message from "../../Component/Message/Message";
import { fetchGetMyWallet } from "../../Redux/Profile/action";
import { Helmet } from "react-helmet";

const GiftCardForm = () => {
  const [msg, setMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const message = useSelector((state) => state.giftCard.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.giftCard.errMsg,
    shallowEqual
  );

  const footerText2 = useSelector(
    (state) => state.giftCard.footerText2,
    shallowEqual
  );
  // retrieve cookie
  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setMsg(message);
    token && dispatch(fetchGetMyWallet(token));
  }, [message]);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  // set formik initial values
  const initialValues = {
    name: "",
    note: "",
    email: "",
    numberOfBids: "",
  };

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    !token && navigate("/sign-in");
    dispatch(fetchSendGift(token, values));

    setTimeout(() => {
      onSubmitProps.resetForm({ values: "" });
    }, [1000]);

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeGiftData());
    }, 10000);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Send Bids | Bidyl</title>
      </Helmet>
      <div className="pb-20 pt-32">
        {message && (
          <Message
            className="text-green-500 text-3xl font-bold"
            message={msg}
            setMessage={setMsg}
          />
        )}

        {errMessage && (
          <Message
            message={errMsg}
            setMessage={setErrMsg}
            className="text-red-500 text-3xl font-bold"
          />
        )}
        <h3 className="font-bold text-4xl text-center uppercase pt-8">
          Send Bids
        </h3>
        <h6 className="font-medium text-xl text-center text-secondary mt-4">
          Your chance to spread the love
        </h6>
        <div className=" w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-2">
          <div className="text-center w-full sm:w-9/12 xl:w-1/2 container mx-auto mt-10">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className=" w-full">
                    <div className="mt-6">
                      <FormikControl
                        label="Enter Number of Bids"
                        placeholder="Add your amount here..."
                        control="input"
                        name="numberOfBids"
                        type="number"
                      />
                    </div>

                    <div className="mt-6">
                      <FormikControl
                        label="Who are the bids going to"
                        placeholder="Enter name here..."
                        control="input"
                        name="name"
                      />
                    </div>

                    <div className="mt-6">
                      <FormikControl
                        label="Email of the person the gift card is going to"
                        placeholder="Enter email here..."
                        control="input"
                        type="email"
                        name="email"
                      />
                    </div>

                    <div className="mt-6">
                      <FormikControl
                        label="Add your personalised note here"
                        placeholder="Enjoy this amazing gift card from..."
                        control="textarea"
                        name="note"
                      />
                    </div>

                    <div className="mt-6">
                      <Button
                        type="submit"
                        className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                        value="Continue with Order"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="w-3/4 mx-auto mt-10">
          <p className="text-secondary text-center">{footerText2}</p>
        </div>
      </div>
    </>
  );
};

export default GiftCardForm;
