import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import searchIcon from "../../Assets/Icons/search.svg";
import Loading from "../../Component/Loading/Loading";
import Pagination from "../../Component/Pagination/Pagination";
import SinglePagnination from "../../Component/Pagination/SinglePagnination";
import {
  fetchGetMyOrderHistory,
  fetchSearchMyOrderHistory,
  removeProfileData,
} from "../../Redux/Profile/action";
import noHistory from "../../Assets/Images/empty.svg";
import { convertDate } from "../../Utls/Moment";

const HistoryModal = () => {
  const [searchHistory, setSearchHistory] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("descending");
  const [sortParameter, setSortParameter] = useState("id");
  const success = useSelector((state) => state.profile.success, shallowEqual);
  const myOrder = useSelector((state) => state.profile.myOrder, shallowEqual);
  const totalData = useSelector(
    (state) => state.profile.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.profile.totalPage,
    shallowEqual
  );
  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetMyOrderHistory(page, sort, sortParameter, token));

    return () => {
      dispatch(removeProfileData());
    };
  }, [token, sort, sortParameter, page]);

  const navigateTop = () => {
    window.scrollTo(0, 0);
  };
  // handle sort select box
  const handleSelectSort = (e) => {
    if (e.target.value === "All") {
      setSortParameter("id");
      setSort("descending");
    } else if (e.target.value === "Billing Date") {
      setSortParameter("billingDate");
      setSort("descending");
    } else if (e.target.value === "Amount") {
      setSortParameter("totalAmount");
      setSort("descending");
    } else {
      setSortParameter("id");
      setSort("descending");
    }
  };

  useEffect(() => {
    dispatch(fetchSearchMyOrderHistory(searchHistory, token));
  }, [searchHistory, token]);

  useEffect(() => {
    !success && (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }, []);

  return (
    <div className={`mt-10 ${myOrder.length ? "" : "mb-60"}`}>
      {/* search and filter */}
      <div className="flex justify-between items-center flex-wrap">
        <div className="w-full md:w-2/5 border rounded-xl py-2 px-2 gap-x-2 flex items-center">
          <img src={searchIcon} alt="search icon" className="py-1 w-8 h-8" />
          <input
            type="text"
            placeholder="Search by invoice number, name, amount..."
            className="py-1 w-full outline-none px-3"
            onChange={(e) => setSearchHistory(e.target.value)}
          />
        </div>

        <div className="w-full mt-5 md:mt-0 md:w-64 py-2 items-center flex justify-between">
          <p className="text-secondary">Sort By:</p>
          <select
            className="w-9/12 outline-none rounded-lg px-1 border py-2"
            onChange={handleSelectSort}
          >
            <option>All</option>
            {/* <option>Package Name</option> */}
            {/* <option>Vendor Name</option> */}
            <option>Billing Date</option>
            <option>Amount</option>
          </select>
        </div>
      </div>

      {/* bids detail */}
      <div className="py-2 pb-20 align-middle w-full mt-5 md:mt-12 relative">
        <table className="table-auto w-full">
          <thead className="w-full">
            <tr className="border-b">
              <th
                className="p-4 md:p-6 text-xs md-text-base text-left font-medium  text-secondary uppercase"
                scope="col"
              >
                Order Number
              </th>
              <th
                className="p-4 md:p-6 text-xs md-text-base text-left font-medium  text-secondary uppercase"
                scope="col"
              >
                Package Name
              </th>

              <th
                className="p-4 md:p-6 text-xs md-text-base text-left font-medium  text-secondary uppercase"
                scope="col"
              >
                Billing Date
              </th>
              <th
                className="p-4 md:p-6 text-xs md-text-base text-left font-medium  text-secondary uppercase"
                scope="col"
              >
                Amount
              </th>
            </tr>
          </thead>

          {totalData === "" ? (
            <div className="w-full absolute">
              <Loading />
            </div>
          ) : myOrder && myOrder.length ? (
            <>
              <tbody className="bg-white">
                {myOrder.map((data) => {
                  return (
                    <tr className="border-b w-full" key={data.id}>
                      <td className="p-4 md:p-6 text-xs md:text-base whitespace-nowrap">
                        {data.orderNumber}
                      </td>
                      <td className="p-4 md:p-6 text-xs md:text-base whitespace-nowrap">
                        {data.packageName}
                      </td>
                      {/* <td className="p-6 whitespace-nowrap">Jane Cooper</td> */}
                      <td className="p-4 md:p-6 text-xs md:text-base px-3 md:px-7 whitespace-nowrap">
                        {convertDate(data.billingDate)}
                      </td>
                      <td className="p-4 md:p-6 text-xs md:text-base whitespace-nowrap px-4 md:px-10">
                        ${data.amount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <div className="bottom-0 w-full absolute">
                {totalPage > 5 ? (
                  <Pagination
                    totalData={totalData}
                    totalPage={totalPage}
                    page={page}
                    setPage={setPage}
                    navigateTop={navigateTop}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    navigateTop={navigateTop}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="w-80 h-80 left-[8%] md:left-1/3 top-32 absolute">
              <img src={noHistory} alt="no bid" />
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default HistoryModal;
