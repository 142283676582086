import React from "react";
import footerLogo from "../../Assets/Logo/Footer Logo.png";
import twitter from "../../Assets/Icons/Twitter-White.svg";
import facebook from "../../Assets/Icons/Facebook-White.svg";
import instagram from "../../Assets/Icons/Instagram-White.svg";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const Footer = () => {
  const footerText = useSelector((state) => state.footer.text, shallowEqual);
  const twitterLink = useSelector(
    (state) => state.footer.twitter,
    shallowEqual
  );
  const facebookLink = useSelector(
    (state) => state.footer.facebook,
    shallowEqual
  );
  const instagramLink = useSelector(
    (state) => state.footer.instagram,
    shallowEqual
  );

  const copyRightText = useSelector(
    (state) => state.footer.copyRight,
    shallowEqual
  );

  const pages = useSelector((state) => state.pages.pages, shallowEqual);

  const bidyl = [
    pages.aboutUs && {
      id: 1,
      name: "About Us",
      link: "/about-us",
    },

    pages.faq && {
      id: 2,
      name: "FAQ",
      link: "/faq",
    },

    pages.ourWinners && {
      id: 3,
      name: "Winners",
      link: "/winners",
    },

    pages.charity && {
      id: 4,
      name: "Charity",
      link: "/charity-partner",
    },

    pages.contactUs && {
      id: 5,
      name: "Contact Us",
      link: "/contact-us",
    },
  ];

  const legals = [
    pages.howItWorks && {
      id: 1,
      name: "How it works",
      link: "/how-it-works",
    },

    pages.termsAndCondition && {
      id: 2,
      name: "Terms & Conditions",
      link: "/term-&-condition",
    },

    pages.privacyPolicy && {
      id: 3,
      name: "Privacy Policy",
      link: "privacy-policy",
    },
  ];

  const icons = [
    {
      id: 1,
      name: facebook,
      link: facebookLink,
    },

    {
      id: 2,
      name: twitter,
      link: twitterLink,
    },

    {
      id: 3,
      name: instagram,
      link: instagramLink,
    },
  ];
  return (
    <div className=" h-96 bg-footer-color  py-16">
      <div className="w-11/12 md:w-9/12 container mx-auto flex gap-x-5 md:gap-x-0 justify-between">
        <div className="w-1/2">
          <div className="w-24 h-10 md:w-32 md:h-16">
            <img src={footerLogo} alt="footer logo" />
          </div>
          <p className="text-footer-text text-sm md:text-base mt-2">
            {footerText}
          </p>
          <div className="flex gap-x-3 mt-5">
            {icons.map((item) => {
              return (
                <div
                  className="border border-white p-2 rounded-full w-fit"
                  key={item.id}
                >
                  <a href={item?.link}>
                    <img
                      src={item.name}
                      alt="social media"
                      className="w-5 h-5"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h5 className="font-bold text-white text-xl md:text-2xl">bidyl</h5>
          <div className="text-footer-text mt-2">
            {bidyl &&
              bidyl.map((item) => {
                return (
                  <div key={item?.id} className="py-0.5">
                    <Link to={item?.link}>
                      <p className="text-sm md:text-base">{item?.name}</p>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <h5 className="font-bold text-white text-xl md:text-2xl">Legals</h5>
          <div className="text-footer-text mt-2">
            {legals.map((item) => {
              return (
                <div key={item?.id} className="py-0.5">
                  <Link to={item?.link}>
                    <p className="text-sm md:text-base">{item?.name}</p>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <p className="text-center text-footer-text mt-20 text-sm md:text-base">
        {copyRightText}
      </p>
    </div>
  );
};

export default Footer;
