import React, { useEffect, useState } from "react";
import eyeSlash from "../../../../Assets/Icons/eye-slash.svg";
import Button from "../../../../Component/Button/Button";
import bidHammer from "../../../../Assets/Icons/White-hammer.svg";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetMyWallet } from "../../../../Redux/Profile/action";
import {
  fetchGetProductByIdUser,
  fetchPlaceBid,
} from "../../../../Redux/Product/action";
import Message from "../../../../Component/Message/Message";
import { fetchMyBidsOnProduct } from "../../../../Redux/MyBids/action";

const BiddingPassword = ({
  setShowBiddingPasswordPopup,
  product,
  bidValue,
}) => {
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const message = useSelector((state) => state.product.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.product.errMessage,
    shallowEqual
  );

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  // place a bid button click
  const handlePlaceABid = (id) => {
    const data = {
      product: id,
      bidAmount: bidValue,
      password: password,
    };

    token && dispatch(fetchPlaceBid(data, token));

    token && dispatch(fetchGetProductByIdUser(id, token));
    token && dispatch(fetchMyBidsOnProduct(id, 1, token));
    token && dispatch(fetchGetMyWallet(token));
    errMessage && setErrMsg(errMessage);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (message.length) {
      setShowBiddingPasswordPopup(false);
      Cookies.set("biddingPassword", password, {
        path: "/",
      });
      token && dispatch(fetchGetProductByIdUser(product, token));
      token && dispatch(fetchMyBidsOnProduct(product, 1, token));
      token && dispatch(fetchGetMyWallet(token));
    }
  }, [message]);

  return (
    <div className="absolute w-1/3 pb-10 pt-5 mx-auto shadow-lg left-1/3 border top-32 rounded-lg bg-white z-30">
      <div className="flex justify-center items-center px-10">
        <h4 className="text-primary font-bold text-3xl text-center">
          Bidding Password
        </h4>
      </div>

      {errMessage && (
        <Message
          className="text-red-500 font-bold text-xl"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}

      <div className="px-10 mt-10">
        <label className="text-sm pb-2 font-medium">Bidding Password</label>
        <div className="flex justify-between items-center relative mt-1">
          <input
            type={`${showPassword ? "text" : "password"}`}
            className="border border-gray-300 w-full text-base p-2.5 rounded-lg outline-gray-300"
            onChange={(e) => setPassword(e.target.value)}
          />

          <div
            className="absolute right-4 opacity-40"
            onClick={(e) => setShowPassword(!showPassword)}
          >
            <img src={eyeSlash} alt="eye slash" />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center gap-x-6 mt-8 px-10">
        <div
          className="border w-full rounded-2xl cursor-pointer text-primary font-medium py-4 text-center "
          style={{ background: "#EBF0FA" }}
          onClick={() => setShowBiddingPasswordPopup(false)}
        >
          <Button value="Cancel" />
        </div>

        <div
          className="border w-full rounded-2xl flex items-center justify-center gap-x-3 bg-primary cursor-pointer text-white font-medium py-4 text-center"
          onClick={() => handlePlaceABid(product)}
        >
          <img src={bidHammer} alt="bidding hammer" />
          <p> Place a Bid</p>
        </div>
      </div>
    </div>
  );
};

export default BiddingPassword;
