import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  products: [],
  totalPage: "",
  totalData: "",
  pageNumber: "",
  product: "",
  message: "",
  errMessage: "",
  isBidded: false,
  myBids: [],
};

export const Products = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_BY_CATEGORY:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_PRODUCT_BY_CATEGORY_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_PRODUCT_BY_ID:
      return {
        ...state,
        isloading: false,
        success: true,
        product: action.payload,
      };

    case ActionTypes.GET_PRODUCT_BY_ID_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        product: action.payload,
      };

    case ActionTypes.PLACE_A_BID:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        isBidded: true,
      };

    case ActionTypes.FAIL_TO_PLACE_BID:
      return {
        ...state,
        isloading: false,
        success: false,
        errMessage: action.payload.message,
        isBidded: false,
      };

    case ActionTypes.PLACE_AUTO_BID:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.FAIL_PLACE_AUTO_BID:
      return {
        ...state,
        isloading: false,
        success: false,
        errMessage: action.payload.message,
      };

    case ActionTypes.SEARCH_PRODUCT_BY_CATEGORIES:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.SEARCH_PRODUCT_BY_CATEGORIES_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_BIDS_ON_PRODUCT:
      return {
        ...state,
        isloading: false,
        success: true,
        myBids: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_PRODUCT_BY_COUNTRY:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_PRODUCT_BY_COUNTRY_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        products: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.REMOVE_VIEWED_PRODUCT:
      return {
        isloading: true,
        success: true,
        products: "",
        totalData: "",
        totalPage: "",
        pageNumber: "",
        product: "",
        message: "",
        errMessage: "",
        isBidded: false,
      };

    default:
      return state;
  }
};
