import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";
import eyeSlash from "../../Assets/Icons/eye-slash.svg";

const PasswordField = ({
  label,
  placeholder,
  name,
  setShowPassword,
  showPassword,
  ...rest
}) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <div className="flex justify-between items-center relative">
        <Field
          id={name}
          name={name}
          {...rest}
          className="border border-gray-300 w-full text-base p-2.5 rounded-lg outline-gray-300"
          placeholder={placeholder}
        />
        <div
          className="absolute right-4 opacity-40"
          onClick={(e) => setShowPassword(!showPassword)}
        >
          <img src={eyeSlash} alt="eye slash" />
        </div>
      </div>
    </div>
  );
};

export default PasswordField;
