import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Component/Loading/Loading";
import {
  fetchGetProductById,
  fetchGetProductByIdUser,
  fetchPlaceBid,
  removeViewedProduct,
} from "../../../Redux/Product/action";
import Breadcrum from "./Breadcrum";
import Container from "./Container";
import Description from "./Description";
import MyBid from "./MyBid";
import VendorInfo from "./VendorInfo";
import Questions from "./Questions";
import Message from "../../../Component/Message/Message";
import LoginPopupModal from "./AuthenticationPopup/LoginPopupModal";
import SignupPopupModal from "./AuthenticationPopup/SignupPopupModal";
import AutoBidPopup from "./AutoBidPopup/AutoBidPopup";
import AutoBidPreviewPopup from "./AutoBidPopup/AutoBidPreviewPopup";
import Cookies from "js-cookie";
import { fetchGetMyWallet } from "../../../Redux/Profile/action";
import BiddingPassword from "./BiddingPasswordPopup/BiddingPassword";
import { fetchMyBidsOnProduct } from "../../../Redux/MyBids/action";
import OTP from "./AuthenticationPopup/OTP";
import AutoBidPassword from "./BiddingPasswordPopup/AutoBidPassword";
import { Helmet } from "react-helmet";

const ProductDetail = () => {
  // login and register popup
  const [authenticateCheck, setAuthenticateCheck] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const [twoFactorCheck, setTwoFactorCheck] = useState(false);
  // autobid popup state
  const [autoBidPopup, setAutoBidPopup] = useState(false);
  const [autoBidPreview, setAutoBidPreview] = useState(false);

  const [showDescription, setShowDescription] = useState(true);
  const [showMyBids, setShowMyBids] = useState(false);
  const [showVendorInfo, setShowVendorInfo] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showBiddingPasswordPopup, setShowBiddingPasswordPopup] =
    useState(false);
  const [showAutoBiddingPasswordpopup, setShowAutoBiddingPasswordPopup] =
    useState(false);

  // bid value state
  const [bidValue, setBidValue] = useState("");

  const [succMsg, setSuccMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [productId, setProductId] = useState("");

  // state for autho bids
  const [minimunBidValue, setMinimumBidValue] = useState("");
  const [numOfBid, setNumOfBid] = useState("");
  const [bidIncrement, setBidIncrement] = useState("");

  // retrieve cookie
  const token = Cookies.get("token");
  const search = useLocation().search;
  const location = useLocation();

  const loading = useSelector((state) => state.product.isloading, shallowEqual);
  const product = useSelector((state) => state.product.product, shallowEqual);

  const questions = useSelector(
    (state) => state.product.product.questions,
    shallowEqual
  );
  const hasBiddingPassword = useSelector(
    (state) => state.product.product.hasBiddingPassword,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setProductId(new URLSearchParams(search).get("index"));
  }, [location.search]);

  useEffect(() => {
    token
      ? dispatch(fetchGetProductByIdUser(productId, token))
      : dispatch(fetchGetProductById(productId));

    return () => {
      dispatch(removeViewedProduct());
    };
  }, [productId]);

  useEffect(() => {
    succMsg && dispatch(fetchGetProductByIdUser(productId, token));
  }, [succMsg]);

  // handle description click
  const handleDescriptionClick = () => {
    setShowDescription(true);
    setShowMyBids(false);
    setShowVendorInfo(false);
    setShowQuestions(false);
  };

  // handle mybids click
  const handleMybidsClick = () => {
    setShowDescription(false);
    setShowMyBids(true);
    setShowVendorInfo(false);
    setShowQuestions(false);
  };

  // handle vendor info click
  const handleVendorInfoClick = () => {
    setShowDescription(false);
    setShowMyBids(false);
    setShowVendorInfo(true);
    setShowQuestions(false);
  };

  // handle question click
  const handleQuestionClick = () => {
    setShowDescription(false);
    setShowMyBids(false);
    setShowVendorInfo(false);
    setShowQuestions(true);
  };

  const handleClosePopup = () => {
    setAuthenticateCheck(false);
    setSignupPopup(false);
    setAutoBidPopup(false);
    setAutoBidPreview(false);
    setShowBiddingPasswordPopup(false);
    setShowAutoBiddingPasswordPopup(false);
  };

  const successMessage = useSelector(
    (state) => state.product.message,
    shallowEqual
  );

  const errMessage = useSelector(
    (state) => state.product.errMessage,
    shallowEqual
  );

  useEffect(() => {
    successMessage && setSuccMsg(successMessage);
    successMessage && setBidValue("");
    successMessage && setMinimumBidValue("");
    successMessage && setNumOfBid("");
    successMessage && setBidIncrement("");
  }, [successMessage]);

  useEffect(() => {
    errMessage && setErrMsg(errMessage);
    errMessage && setBidValue("");
    errMessage && setMinimumBidValue("");
    errMessage && setNumOfBid("");
    errMessage && setBidIncrement("");
  }, [errMessage]);

  // setBidding value
  const handleSetBidValue = (e) => {
    setBidValue(e.target.value);
  };

  // retrieve bidding password
  const biddingPassword = Cookies.get("biddingPassword");

  // place a bid button click
  const handlePlaceABid = (id) => {
    const data = {
      product: id,
      bidAmount: bidValue,
      password: biddingPassword,
    };
    !token && setAuthenticateCheck(true);

    if (hasBiddingPassword === true && !biddingPassword && bidValue) {
      setShowBiddingPasswordPopup(true);
    } else {
      token && dispatch(fetchPlaceBid(data, token));
      token && dispatch(fetchGetMyWallet(token));
      token && dispatch(fetchGetProductByIdUser(id, token));
      token && dispatch(fetchMyBidsOnProduct(id, 1, token));

      errMessage && setErrMsg(errMessage);
      successMessage && setSuccMsg(successMessage);
    }
    token && dispatch(fetchGetMyWallet(token));
    token && dispatch(fetchMyBidsOnProduct(id, 1, token));
    biddingPassword && setBidValue("");
    window.scrollTo(0, 0);
  };

  // onChange event of auto bid
  const minBidOnchangeHandle = (e) => {
    setMinimumBidValue(e.target.value);
  };
  const numBidOnchangeHandler = (e) => {
    setNumOfBid(e.target.value);
  };
  const bidIncrementOnchangeHandler = (e) => {
    setBidIncrement(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="relative pt-28 md:pt-40">
      <Helmet>
        <title>{`${product?.title} | Bidyl`}</title>
      </Helmet>
      {succMsg && (
        <Message
          className="w-fit border py-2 px-5 bg-green-500 text-white font-bold text-xl"
          message={succMsg}
          setMessage={setSuccMsg}
        />
      )}

      {errMsg && (
        <Message
          className="w-fit border py-2 px-5 bg-red-500 text-white font-bold text-xl"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}
      <div className="w-11/12 xl:w-3/4 mx-auto pb-20">
        <Breadcrum
          title="Home"
          subTitle={product?.category?.categoryName}
          productName={product.title}
        />
        <hr className="mt-4" />

        <div className="mt-10">
          <Container
            product={product}
            setAuthenticateCheck={setAuthenticateCheck}
            setAutoBidPopup={setAutoBidPopup}
            handlePlaceABid={handlePlaceABid}
            handleSetBidValue={handleSetBidValue}
            bidValue={bidValue}
          />
        </div>

        {/* menu section */}
        <div className="w-full py-1 md:py-3 px-1 md:px-5 rounded-2xl mt-14 md:mt-20 border flex justify-between gap-x-1 md:gap-x-7 items-center bg-slate-100">
          <h6
            className={`${
              showDescription === true
                ? "text-primary bg-white border rounded-lg font-bold"
                : "text-secondary"
            } text-[0.5rem] md:text-xl py-5 md:px-4 w-full text-center cursor-pointer`}
            onClick={handleDescriptionClick}
          >
            Descriptions
          </h6>
          <h6
            className={`${
              showMyBids === true
                ? "text-primary bg-white border rounded-lg font-bold"
                : "text-secondary"
            } ${
              !token && "hidden"
            } text-[0.5rem] md:text-xl py-5 px-4 w-full text-center cursor-pointer`}
            onClick={handleMybidsClick}
          >
            My Bids
          </h6>
          <h6
            className={`${
              showVendorInfo === true
                ? "text-primary bg-white border rounded-lg font-bold"
                : "text-secondary"
            } text-[0.5rem] md:text-xl py-5 px-4 w-full text-center cursor-pointer`}
            onClick={handleVendorInfoClick}
          >
            Vendor Information
          </h6>
          <h6
            className={`${
              showQuestions === true
                ? "text-primary bg-white border rounded-lg font-bold"
                : "text-secondary"
            } text-[0.5rem] md:text-xl py-5 px-4 w-full text-center cursor-pointer`}
            onClick={handleQuestionClick}
          >
            Questions
          </h6>
        </div>

        {showDescription && (
          <div className="w-full mt-7 h-[15rem] overflow-y-auto">
            <Description product={product} />
          </div>
        )}
        {showMyBids && (
          <div className="w-full h-[26rem] overflow-y-auto mt-7">
            <MyBid productId={productId} />
          </div>
        )}
        {showVendorInfo && (
          <div className="w-full mt-7">
            <VendorInfo product={product} />
          </div>
        )}
        {showQuestions && (
          <div className="w-full mt-7">
            <Questions questions={questions} />
          </div>
        )}
      </div>

      {/* authentication popup */}
      {authenticateCheck && (
        <LoginPopupModal
          setAuthenticateCheck={setAuthenticateCheck}
          setSignupPopup={setSignupPopup}
          product={product}
          twoFactorCheck={twoFactorCheck}
          setTwoFactorCheck={setTwoFactorCheck}
        />
      )}

      {/* signup popup */}
      {signupPopup && (
        <SignupPopupModal
          setSignupPopup={setSignupPopup}
          setAuthenticateCheck={setAuthenticateCheck}
        />
      )}
      {/* 2-fa check */}
      {twoFactorCheck && (
        <OTP
          setTwoFactorCheck={setTwoFactorCheck}
          // redirectUrl={redirectUrl}
        />
      )}

      {/* autoBid popup */}
      {autoBidPopup && (
        <AutoBidPopup
          setAutoBidPopup={setAutoBidPopup}
          setAutoBidPreview={setAutoBidPreview}
          minBidOnchangeHandle={minBidOnchangeHandle}
          numBidOnchangeHandler={numBidOnchangeHandler}
          bidIncrementOnchangeHandler={bidIncrementOnchangeHandler}
          minimunBidValue={minimunBidValue}
          numOfBid={numOfBid}
          bidIncrement={bidIncrement}
          setMinimumBidValue={setMinimumBidValue}
          setNumOfBid={setNumOfBid}
          setBidIncrement={setBidIncrement}
        />
      )}

      {/* autoBid Preview popup */}
      {autoBidPreview && (
        <AutoBidPreviewPopup
          setAutoBidPreview={setAutoBidPreview}
          setAutoBidPopup={setAutoBidPopup}
          minimunBidValue={minimunBidValue}
          numOfBid={numOfBid}
          bidIncrement={bidIncrement}
          productId={productId}
          setShowAutoBiddingPasswordPopup={setShowAutoBiddingPasswordPopup}
        />
      )}

      {/* show bidding passsword popup */}
      {showBiddingPasswordPopup && (
        <BiddingPassword
          setShowBiddingPasswordPopup={setShowBiddingPasswordPopup}
          product={product.id}
          setBidValue={setBidValue}
          bidValue={bidValue}
          handlePlaceABid={handlePlaceABid}
        />
      )}

      {showAutoBiddingPasswordpopup && (
        <AutoBidPassword
          productId={productId}
          setShowAutoBiddingPasswordPopup={setShowAutoBiddingPasswordPopup}
          minimunBidValue={minimunBidValue}
          numOfBid={numOfBid}
          bidIncrement={bidIncrement}
        />
      )}

      {/* backdrop popup */}
      {showBiddingPasswordPopup ? (
        <div
          className="w-10/12 h-full absolute top-0"
          onClick={() => setShowBiddingPasswordPopup(false)}
        ></div>
      ) : null}

      {/* backdrop popup */}
      {authenticateCheck || signupPopup || autoBidPopup || autoBidPreview ? (
        <div
          className="w-full h-full absolute top-0 bg-black bg-opacity-10"
          onClick={handleClosePopup}
        ></div>
      ) : null}
    </div>
  );
};

export default ProductDetail;
