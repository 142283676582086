import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Component/Loading/Loading";

const StripeCancel = () => {
  let params = new URLSearchParams(document.location.search);
  let id = params.get("key");

  const navigate = useNavigate();

  useEffect(() => {
    id && navigate("/checkout");
  }, [id]);

  if (!id) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return <div>StripeCancel</div>;
};

export default StripeCancel;
