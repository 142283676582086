import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  fetchAddItemToCart,
  fetchAddItemToTempCart,
  fetchGetCartData,
  fetchGetTempCartData,
} from "../../Redux/Cart/action";
import Button from "../Button/Button";
import Cookies from "js-cookie";

const BidCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // retrieve cookie
  const token = Cookies.get("token");

  const cartId = localStorage.getItem("cartId");

  const handleBuyBids = (id) => {
    const body = {
      bidPackageId: id,
      quantity: 1,
    };

    !token && cartId && dispatch(fetchAddItemToTempCart(body, cartId));
    !token && !cartId && dispatch(fetchAddItemToTempCart(body));
    !token && dispatch(fetchGetTempCartData(cartId));
    !token && navigate("/user-cart");
    !token && dispatch(fetchGetTempCartData(cartId));
    token && dispatch(fetchAddItemToCart(body, token));
    token && dispatch(fetchGetCartData(token));
    token && navigate("/my-cart");
    token && dispatch(fetchGetCartData(token));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerSliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customBid">
        <div className="loading" />
      </div>
    ),

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 935,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },

      {
        breakpoint: 659,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 467,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 310,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <Slider {...settings} ref={bannerSliderRef}>
        {data &&
          data.map((item) => {
            return (
              <div className="px-2" key={item.id}>
                <div className=" mt-16 relative overflow-hidden" key={item.id}>
                  <h1
                    className={` py-2 -rotate-45 w-full flex justify-center items-center text-white text-base xl:text-lg overflow-hidden top-[15px] left-[-70px] xl:left-[-80px] 2xl:left-[-100px] absolute ${
                      item.isNew === true
                        ? "bg-primary"
                        : item.isPopular === true
                        ? "bg-bid-card-brand"
                        : ""
                    }`}
                  >
                    {(item.isNew && "New") || (item.isPopular && "Popular")}
                  </h1>

                  <div className="p-6 h-80 border rounded-2xl border-border-color z-50">
                    <h5 className="font-bold text-2xl text-center py-5 border-b border-border-color ">
                      {item.amount ? `${item.amount} Bids` : item.title}
                    </h5>

                    <h4 className="font-bold text-3xl py-5 mt-10 text-center">
                      ${item.price}
                    </h4>

                    <div
                      className="mt-5"
                      onClick={() =>
                        handleBuyBids(item.id, item.amount, item.price)
                      }
                    >
                      <Button
                        className="bg-primary font-medium py-3 rounded-lg text-white w-full"
                        value="BUY NOW"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default BidCard;

export const GridBidCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // retrieve cookie
  const token = Cookies.get("token");

  const cartId = localStorage.getItem("cartId");

  const handleBuyBids = (id, value, price) => {
    const body = {
      bidPackageId: id,
      quantity: 1,
    };

    !token && cartId && dispatch(fetchAddItemToTempCart(body, cartId));
    !token && !cartId && dispatch(fetchAddItemToTempCart(body));
    !token && dispatch(fetchGetTempCartData(cartId));
    !token && navigate("/user-cart");
    !token && dispatch(fetchGetTempCartData(cartId));
    token && dispatch(fetchAddItemToCart(body, token));
    token && dispatch(fetchGetCartData(token));
    token && navigate("/my-cart");
    token && dispatch(fetchGetCartData(token));
  };

  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {data &&
        data.map((item) => {
          return (
            <div className="px-2" key={item.id}>
              <div
                className="mt-10 md:mt-16 relative overflow-hidden"
                key={item.id}
              >
                <h1
                  className={` py-2 -rotate-45 w-full flex justify-center items-center text-white text-base xl:text-lg overflow-hidden top-[15px] left-[-70px] xl:left-[-80px] 2xl:left-[-100px] absolute ${
                    item.isNew === true
                      ? "bg-primary"
                      : item.isPopular === true
                      ? "bg-bid-card-brand"
                      : ""
                  }`}
                >
                  {(item.isNew && "New") || (item.isPopular && "Popular")}
                </h1>

                <div className="p-6 h-80 border rounded-2xl border-border-color z-50">
                  <h5 className="font-bold text-2xl text-center py-5 border-b border-border-color ">
                    {item.amount ? `${item.amount} Bids` : item.title}
                  </h5>

                  <h4 className="font-bold text-3xl py-5 mt-10 text-center">
                    ${item.price}
                  </h4>

                  <div
                    className="mt-5"
                    onClick={() =>
                      handleBuyBids(item.id, item.amount, item.price)
                    }
                  >
                    <Button
                      className="bg-primary font-medium py-3 rounded-lg text-white w-full"
                      value="BUY NOW"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
