import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ReactPasswordChecklist from "react-password-checklist";
import { Link } from "react-router-dom";
import FormikControl from "../../../../Component/Formik/FormikControl";
import closeIcon from "../../../../Assets/Icons/Close_MD.svg";
import Header from "../../../Login/Register/Header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchSignup, removeRegister } from "../../../../Redux/Register/action";
import Message from "../../../../Component/Message/Message";

const SignupPopupModal = ({ setSignupPopup, setAuthenticateCheck }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [accepted, setAccepted] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  // retrieve successMessage from redux
  const successMessage = useSelector(
    (state) => state.signup.message,
    shallowEqual
  );

  useEffect(() => {
    setSuccMsg(successMessage);
    successMessage && setSignupPopup(false);
    successMessage && setAuthenticateCheck(true);
  }, [successMessage]);

  // retrieve errorMessgae from redux
  const errMessage = useSelector((state) => state.signup.errMsg, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const dispatch = useDispatch();

  // initial formik values
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  // formik form validation with Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required *"),
    lastName: Yup.string().required("Required *"),
    email: Yup.string().email("Invalid email").required("Required *"),
    password: Yup.string().required("Required *"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchSignup({ ...values, hasAccepted: accepted }));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeRegister());
    }, 10000);

    window.scrollTo(0, 0);
  };

  const showLoginPopup = () => {
    setSignupPopup(false);
    setAuthenticateCheck(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="absolute w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 px-10 pb-10 pt-5 mx-auto shadow-lg left-4 md:left-[14%] lg:left-[27%] xl:left-1/3 border top-28 md:top-32 rounded-lg bg-white z-30">
      {succMsg && (
        <Message
          className="text-green-500"
          message={succMsg}
          setMessage={setSuccMsg}
        />
      )}

      {errMsg && (
        <Message
          className="text-red-500"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}
      <div className="flex justify-end">
        <img
          src={closeIcon}
          alt="close Icon"
          className="w-10 h-10 cursor-pointer"
          onClick={() => setSignupPopup(false)}
        />
      </div>
      <Header
        text="Welcome"
        message="Back"
        info="You can log into your bidyl account"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <div className=" w-full">
              <div className="flex w-full flex-col md:flex-row justify-between gap-x-5">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    placeholder="Eg: John"
                    control="input"
                    name="firstName"
                  />
                </div>
                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Last Name"
                    placeholder="Eg: Doe"
                    control="input"
                    name="lastName"
                  />
                </div>
              </div>
              <div className="mt-5">
                <FormikControl
                  label="Email Address"
                  placeholder="Enter your email"
                  control="input"
                  name="email"
                  type="email"
                />
              </div>
              <div className="mt-5">
                <FormikControl
                  label="Password"
                  placeholder="Enter your password"
                  control="password"
                  name="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>

              {formik.values.password && (
                <div className="text-start mt-3 text-sm">
                  <p>Password should be: </p>
                  <ReactPasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={4}
                    iconSize={0}
                    validColor="green"
                    value={formik.values.password}
                    messages={{
                      minLength: "Atleast 4 characters long",
                      specialChar: "Minimum 1 special character",
                      number: "Minimum 1 number",
                      capital: "Minimum 1 uppercase",
                    }}
                  />
                </div>
              )}

              <div className="mt-5">
                <FormikControl
                  label="Confirm password"
                  placeholder="Confirm your password"
                  control="password"
                  name="confirmPassword"
                  type={`${showCPassword ? "text" : "password"}`}
                  setShowPassword={setShowCPassword}
                  showPassword={showCPassword}
                />
              </div>

              <div className="flex gap-x-2">
                <input
                  type="checkbox"
                  className="w-12 h-12 mt-3"
                  onClick={() => setAccepted(!accepted)}
                />
                <h1 className="text-gray-400 mt-6">
                  By continuing, you acknowledge that you have read and
                  understood, and agree to Bidyl’s{" "}
                  <span className="text-primary font-bold">
                    <Link to="/term-&-Condition">Terms & Conditions </Link>
                  </span>
                  and{" "}
                  <span className="text-primary font-bold">
                    <Link to="/privacy-policy">Privacy Policy.</Link>
                  </span>
                </h1>
              </div>

              <div className="mt-5">
                <button
                  disabled={`${accepted ? "" : true}`}
                  type="submit"
                  className={`font-medium py-3 rounded-2xl text-white w-full ${
                    accepted ? "bg-primary " : "bg-blue-300"
                  }`}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex items-center justify-center gap-x-2 mt-3">
        <p>Already have an account?</p>

        <p
          className="text-primary font-bold cursor-pointer"
          onClick={showLoginPopup}
        >
          Log in
        </p>
      </div>
    </div>
  );
};

export default SignupPopupModal;
