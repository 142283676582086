import React from "react";
import Slider from "react-slick";
import Loading from "../../Component/Loading/Loading";
import NoImages, { NoImagesFullRounded } from "../../Component/NoData/NoImages";

const WinnersCard = ({ data }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  if (!data) {
    <div className="w-full h-screen">
      <Loading />
    </div>;
  }
  return (
    <Slider {...settings}>
      {data &&
        data.map((data) => {
          return (
            <div className="md:px-5 h-60 md:h-96" key={data.id}>
              <div className="flex gap-x-5 xl:gap-x-16">
                <div className="w-36 h-40 md:w-1/2 md:h-80">
                  {data.images?.length ? (
                    <img
                      src={data.images?.[0]?.image}
                      alt="product img"
                      className="w-full h-full rounded-2xl"
                    />
                  ) : (
                    <NoImages />
                  )}
                </div>

                <div className="w-1/2 xl:w-[48%] relative">
                  <h4 className="font-bold w-10/12 truncate text-base xl:text-3xl mx-auto">
                    {data.winner?.fullName} from {data.country} won {data.title}
                  </h4>
                  <div className=" flex justify-between w-10/12 mx-auto items-center mt-8">
                    <div className="flex items-center gap-x-2 xl:gap-x-4">
                      <div className="w-12 h-12">
                        {data.winner?.image ? (
                          <img
                            src={data.winner?.image}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        ) : (
                          <NoImagesFullRounded />
                        )}
                      </div>
                      <div>
                        <h6 className="font-bold text-sm xl:text-xl">
                          {data.winner.fullName}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="w-10/12 mt-4 mx-auto">
                    <p className="text-secondary font-base text-start text-xs xl:text-xl">
                      {data.winnersComment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Slider>
  );
};

export default WinnersCard;
