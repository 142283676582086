import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const qrLoding = () => ({
  type: ActionTypes.QR_LOADING,
});

export const fetchGetQR = (token) => (dispatch) => {
  dispatch(qrLoding());

  return axios
    .get(`${BaseUrl}/bidyl/v1/api/client/get-qr-two-factor`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      if (data.isSuccess) {
        dispatch({
          type: ActionTypes.GET_QR,
          payload: data,
        });
      }
    });
};
