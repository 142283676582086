import React from "react";
import Button from "../../Component/Button/Button";
import edit from "../../Assets/Icons/edit-2.svg";
import { NoImagesFullRounded } from "../../Component/NoData/NoImages";

const ProfileModal = ({
  data,
  setPersonalDetailPopupModal,
  setLocationDetailPopupModal,
  setImagePopupModal,
}) => {
  return (
    <div className="mt-10">
      {/* profile Image section */}
      <div className="flex gap-x-10">
        <div className="w-20 h-20 md:w-40 md:h-40">
          {data.profilePicture ? (
            <img
              src={data.profilePicture}
              alt="profile"
              className="w-full h-full rounded-full"
            />
          ) : (
            <NoImagesFullRounded />
          )}
        </div>
        <div className="mt-3">
          <h4 className="font-bold text-xl md:text-3xl">{data.fullName}</h4>
          <h6 className="text-base md:text-xl text-secondary">
            {data.state}, {data.country}
          </h6>

          <div
            className="border border-primary rounded-2xl text-sm md:text-base py-3 md:py-4 px-5 text-primary mt-5 md:mt-10 font-medium"
            onClick={() => setImagePopupModal(true)}
          >
            <Button value="Change Profile Picture" />
          </div>
        </div>
      </div>

      {/* Personal Details */}
      <div className="py-2 align-middle w-2/3 mt-16">
        <div className="flex items-center justify-between mb-4">
          <h5 className="font-bold text-primary text-lg md:text-2xl">
            Personal Details
          </h5>
          <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => setPersonalDetailPopupModal(true)}
          >
            <img src={edit} alt="edit icon" />
            <p className="text-primary font-medium">Edit</p>
          </div>
        </div>
        <table className="table-auto w-3/4">
          <thead className="w-full">
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody className="bg-white">
            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap text-sm md:text-base flex justify-between">
                <p className="text-secondary">Name</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.fullName ? data.fullName : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Email Address</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.email ? data.email : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Phone Number</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.numberWithCode ? data.numberWithCode : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Date of Birth</p>
                <p>:</p>
              </td>
              <td className="py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.dob ? data.dob : "N/A"}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr className="mt-10" />

      {/* Location Details */}
      <div className="py-2 align-middle w-2/3 mt-12">
        <div className="flex items-center justify-between mb-4">
          <h5 className="font-bold text-primary text-lg md:text-2xl">
            Location Details
          </h5>
          <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => setLocationDetailPopupModal(true)}
          >
            <img src={edit} alt="edit icon" />
            <p className="text-primary font-medium">Edit</p>
          </div>
        </div>
        <table className="table-auto w-3/4">
          <thead className="w-full">
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody className="bg-white">
            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Country</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.country ? data.country : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Street Address</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold px-8 text-sm md:text-lg">
                  {data.streetAddress ? data.streetAddress : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">State</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.state ? data.state : "N/A"}
                </h6>
              </td>
            </tr>

            <tr>
              <td className="py-2 md:py-3 whitespace-nowrap flex justify-between text-sm md:text-base">
                <p className="text-secondary">Post Code</p>
                <p>:</p>
              </td>
              <td className="py-2 md:py-3 whitespace-nowrap">
                <h6 className="font-bold text-sm md:text-lg px-8">
                  {data.postCode ? data.postCode : "N/A"}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfileModal;
