import React from "react";
import Slider from "react-slick";
import { NoImagesFullRounded } from "../NoData/NoImages";

const WinnerReview = ({ data }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customWinner">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <Slider {...settings}>
      {data &&
        data.map((item) => {
          return (
            <div className="px-2 w-80  mt-16" key={item.id}>
              <div className="h-72 border rounded-2xl relative">
                <div className="w-11/12 mx-auto mt-12">
                  <p className="text-secondary text-base lg:text-xl text-center">
                    {item.winnersComment}
                  </p>
                  <div className="absolute bottom-0 pb-8 flex justify-between w-10/12 items-center">
                    <div>
                      <h6 className="font-bold text-base lg:text-xl">
                        {item.winner.fullName}
                      </h6>
                    </div>
                    <div className="w-12 h-12 lg:w-14 lg:h-14 mt-2">
                      {item.winner?.image ? (
                        <img
                          src={item.winner?.image}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      ) : (
                        <NoImagesFullRounded />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Slider>
  );
};

export default WinnerReview;

export const GridWinnerReview = ({ data }) => {
  return (
    <div className="w-full grid grid-cols-3 gap-x-3">
      {data &&
        data.map((item) => {
          return (
            <div className="px-2 w-80  mt-16" key={item.id}>
              <div className="h-72 border rounded-2xl relative">
                <div className="w-11/12 mx-auto mt-12">
                  <p className="text-secondary text-base lg:text-xl  text-center">
                    {item.winnersComment}
                  </p>
                  <div className="absolute bottom-0 pb-8 flex justify-between w-10/12 items-center">
                    <div>
                      <h6 className="font-bold text-xl">
                        {item.winner.fullName}
                      </h6>
                    </div>
                    <div className="w-14 h-14 mt-2">
                      {item.winner?.image ? (
                        <img
                          src={item.winner?.image}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      ) : (
                        <NoImagesFullRounded />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
