import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const mybidLoading = () => ({
  type: ActionTypes.MY_BID_LOADING,
});

export const removeMyBidData = () => ({
  type: ActionTypes.REMOVE_MY_BID_DATA,
});

export const fetchMyBidsOnProduct = (productId, page, token) => (dispatch) => {
  dispatch(mybidLoading());
  axios
    .get(
      `${BaseUrl}/bidyl/v1/api/bid/get-mine-by-product/${productId}?page=${page}&pageSize=5`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_BIDS_ON_PRODUCT,
        payload: data,
      });
    });
};
