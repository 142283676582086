import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "../../Component/Button/Button";
import WrapperCard from "../../Component/Card/WrapperCard";
import VideoCarousel from "../../Component/Carousell/VideoCarousel";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import { fetchGetActiveCharityPartners } from "../../Redux/Charity/action";
import { Helmet } from "react-helmet";

const CharitySection = () => {
  let params = new URLSearchParams(document.location.search);
  let id = params.get("index");

  const loading = useSelector((state) => state.charity.isloading, shallowEqual);

  const partners = useSelector((state) => state.charity.partners, shallowEqual);

  const data = partners.find((item) => item.id === Number(id));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetActiveCharityPartners());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-12 pb-20">
      <Helmet>
        <title>Charity Detail | Bidyl</title>
      </Helmet>
      <VideoCarousel video={data.charity?.carouselImages} />
      {/* live draw section */}
      <div
        className={`${
          data.charity.carouselImages && data.charity.carouselImages.length
            ? "mt-0"
            : "mt-16"
        } md:py-10`}
        style={{ background: "#FAFBFB" }}
      >
        <div className="w-11/12 xl:w-1/2 mt-7 mx-auto flex justify-center items-center flex-col">
          <TitleContainer
            title={data.charity?.secondTitleText}
            paragraph={data.charity?.secondSubText}
          />
          <Link to="/categories">
            <Button
              className="bg-primary text-white font-bold text-sm xl:text-xl px-10 text-center py-3.5 w-fit rounded-full mt-12"
              value="Enter Our Live Draw Now"
            />
          </Link>
        </div>
      </div>

      {/* charitysection */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-16">
        {data.charity?.detailsPageData &&
          data.charity?.detailsPageData.map((item, i) => {
            return (
              <div key={item.id} className="mt-16">
                <WrapperCard
                  className={`${
                    i % 2 === 0 ? "" : "flex-row-reverse"
                  } flex items-center gap-x-10 md:gap-x-20 `}
                  data={item}
                  button={
                    <Link to="/categories">
                      <Button
                        className="bg-primary text-white font-bold text-xs md:text-sm xl:text-xl px-5 xl:px-8 text-center py-3 w-fit rounded-2xl mt-5"
                        value="Enter Our Live Draws"
                      />
                    </Link>
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CharitySection;
