import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const contactUsLoading = () => ({
  type: ActionTypes.CONTACT_US_LOADING,
});

export const removeMessage = () => ({
  type: ActionTypes.REMOVE_MESSAGE,
});

export const fetchGetAllCountry = (key) => (dispatch) => {
  dispatch(contactUsLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/country/get-all?keyword=${key}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_COUNTRY,
        payload: data,
      });
    });
};

export const fetchPostMessage = (body) => (dispatch) => {
  dispatch(contactUsLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/contactUs/add`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_MESSAGE,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_SEND,
        payload: e.response.data,
      });
    });
};

export const fetchGetAllData = () => (dispatch) => {
  dispatch(contactUsLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/contact-us-page/get`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_CONTACT_DATA,
        payload: data,
      });
    });
};
