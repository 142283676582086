import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: false,
  success: "",
  message: "",
  errMsg: "",
  verify: false,
};

export const Signup = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        message: action.payload.message,
      };

    case ActionTypes.REGISTER_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_EMAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        verify: true,
      };

    case ActionTypes.FAIL_VERIFY_EMAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
        verify: false,
      };

    case ActionTypes.REMOVE_REGISTER:
      return {
        message: "",
        errMsg: "",
        success: "",
      };
    default:
      return state;
  }
};
