import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const registerLoading = () => ({
  type: ActionTypes.REGISTER_LOADING,
});

export const removeRegister = () => ({
  type: ActionTypes.REMOVE_REGISTER,
});

export const fetchSignup = (body) => (dispatch) => {
  dispatch(registerLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/client/register`, body)
    .then(({ data }) => {
      console.log("data", data);
      if (data.isSuccess) {
        dispatch({
          type: ActionTypes.REGISTER,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.REGISTER_FAIL,
        payload: err.response.data,
      });
    });
};

export const fetchVerifyUserEmail = (body) => (dispatch) => {
  dispatch(registerLoading());
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/verify-email`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_EMAIL,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_VERIFY_EMAIL,
        payload: e.response.data,
      });
    });
};
