import React, { useEffect, useState } from "react";
import Header from "./Header";
import deleteIcon from "../../Assets/Icons/bag-cross.svg";
import Table from "../../Component/Table/Table";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDecreaseQuantity,
  fetchGetCartData,
  fetchGetTempCartData,
  fetchIncreaseQuantity,
  fetchRemoveAllOrder,
  fetchRemoveOrder,
  removeCartData,
} from "../../Redux/Cart/action";
import Loading from "../../Component/Loading/Loading";
import Message from "../../Component/Message/Message";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const headings = [
  {
    id: 1,
    name: "item",
  },
  {
    id: 2,
    name: "quantity",
  },
  {
    id: 3,
    name: "total",
  },
  {
    id: 4,
    name: "",
  },
];

const MyCart = () => {
  const [showCartMsg, setShowCartMsg] = useState(true);
  const [msg, setMsg] = useState();
  const loading = useSelector((state) => state.cart.isloading, shallowEqual);
  const cartData = useSelector((state) => state.cart.orders, shallowEqual);

  const subTotal = useSelector((state) => state.cart.subtotal, shallowEqual);
  const total = useSelector((state) => state.cart.total, shallowEqual);
  const message = useSelector((state) => state.cart.message, shallowEqual);
  const giftMessage = useSelector(
    (state) => state.giftCard.message,
    shallowEqual
  );
  const dispatch = useDispatch();

  // retrieve cookie
  const token = Cookies.get("token");

  useEffect(() => {
    setMsg(message);

    setTimeout(() => {
      message && dispatch(fetchGetCartData(token));
    }, 1000);
  }, [message]);

  useEffect(() => {
    setMsg(giftMessage);
  }, [giftMessage]);

  // delete all orders
  const handleRemoveALlOrder = () => {
    token && dispatch(fetchRemoveAllOrder(token));
  };

  // delete orders
  const handleDelete = (id) => {
    token && dispatch(fetchRemoveOrder(id, token));
  };

  // increase quantity
  const handleIncreaseQuantity = (orderId) => {
    token && dispatch(fetchIncreaseQuantity(orderId, token));
  };

  // decrease qunatity
  const handleDecreaseQuantity = (orderId) => {
    token && dispatch(fetchDecreaseQuantity(orderId, token));
  };

  useEffect(() => {
    token && dispatch(fetchGetCartData(token));
    return () => {
      dispatch(removeCartData());
    };
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(fetchGetCartData(token));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="relative">
      <Helmet>
        <title>My Cart | Bidyl</title>
      </Helmet>
      {showCartMsg && (
        <div
          className="absolute z-30 top-0 w-full h-full"
          onClick={() => setShowCartMsg(false)}
        ></div>
      )}
      <div className="w-11/12 md:w-3/4 mx-auto pt-24 md:pt-40 pb-20">
        {msg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-green-500 text-white"
            message={msg}
            setMessage={setMsg}
          />
        )}

        <Header
          data={cartData}
          showMsg={showCartMsg}
          setShowMsg={setShowCartMsg}
        />

        <div className="mt-12 min-h-[80vh] w-full grid grid-cols-12 gap-x-7">
          <div className="col-span-12 md:col-span-7 lg:col-span-8 bg-white">
            <div className="flex justify-between items-center">
              <h5 className="font-bold text-xl md:text-2xl">Order</h5>
              <div
                className="flex items-center gap-x-2 cursor-pointer"
                onClick={handleRemoveALlOrder}
              >
                <img src={deleteIcon} alt="delete icon" />
                <p className="font-medium text-red-700">Remove All</p>
              </div>
            </div>
            {/* Table start */}
            <div className="mt-7">
              <Table
                headings={headings}
                data={cartData}
                handleDelete={handleDelete}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleDecreaseQuantity={handleDecreaseQuantity}
              />
            </div>
          </div>

          {/* summary section */}
          <div
            className="col-span-12 mt-10 md:mt-0 h-96 md:h-full md:col-start-8 lg:col-start-9 md:col-end-13 px-5 pb-7 relative"
            style={{ background: "#FAFBFB" }}
          >
            <h5 className="font-bold text-2xl">Summary</h5>
            <div className="mt-7">
              <div className="flex justify-between items-center py-2">
                <p className="text-secondary">Subtotal</p>
                <div className="flex items-center gap-x-1">
                  <span>$</span>
                  <h6 className="font-bold text-xl">{subTotal}</h6>
                </div>
              </div>

              <hr className=" border-2 border-dashed mt-6" />
            </div>

            {/* proceed to checkout section */}
            <div className="w-11/12 absolute bottom-28">
              <div className="flex items-center justify-between">
                <div>
                  <h6 className="font-bold text-xl">Total</h6>
                </div>
                <div className="flex items-center gap-x-1">
                  <span>$</span>
                  <h6 className="text-primary font-bold text-2xl">{total}</h6>
                </div>
              </div>

              {/* button */}
              <Link to="/checkout">
                <button
                  className="bg-primary text-white rounded-2xl w-full py-4 mt-8"
                  disabled={cartData && cartData.length ? false : true}
                >
                  Proceed to Checkout
                </button>
              </Link>

              <Link to="/buy-bids">
                <p className="text-primary text-center font-medium mt-4">
                  Continue Shopping
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
