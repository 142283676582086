import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  countries: [],
  message: "",
  errMsg: "",
  titleText: "",
  subText: "",
  secondTitleText: "",
  secondSubText: "",
  image: "",
};

export const ContactUs = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_COUNTRY:
      return {
        ...state,
        isloading: false,
        success: true,
        countries: action.payload,
      };

    case ActionTypes.GET_CONTACT_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        image: action.payload.image,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        secondTitleText: action.payload.secondTitleText,
        secondSubText: action.payload.secondSubText,
      };

    case ActionTypes.SEND_MESSAGE:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.FAIL_TO_SEND:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_MESSAGE:
      return {
        ...state,
        isloading: "",
        success: "",
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
