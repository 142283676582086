import React, { useRef } from "react";
import Slider from "react-slick";
import arrowRight from "../../Assets/Icons/Arrow_Left_LG.svg";
import arrowLeft from "../../Assets/Icons/Arrow_Right_LG.svg";

const Pagination = ({ setPage, totalData, totalPage, page, navigateTop }) => {
  const bannerSliderRef = useRef(null);
  const pageNumber = [];
  // pushing page number indexing in array
  if (totalData) {
    for (let i = 1; i <= totalPage; i++) {
      pageNumber.push(i);
    }
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const handlePageChangler = (data) => {
    setPage(data);
    navigateTop();
  };

  return (
    <div className="w-52 md:w-80 mx-auto relative">
      <Slider {...settings} ref={bannerSliderRef}>
        {pageNumber.map((data, i) => {
          return (
            <div key={data}>
              <div
                className={`${
                  page === data
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-primary"
                } w-8 md:w-10  cursor-pointer rounded-lg text-center py-1 md:py-2 h-full text-base`}
                onClick={() => handlePageChangler(data)}
              >
                <h1 className="cursor-pointer">{data}</h1>
              </div>
            </div>
          );
        })}
      </Slider>

      <div className="flex justify-between items-center">
        <button
          className="rounded-full text-xs md:text-base -left-20 md:-left-36 flex items-center gap-x-1 md:gap-x-4 py-2 absolute top-0 font-medium text-primary"
          onClick={() => bannerSliderRef.current.slickPrev()}
        >
          <img src={arrowLeft} className="w-4 h-4 md:h-full md:w-full" />
          Previous
        </button>
        <button
          className="rounded-full text-xs md:text-base -right-20 md:-right-24 absolute flex items-center gap-x-4 top-0 py-2 font-medium text-primary"
          onClick={() => bannerSliderRef.current.slickNext()}
        >
          <p>Next</p>
          <img
            src={arrowRight}
            alt="next arrow"
            className="w-4 h-4 md:w-full md:h-full"
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
