import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "../../Component/Loading/Loading";
import Message from "../../Component/Message/Message";
import {
  fetchGetStripeSuccess,
  fetchGetTempStripeSuccess,
} from "../../Redux/Transaction/action";
import ConfirmationModule from "./ConfirmationModule";
import Header from "./Header";
import Processing from "../../Component/Loading/Processing";
import { Helmet } from "react-helmet";

const StripeSuccess = () => {
  const [key, setKey] = useState();
  const [errMsg, setErrMsg] = useState();

  const search = useLocation().search;
  const location = useLocation();

  const dispatch = useDispatch();

  const cartId = localStorage.getItem("cartId");
  const token = Cookies.get("token");

  const loading = useSelector(
    (state) => state.transaction.isloading,
    shallowEqual
  );

  const errMessage = useSelector(
    (state) => state.transaction.errMsg,
    shallowEqual
  );

  useEffect(() => {
    errMessage && setErrMsg(errMessage);
  }, [errMessage]);

  useEffect(() => {
    setKey(new URLSearchParams(search).get("key"));
  }, [location.search]);

  useEffect(() => {
    token && key && dispatch(fetchGetStripeSuccess(key, token));
  }, [key]);

  useEffect(() => {
    !token && key && cartId && dispatch(fetchGetTempStripeSuccess(key, cartId));
  }, [key, cartId]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  if (!key) {
    return (
      <div className="w-full">
        <Processing />
      </div>
    );
  }

  return (
    <div className="w-3/4 mx-auto pt-40 pb-20">
      <Helmet>
        <title>Stripe Success | Bidyl</title>
      </Helmet>
      <Header />
      {errMessage && (
        <Message
          message={errMsg}
          setMessage={setErrMsg}
          className="text-red-500 text-3xl font-bold"
        />
      )}
      <div className="mt-14 w-2/3 mx-auto">
        <ConfirmationModule />
      </div>
    </div>
  );
};

export default StripeSuccess;
