import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Message from "../../../../Component/Message/Message";
import { fetchVerifyUser } from "../../../../Redux/Login/action";

const OTP = ({ setTwoFactorCheck }) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  // retrieve token
  const token = Cookies.get("token");

  const [errMsg, setErrMsg] = useState("");

  const errMessage = useSelector((state) => state.login.errMsg, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const verified = useSelector((state) => state.login.verified, shallowEqual);

  const handleSubmit = () => {
    const data = {
      verificationCode: otp,
    };

    token && dispatch(fetchVerifyUser(data, token));
  };

  useEffect(() => {
    if (verified === true) {
      setTwoFactorCheck(false);
      window.location.reload(false);
    }
  }, [verified]);
  return (
    <div className="absolute top-36 border rounded-2xl z-40 bg-white shadow-2xl w-2/5 pt-5 pb-12 left-1/3">
      <h4 className="font-bold text-2xl text-primary px-5">
        Two Factor Authentication
      </h4>
      <hr className="w-11/12 mx-auto  mt-4" />

      {errMessage && (
        <Message
          className="text-red-500 mt-6"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}

      <div className="px-5 mt-5">
        <p>Enter the two factor code to login two-factor-authentication.</p>

        <div className="mt-5">
          <input
            type="text"
            className="border w-full py-3 rounded-lg px-5 outline-none"
            onChange={(e) => setOtp(e.target.value)}
          />

          <div className="w-11/12 mx-auto flex gap-x-10 items-center mt-8">
            <div
              className="rounded-2xl py-4 w-full text-center text-primary font-medium cursor-pointer"
              style={{ background: "#EBF0FA" }}
              onClick={() => {
                setTwoFactorCheck(false);
                Cookies.remove("token");
                window.location.reload(false);
              }}
            >
              Cancel
            </div>
            <div
              className="rounded-2xl py-4 w-full text-center bg-primary text-white font-medium cursor-pointer"
              onClick={handleSubmit}
            >
              Verify
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
