import React, { useEffect, useState } from "react";
import TitleContainer from "../../Component/Header/TitleContainer";
import Button from "../../Component/Button/Button";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetFaq, fetchGetFaqDetailPage } from "../../Redux/Faq/action";
import Loading from "../../Component/Loading/Loading";
import { Helmet } from "react-helmet";
import { NoImagesFullRounded } from "../../Component/NoData/NoImages";

const Faq = () => {
  const [isOpen, setIsOpen] = useState({});
  const [show, setShow] = useState(false);

  const loading = useSelector((state) => state.faq.isloading, shallowEqual);
  const faq = useSelector((state) => state.faq.faq, shallowEqual);
  const details = useSelector((state) => state.faq.detailPage, shallowEqual);

  const dispatch = useDispatch();

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };

  useEffect(() => {
    dispatch(fetchGetFaq());
    dispatch(fetchGetFaqDetailPage());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>FAQ | Bidyl</title>
      </Helmet>
      <div className="w-10/12 xl:w-1/2 mx-auto pt-28 md:pt-36">
        <TitleContainer title={details.titleText} paragraph={details.subText} />
      </div>

      {/* FAQ component */}
      <div className="w-11/12 xl:w-3/4 mt-10 mx-auto">
        {faq &&
          faq.map((data) => {
            return (
              <div
                key={data.id}
                className=" cursor-pointer"
                onClick={() => {
                  handleOpen(data.id);
                }}
              >
                <div className="flex items-center gap-x-7 py-4">
                  <div
                    className={`arrow transition ease-in-out duration-500 text-5xl xl:text-6xl delay-100 ${
                      !isOpen[data.id] ? "close" : " open"
                    }`}
                  >
                    +
                  </div>

                  <h6 className="font-bold text-sm md:text-lg xl:text-xl">
                    {data.question}
                  </h6>
                </div>
                <h5
                  className={
                    show === data.id
                      ? "w-[68%] xl:w-[88%] text-secondary text-sm md:text-base pb-8 mx-auto ease-in-out transition-all duration-1000 text-justify"
                      : "hidden"
                  }
                >
                  {data.answer}
                </h5>

                <hr />
              </div>
            );
          })}
      </div>

      {/* get in touch section */}
      <div className="w-full mt-16 py-10" style={{ background: "#F7F9FC" }}>
        {/* avatar section */}
        <div className="w-3/12 md:w-2/12 xl:w-1/12 mx-auto relative flex justify-center items-center">
          <div className="w-16 h-16 -mt-1.5 rounded-full overflow-hidden z-30">
            {details.avatars?.[0]?.image ? (
              <img
                src={details.avatars?.[0]?.image}
                alt="avatar"
                className="w-full h-full rounded-full object-fill"
              />
            ) : (
              <NoImagesFullRounded />
            )}
          </div>

          <div className="w-14 h-14 absolute top-0 -left-4 rounded-full">
            {details.avatars?.[1]?.image ? (
              <img
                src={details.avatars?.[1]?.image}
                alt="avatar"
                className="w-full h-full rounded-full object-fill"
              />
            ) : (
              <NoImagesFullRounded />
            )}
          </div>

          <div className="w-14 h-14 absolute top-0 -right-0 xl:-right-4 rounded-full">
            {details.avatars?.[2]?.image ? (
              <img
                src={details.avatars?.[2]?.image}
                alt="avatar"
                className="w-full h-full rounded-full object-fill"
              />
            ) : (
              <NoImagesFullRounded />
            )}
          </div>
        </div>

        <h4 className="font-bold text-xl md:text-3xl text-center mt-8">
          {details.footerTitleText}
        </h4>
        <h6 className="text-sm md:text-xl mt-3 text-center text-secondary">
          {details.footerSubText}
        </h6>
        <Link to="/contact-us">
          <Button
            className="bg-primary rounded-2xl text-white px-8 w-80 text-center flex justify-center items-center mt-14 mx-auto py-3"
            value="Get in Touch"
          />
        </Link>
      </div>
    </>
  );
};

export default Faq;
