import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  myWish: [],
  totalPage: "",
  totalData: "",
  pageNumber: "",
  message: "",
};

export const Wishlist = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TO_WISHLIST:
      return {
        isloading: false,
        message: action.payload.message,
        success: action.payload.isSuccess,
        myWish: action.payload.data,
      };

    case ActionTypes.FAIL_TO_ADD:
      return {
        isloading: false,
        message: action.payload.message,
        success: false,
      };

    case ActionTypes.REMOVE_FROM_WISHLIST:
      return {
        isloading: false,
        message: action.payload.message,
        success: true,
        myWish: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_OWN_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        myWish: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_OWN_WISHLIST_BY_CATEGORY:
      return {
        ...state,
        isloading: false,
        success: true,
        myWish: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.SEARCH_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        myWish: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.REMOVE_DATA:
      return {
        message: "",
        success: "",
        myWish: [],
        totalData: "",
        totalPage: "",
        pageNumber: "",
      };
    default:
      return state;
  }
};
