import React, { useState } from "react";

const Questions = ({ questions }) => {
  const [isOpen, setIsOpen] = useState({});
  const [show, setShow] = useState(false);

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };
  return (
    <div className="w-full px-5">
      {questions &&
        questions.map((data) => {
          return (
            <div
              key={data.id}
              className=" cursor-pointer"
              onClick={() => {
                handleOpen(data.id);
              }}
            >
              <div className="flex items-center gap-x-7 py-4">
                <div
                  className={`arrow transition ease-in-out duration-500 text-5xl xl:text-6xl delay-100 ${
                    !isOpen[data.id] ? "close" : " open"
                  }`}
                >
                  +
                </div>

                <h6 className="font-bold text-sm md:text-lg xl:text-xl">
                  {data.question}
                </h6>
              </div>
              <h5
                className={
                  show === data.id
                    ? "w-[68%] xl:w-[88%] text-secondary text-sm md:text-base pb-8 mx-auto ease-in-out transition-all duration-1000"
                    : "hidden"
                }
              >
                {data.answer}
              </h5>

              <hr />
            </div>
          );
        })}
    </div>
  );
};

export default Questions;
