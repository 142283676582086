import React from "react";
import filterIcon from "../../Assets/Icons/setting-4.svg";
import gridActive from "../../Assets/Icons/element-3.svg";
import gridInactive from "../../Assets/Icons/light grid.svg";
import listInactive from "../../Assets/Icons/light list.svg";
import listActive from "../../Assets/Icons/firstline.svg";

const Header = ({
  totalData,
  toggle,
  setToggle,
  showFilter,
  setShowFilter,
  setSortParameter,
  setSort,
}) => {
  const showFilterHandler = () => {
    setShowFilter(!showFilter);
  };

  // handle sort select box
  const handleSelectSort = (e) => {
    if (e.target.value === "All") {
      setSortParameter("id");
      setSort("descending");
    } else if (e.target.value === "A-Z") {
      setSortParameter("title");
      setSort("ascending");
    } else if (e.target.value === "Z-A") {
      setSortParameter("title");
      setSort("descending");
    } else if (e.target.value === "Latest") {
      setSortParameter("addedOn");
      setSort("descending");
    } else if (e.target.value === "Oldest") {
      setSortParameter("addedOn");
      setSort("ascending");
    } else {
      setSortParameter("id");
      setSort("descending");
    }
  };

  return (
    <div className="py-4 flex justify-between items-center flex-wrap lg:flex-nowrap">
      <div className="flex items-center gap-x-10 w-full">
        <div
          className="border py-3 flex items-center gap-x-2 px-3 w-40 lg:w-32 rounded-lg cursor-pointer"
          onClick={showFilterHandler}
        >
          <img src={filterIcon} alt="filter icon" />
          <p className="font-medium">Filter</p>
          <div className="border bg-primary rounded-full px-2 py-0.5">
            <p className="text-white text-sm">0</p>
          </div>
        </div>
        <h5 className="font-bold text-base sm:text-xl xl:text-2xl">
          {totalData} RESULTS FOUND
        </h5>
      </div>

      <div className="flex items-center w-full mt-5 lg:mt-0">
        <div className="w-64 py-2 items-center flex gap-x-3">
          <p className="text-secondary">Sort By:</p>
          <select
            className="w-1/3 outline-none rounded-lg px-1 border py-2"
            onChange={handleSelectSort}
          >
            <option value="id">All</option>
            <option>A-Z</option>
            <option>Z-A</option>
            <option>Latest</option>
            <option>Oldest</option>
          </select>
        </div>
        <div className="flex items-center gap-x-5">
          <div className={` p-3 rounded-lg ${!toggle && "bg-primary"}`}>
            <img
              src={`${toggle ? gridInactive : gridActive}`}
              alt="grid"
              className="cursor-pointer"
              onClick={() => setToggle(false)}
            />
          </div>

          <div className={`p-3 rounded-lg ${toggle && "bg-primary"}`}>
            <img
              src={`${toggle ? listActive : listInactive}`}
              alt="list"
              className="cursor-pointer"
              onClick={() => setToggle(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
