import React from "react";
import searchIcon from "../../Assets/Icons/search.svg";

const Header = ({ totalData, categories, setSearchValue }) => {
  return (
    <div className="w-full h-20 flex justify-between items-center flex-wrap">
      <div>
        <h3 className="font-bold text-2xl lg:text-4xl">My Wishlist</h3>
        <div className="flex items-center gap-x-2 mt-2">
          <p className="text-secondary">You have</p>
          <h6 className="font-bold text-primary text-xl">
            {totalData.length} Items
          </h6>
          <p className="text-secondary">in your wishlist</p>
        </div>
      </div>

      <div className="flex gap-x-6 items-center flex-wrap mt-5 lg:mt-0">
        <div className="w-full md:w-64 border rounded-xl py-2 px-2 gap-x-2 flex items-center">
          <img src={searchIcon} alt="search icon" className="py-1 w-8 h-8" />
          <input
            type="text"
            placeholder="Search Item"
            className="py-1 w-full outline-none px-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div className="w-full md:w-64 py-2 mt-5 md:mt-0 items-center flex justify-between">
          <p className="text-secondary">Sort By:</p>

          <select className="w-9/12 outline-none rounded-lg px-1 border py-2">
            <option>All</option>
            {categories.map((data) => {
              return <option value={data.id}>{data.categoryName}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
