import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormikControl from "../../Component/Formik/FormikControl";
import * as Yup from "yup";
import Button from "../../Component/Button/Button";
import TitleContainer from "../../Component/Header/TitleContainer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAllCountry,
  fetchGetAllData,
  fetchPostMessage,
  removeMessage,
} from "../../Redux/ContactUs/action";
import Loading from "../../Component/Loading/Loading";
import arrowDown from "../../Assets/Icons/arrow-down.svg";
import Message from "../../Component/Message/Message";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [showCountry, setShowCountry] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState();

  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  // retrieve successMessage from redux
  const successMessage = useSelector(
    (state) => state.contactus.message,
    shallowEqual
  );

  const titleText = useSelector(
    (state) => state.contactus.titleText,
    shallowEqual
  );
  const subText = useSelector((state) => state.contactus.subText, shallowEqual);
  const secondTitleText = useSelector(
    (state) => state.contactus.secondTitleText,
    shallowEqual
  );
  const secondSubText = useSelector(
    (state) => state.contactus.secondSubText,
    shallowEqual
  );
  const image = useSelector((state) => state.contactus.image, shallowEqual);

  useEffect(() => {
    setSuccMsg(successMessage);
  }, [successMessage]);

  useEffect(() => {
    if (succMsg) {
      setCountry("");
      setCountryCode("");
    }
  });
  // retrieve errorMessgae from redux
  const errMessage = useSelector(
    (state) => state.contactus.errMsg,
    shallowEqual
  );

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const loading = useSelector(
    (state) => state.contactus.isloading,
    shallowEqual
  );

  const countries = useSelector(
    (state) => state.contactus.countries,
    shallowEqual
  );
  const dispatch = useDispatch();

  // store the country in state
  const onClickDropdownOption = (country) => {
    setCountry(country);
    const data = countries.find((item) => item.name === country);
    setCountryCode(data.calling_code);
    setShowCountry(false);
  };

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Required *"),
    email: Yup.string().email("Invalid email").required("Required *"),
  });

  const onSubmit = (values, onSubmitProps) => {
    console.log("hit", values);

    const data = {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      message: values.message,
      country: country,
      callingCode: countryCode,
    };

    console.log("formik login", {
      data,
    });

    dispatch(fetchPostMessage(data));
    setTimeout(() => {
      onSubmitProps.resetForm({ values: "" });
    }, [1000]);

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeMessage());
    }, 10000);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchGetAllData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGetAllCountry(country));
  }, [dispatch, country]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pb-20 pt-24 md:pt-36">
      <Helmet>
        <title>Contact us | Bidyl</title>
      </Helmet>
      {succMsg && (
        <Message
          className="text-green-500 text-3xl font-bold border w-fit px-7 py-3 rounded-lg border-green-500"
          message={succMsg}
          setMessage={setSuccMsg}
        />
      )}

      {errMsg && (
        <Message
          className="text-red-500 text-3xl font-bold"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}
      <div className="w-11/12 xl:w-3/4 container mx-auto pt-4">
        <div className="w-full md:w-2/3 mx-auto flex justify-center items-center">
          <TitleContainer title={titleText} paragraph={subText} />
        </div>

        <div className="mt-16 grid grid-cols-12 md:gap-x-16">
          <div className="col-span-12 md:col-span-5">
            <h3 className="font-bold text-xl md:text-3xl">{secondTitleText}</h3>
            <p className="text-secondary text-sm md:text-base mt-4">
              {secondSubText}
            </p>

            <img src={image} alt="Contact-us" className="mt-5" />
          </div>
          <div className="col-span-12 md:col-start-6 md:col-end-13 mt-10 md:mt-3">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-x-5 items-center">
                    <div className="w-full">
                      <FormikControl
                        label="Your Name"
                        placeholder="Name"
                        control="input"
                        name="fullName"
                      />
                    </div>

                    <div className="w-full">
                      <FormikControl
                        label="Email Address"
                        placeholder="Email"
                        control="input"
                        name="email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="flex gap-x-5 items-center mt-7">
                    <div className="w-full">
                      <label className="pb-2 font-medium flex items-center justify-between">
                        Country
                      </label>
                      <div
                        className="border border-gray-300 w-full cursor-pointer p-1.5 text-base rounded-lg outline-gray-300 flex justify-between"
                        onClick={() => setShowCountry(!showCountry)}
                      >
                        <input
                          type="text"
                          className="w-full py-1 outline-none px-3 cursor-pointer"
                          placeholder="Search Countries"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <img src={arrowDown} alt="arrow" />
                      </div>

                      {showCountry ? (
                        <div
                          className={`absolute border w-1/2 md:w-1/4 lg:w-1/5 mt-1 rounded-md border-gray-400 mb-5 bg-white shadow-md z-50 overflow-auto ${
                            countries.length < 5 ? "h-fit" : "h-56"
                          }`}
                        >
                          {/* <div className="border py-2 w-full bg-white mb-1">
                            <input
                              type="text"
                              className="outline-none py-0.5 px-2"
                            />
                          </div> */}
                          {countries?.map((item) => {
                            return (
                              <div
                                className=" cursor-pointer p-3 hover:bg-gray-200 text-sm"
                                key={item.code}
                                onClick={(e) => {
                                  return (
                                    e.preventDefault(),
                                    onClickDropdownOption(item.name)
                                  );
                                }}
                              >
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full">
                      <FormikControl
                        label="Phone Number"
                        placeholder="your phone..."
                        control="phone"
                        name="phoneNumber"
                        callingCode={countryCode}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-7">
                    <FormikControl
                      label="Message (optional)"
                      placeholder="Your message..."
                      control="textarea"
                      name="message"
                    />
                  </div>

                  <div className="w-fit">
                    <Button
                      type="submit"
                      className="bg-primary py-3 px-10 mt-5 rounded-xl text-white w-full"
                      value="Get in Touch"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {showCountry && (
        <div
          className="w-full h-full z-40 absolute top-0 bg-opacity-10"
          onClick={() => setShowCountry(false)}
        ></div>
      )}
    </div>
  );
};

export default Contact;
