import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  // retrieve cookie
  const token = Cookies.get("token");

  if (!token) {
    return (
      <Navigate
        to={`/sign-in?authenticate=false&path=${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};
export default ProtectedRoute;
