import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const homeLoading = () => ({
  type: ActionTypes.HOME_LOADING,
});

export const removeHomeData = () => ({
  type: ActionTypes.REMOVE_DATA,
});

export const fetchGetHomeData = () => (dispatch) => {
  dispatch(homeLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/home-page/get-all-data`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_DATA,
        payload: data,
      });
    });
};
