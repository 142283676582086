import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  bidPackages: [],
  productWinners: [],
  products: [],
  carouselImages: [],
  titleText: "",
  subText: "",
  secondTitleText: "",
  secondSubText: "",
  winnerTitleText: "",
  winnerSubText: "",
  bidTitleText: "",
  bidSubText: "",
};

export const Winner = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DATA:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        bidPackages: action.payload?.bidPackages?.data,
        productWinners: action.payload?.productWinners,
        products: action.payload?.becomeAWinner,
        titleText: action.payload?.titleText,
        subText: action.payload?.subText,
        secondTitleText: action.payload?.secondTitleText,
        secondSubText: action.payload?.secondSubText,
        winnerTitleText: action.payload?.winnerTitleText,
        winnerSubText: action.payload?.winnerSubText,
        bidTitleText: action.payload?.bidTitleText,
        bidSubText: action.payload?.bidSubText,
        carouselImages: action.payload?.carouselImages,
      };

    case ActionTypes.GET_DATA_USER:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        bidPackages: action.payload?.bidPackages?.data,
        productWinners: action.payload?.productWinners,
        products: action.payload?.becomeAWinner,
        titleText: action.payload?.titleText,
        subText: action.payload?.subText,
        secondTitleText: action.payload?.secondTitleText,
        secondSubText: action.payload?.secondSubText,
        winnerTitleText: action.payload?.winnerTitleText,
        winnerSubText: action.payload?.winnerSubText,
        bidTitleText: action.payload?.bidTitleText,
        bidSubText: action.payload?.bidSubText,
        carouselImages: action.payload?.carouselImages,
      };

    case ActionTypes.REMOVE_DATA:
      return {
        bidPackages: "",
        productWinners: "",
        products: "",
        carouselImages: [],
      };
    default:
      return state;
  }
};
