import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import NoImages from "../NoData/NoImages";

const Partners = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerSliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 935,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },

      {
        breakpoint: 659,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 467,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        },
      },

      {
        breakpoint: 310,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customBid">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <div className="w-full">
      <Slider {...settings} ref={bannerSliderRef}>
        {data &&
          data.map((item) => {
            return (
              <div className="px-2 mt-10 cursor-pointer" key={item.id}>
                <a href={item.link}>
                  <div className="p-3 border rounded-2xl border-border-color z-50">
                    <div className="w-full h-32">
                      {item.image ? (
                        <img
                          src={item.image}
                          alt=""
                          className="w-full h-full object-contain"
                        />
                      ) : (
                        <NoImages />
                      )}
                    </div>

                    <h4 className="font-bold text-base mt-3 text-secondary">
                      {item.name}
                    </h4>
                  </div>
                </a>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Partners;

export const PartnersCard = ({ data }) => {
  return (
    <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
      {data &&
        data.map((item) => {
          return (
            <div className="px-2 mt-10 cursor-pointer" key={item.id}>
              <a href={item.link}>
                <div className="p-3 border rounded-2xl border-border-color z-50">
                  <div className="w-full h-32">
                    {item.image ? (
                      <img
                        src={item.image}
                        alt=""
                        className="w-full h-full object-contain"
                      />
                    ) : (
                      <NoImages />
                    )}
                  </div>

                  <h4 className="font-bold text-base mt-3 text-secondary">
                    {item.name}
                  </h4>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};
