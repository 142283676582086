import React, { useEffect } from "react";

const Message = ({ className, message, setMessage }) => {
  useEffect(() => {
    setTimeout(() => {
      setMessage(false);
    }, 10000);
  }, []);
  return (
    <div className="flex justify-center mx-5 mt-2">
      <div className={className}>
        <h6 className="text-base">{message}</h6>
      </div>
    </div>
  );
};

export default Message;
