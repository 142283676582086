import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ContainerCard from "../../Component/Card/ContainerCard";
import Loading from "../../Component/Loading/Loading";
import { fetchGetHomeData } from "../../Redux/Home/action";
import { Helmet } from "react-helmet";

const Categories = () => {
  const loading = useSelector((state) => state.home.isloading, shallowEqual);
  const products = useSelector((state) => state.home.products, shallowEqual);
  // category products
  const productHouses = products?.filter(
    (dataObj) => dataObj.category.id === 1
  );
  const productVehicles = products?.filter(
    (dataObj) => dataObj.category.id === 2
  );
  const productWatches = products?.filter(
    (dataObj) => dataObj.category.id === 3
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetHomeData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-11/12 xl:w-3/4 mx-auto pt-36 mb-20">
      <Helmet>
        <title>Categories | Bidyl</title>
      </Helmet>
      <h5 className="text-center text-3xl xl:text-5xl font-bold xl:mb-20">
        Categories
      </h5>
      <div className="mt-10">
        <ContainerCard
          data={productHouses}
          className="flex gap-x-5 xl:gap-x-10 h-80 xl:h-96 mt-10 px-5"
        />

        <ContainerCard
          data={productVehicles}
          className="flex gap-x-5 flex-row-reverse xl:gap-x-10 h-80 xl:h-96 mt-10 px-5"
        />

        <ContainerCard
          data={productWatches}
          className="flex gap-x-5 xl:gap-x-10 h-80 xl:h-96 mt-10 px-5"
        />
      </div>
    </div>
  );
};

export default Categories;
