import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  totalPage: "",
  totalData: "",
  pageNumber: "",
  message: "",
  errMessage: "",
  myBids: [],
};

export const MyBid = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MY_BIDS_ON_PRODUCT:
      return {
        ...state,
        isloading: false,
        success: true,
        myBids: action.payload?.data,
        totalData: action.payload?.totalData,
        totalPage: action.payload?.totalPage,
      };

    case ActionTypes.REMOVE_MY_BID_DATA:
      return {
        isloading: true,
        success: false,
        totalData: "",
        totalPage: "",
        pageNumber: "",
        message: "",
        errMessage: "",
      };

    default:
      return state;
  }
};
