import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: false,
  success: "",
  qr: "",
};

export const Qr = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_QR:
      return {
        ...state,
        isloading: false,
        success: true,
        qr: action.payload.qrCodeImage,
      };

    default:
      return state;
  }
};
