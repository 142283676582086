import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../../Assets/Icons/Close_MD.svg";
import informationIcon from "../../../../Assets/Icons/information.svg";
import Button from "../../../../Component/Button/Button";
import { fetchMyBidsOnProduct } from "../../../../Redux/MyBids/action";
import {
  fetchGetProductByIdUser,
  fetchPlaceAutoBid,
} from "../../../../Redux/Product/action";
import { fetchGetMyWallet } from "../../../../Redux/Profile/action";

const AutoBidPreviewPopup = ({
  setAutoBidPreview,
  setAutoBidPopup,
  minimunBidValue,
  numOfBid,
  bidIncrement,
  productId,
  setShowAutoBiddingPasswordPopup,
}) => {
  const dispatch = useDispatch();

  console.log("number od bid", numOfBid);
  console.log("bid increment", bidIncrement);
  console.log("minimum bid value", minimunBidValue);

  const hasBiddingPassword = useSelector(
    (state) => state.product.product.hasBiddingPassword,
    shallowEqual
  );

  let test = Number(minimunBidValue);

  const dataArray = Array.from({ length: numOfBid }, () =>
    Number(bidIncrement)
  );

  const test2 = dataArray.map((val, i) => {
    return i === 0 ? (test = test) : (test = test + val).toFixed(2);
  });

  const handleChangeButton = () => {
    setAutoBidPreview(false);
    setAutoBidPopup(true);
    window.scrollTo(0, 0);
  };

  // retrieve token
  const token = Cookies.get("token");

  // retrieve bidding password
  const biddingPassword = Cookies.get("biddingPassword");

  // place auto bid button
  const handlePlaceAutoBid = () => {
    const abc = [];
    test2.map((val) => abc.push({ product: productId, bidAmount: val }));

    const body = {
      noOfBids: numOfBid,
      password: biddingPassword,
      bids: abc,
    };

    if (hasBiddingPassword === true && !biddingPassword) {
      setShowAutoBiddingPasswordPopup(true);
    } else {
      token && dispatch(fetchPlaceAutoBid(body, token));
      token && dispatch(fetchGetMyWallet(token));
      token && dispatch(fetchGetProductByIdUser(productId, token));
      token && dispatch(fetchMyBidsOnProduct(productId, 1, token));
    }
    window.scrollTo(0, 0);

    token && dispatch(fetchGetMyWallet(token));
    token && dispatch(fetchMyBidsOnProduct(productId, 1, token));
    setAutoBidPreview(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute w-1/2  pb-10 pt-5 mx-auto shadow-lg left-1/4 border top-36 rounded-lg bg-white z-30">
      <div className="flex justify-between items-center px-10">
        <h4 className="text-primary font-bold text-3xl">Auto Bid</h4>
        <img
          src={closeIcon}
          alt="close Icon"
          className="w-10 h-10 cursor-pointer"
          onClick={() => setAutoBidPreview(false)}
        />
      </div>
      <p className="text-secondary px-10 py-1">
        Please fill out the form below to initiate auto bid
      </p>

      <hr className="mt-5" />

      <div className="flex items-center gap-x-1 mt-10 px-10">
        <p className="font-bold text-xl">Preview</p>
        <img src={informationIcon} alt="information Icon" className="w-7 h-7" />
      </div>

      <div className="flex items-center gap-x-7 mt-10 px-10">
        {test2.slice(0, 4).map((val) => {
          return (
            <div className="flex items-center gap-x-1 py-4 px-6 w-fit border rounded-xl">
              <p className="text-sm font-medium">$</p>
              <p className="font-bold text-primary">{val}</p>
            </div>
          );
        })}

        {test2.length > 4 && (
          <>
            <p className="font-bold text-primary">.......................</p>
            <div className="flex items-center gap-x-1 py-4 px-6 w-fit border rounded-xl">
              <p className="text-sm font-medium">$</p>
              <p className="font-bold text-primary">
                {test2[test2.length - 1]}
              </p>
            </div>
          </>
        )}
      </div>

      <hr className="mx-10 mt-10" />

      {/* Buttons */}
      <div className="flex justify-between mt-10 gap-x-20 px-10">
        <div
          className="border rounded-2xl text-primary py-4 text-center w-full cursor-pointer"
          style={{ background: "#EBF0FA" }}
          onClick={handleChangeButton}
        >
          <Button value="Change" />
        </div>
        <div
          className="border bg-primary text-white py-4 text-center w-full rounded-2xl cursor-pointer"
          onClick={handlePlaceAutoBid}
        >
          <Button value="Place Auto Bid" />
        </div>
      </div>
    </div>
  );
};

export default AutoBidPreviewPopup;
