import React, { useEffect, useState } from "react";
import Button from "../../../Component/Button/Button";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikControl from "../../../Component/Formik/FormikControl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Message from "../../../Component/Message/Message";
import Cookies from "js-cookie";
import { fetchEnableTwoFactor } from "../../../Redux/TwoFactor/action";

const TwoFactorModalPopup = ({ setTwoFactorModal, setOtpPopupModal }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const errMessage = useSelector(
    (state) => state.twoFactor.errMsg,
    shallowEqual
  );

  const otpSent = useSelector((state) => state.twoFactor.sent, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  // set formik initial values
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required *"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchEnableTwoFactor(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 2000);
  };

  useEffect(() => {
    if (otpSent === true) {
      setOtpPopupModal(true);
      setTwoFactorModal(false);
    }
  }, [otpSent]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 left-4 md:left-[17%] lg:left-1/4 pt-10 pb-16">
      <div className="w-11/12 md:w-3/4 mx-auto">
        <h4 className="font-bold text-xl xl:text-3xl text-primary text-center">
          Two Factor Authentication
        </h4>
        <p className="text-center text-secondary py-1">
          To continue, please enter your email and password.
        </p>
        {errMessage && (
          <Message
            className="text-red-500 mt-6"
            message={errMsg}
            setMessage={setErrMsg}
          />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="mt-5">
                <FormikControl
                  label="Password"
                  placeholder="Enter your password"
                  control="password"
                  name="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>
              <div className="mt-5">
                <FormikControl
                  label="Confirm password"
                  placeholder="Confirm Your Password"
                  control="password"
                  name="confirmPassword"
                  type={`${showConfirmPassword ? "text" : "password"}`}
                  setShowPassword={setShowConfirmPassword}
                  showPassword={showConfirmPassword}
                />
              </div>

              <div className="flex justify-between mt-6 gap-x-20">
                <div
                  className="w-full rounded-2xl text-center cursor-pointer"
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setTwoFactorModal(false)}
                >
                  <Button
                    value="Cancel"
                    className=" py-4 px-7 text-primary font-medium "
                  />
                </div>

                <Button
                  value="Continue"
                  type="submit"
                  className=" py-4 px-7 w-full rounded-2xl text-center bg-primary cursor-pointer text-white "
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TwoFactorModalPopup;
