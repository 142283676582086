import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BidCard, { GridBidCard } from "../../Component/Card/BidCard";
import ProductCardGrid from "../../Component/Card/ProductCardGrid";
import VideoCarousel from "../../Component/Carousell/VideoCarousel";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetWinnerData,
  fetchGetWinnerDataUser,
} from "../../Redux/Winner/action";
import WinnersCard from "./WinnersCard";
import wish from "../../Assets/Icons/wish.svg";
import heartIcon from "../../Assets/Icons/Heart Icon.svg";
import { useNavigate } from "react-router-dom";
import {
  fetchAddToWishlist,
  fetchGetOwnWishlist,
  fetchRemoveFromWishlist,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const Winners = () => {
  const navigate = useNavigate();

  // retrieve cookie
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.winner.isloading, shallowEqual);

  const secondTitleText = useSelector(
    (state) => state.winner.secondTitleText,
    shallowEqual
  );
  const secondSubText = useSelector(
    (state) => state.winner.secondSubText,
    shallowEqual
  );
  const winnerTitleText = useSelector(
    (state) => state.winner.winnerTitleText,
    shallowEqual
  );
  const winnerSubText = useSelector(
    (state) => state.winner.winnerSubText,
    shallowEqual
  );
  const bidTitleText = useSelector(
    (state) => state.winner.bidTitleText,
    shallowEqual
  );
  const bidSubText = useSelector(
    (state) => state.winner.bidSubText,
    shallowEqual
  );

  const productWinners = useSelector(
    (state) => state.winner.productWinners,
    shallowEqual
  );

  const products = useSelector((state) => state.winner.products, shallowEqual);

  const bidPackages = useSelector(
    (state) => state.winner.bidPackages,
    shallowEqual
  );

  const carouselImages = useSelector(
    (state) => state.winner.carouselImages,
    shallowEqual
  );
  const dispatch = useDispatch();

  // category products
  const productHouses = productWinners?.filter(
    (dataObj) => dataObj.category.id === 1
  );
  const productVehicles = productWinners?.filter(
    (dataObj) => dataObj.category.id === 2
  );
  const productWatches = productWinners?.filter(
    (dataObj) => dataObj.category.id === 3
  );

  // hanlde add or remove item from cart
  const handleWishlist = (id, isInWishList) => {
    !token && navigate("/sign-in");

    if (isInWishList === true) {
      token && dispatch(fetchRemoveFromWishlist(id, token));
      token && dispatch(fetchGetWinnerDataUser(token));
      token && dispatch(fetchGetOwnWishlist(1, token));
    } else {
      token && dispatch(fetchAddToWishlist(id, token));
      token && dispatch(fetchGetWinnerDataUser(token));
      token && dispatch(fetchGetOwnWishlist(1, token));
    }
    token && dispatch(fetchGetWinnerDataUser(token));
    token && dispatch(fetchGetOwnWishlist(1, token));
    token && dispatch(fetchGetOwnWishlist(1, token));
  };

  useEffect(() => {
    token
      ? dispatch(fetchGetWinnerDataUser(token))
      : dispatch(fetchGetWinnerData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="pt-12 pb-20">
      <Helmet>
        <title>Winners | Bidyl</title>
      </Helmet>
      <VideoCarousel video={carouselImages} />
      {productWinners && productWinners.length ? (
        <div className="mt-10 md:mt-16 text-center w-11/12 xl:w-1/2 mx-auto">
          <TitleContainer title={secondTitleText} paragraph={secondSubText} />
        </div>
      ) : null}
      {/* winners */}

      {productHouses && productHouses.length ? (
        <div className="mt-16 text-center">
          <h4
            className="py-5 font-bold text-lg xl:text-3xl"
            style={{ background: "#F7F9FC" }}
          >
            Houses
          </h4>
          <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
            <WinnersCard data={productHouses} />
          </div>
        </div>
      ) : null}

      {productVehicles && productVehicles.length ? (
        <div className="mt-16 text-center">
          <h4
            className="py-5 font-bold text-lg xl:text-3xl"
            style={{ background: "#F7F9FC" }}
          >
            Vehicles
          </h4>
          <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
            <WinnersCard data={productVehicles} />
          </div>
        </div>
      ) : null}

      {productWatches && productWatches.length ? (
        <div className="mt-16 text-center">
          <h4
            className="py-5 font-bold text-lg xl:text-3xl"
            style={{ background: "#F7F9FC" }}
          >
            Watches & Jewelry
          </h4>
          <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
            <WinnersCard data={productWatches} />
          </div>
        </div>
      ) : null}

      {/* become a winner section */}
      {products && products.length ? (
        <div className="w-11/12 xl:w-3/4 mx-auto mt-14 md:mt-24">
          <div className="w-11/12 xl:w-2/3 mx-auto text-center">
            <TitleContainer title={winnerTitleText} paragraph={winnerSubText} />
          </div>
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-10">
            {products &&
              products.slice(0, 3).map((data) => {
                return (
                  <div key={data.id} className="mt-10">
                    <ProductCardGrid
                      data={data}
                      icon={`${data.isInWishList === true ? heartIcon : wish}`}
                      handleWishlist={handleWishlist}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
      {/* buy bid section */}
      <div className="mx-auto flex items-center justify-center flex-col text-center mt-16 md:mt-20 w-11/12 xl:w-1/2">
        <TitleContainer title={bidTitleText} paragraph={bidSubText} />
      </div>
      {/* bid packages */}
      <div className="w-11/12 xl:w-9/12 mx-auto">
        {bidPackages && bidPackages.length > 4 ? (
          <BidCard data={bidPackages} />
        ) : (
          <GridBidCard data={bidPackages} />
        )}
      </div>
    </div>
  );
};

export default Winners;
