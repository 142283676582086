import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import Header from "./Header";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchVerifyEmail, removeLogin } from "../../../Redux/Login/action";
import Message from "../../../Component/Message/Message";
import { Helmet } from "react-helmet";

const ForgetPassword = () => {
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSuccess = useSelector((state) => state.login.success, shallowEqual);
  const errMessage = useSelector((state) => state.login.errMsg, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  const initialValues = {
    userName: "",
  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().email("Invalid username").required("Required *"),
  });

  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchVerifyEmail(values));
    setTimeout(() => {
      dispatch(removeLogin());
      onSubmitProps.setSubmitting(false);
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isSuccess && navigate("/verify-email");
  }, [isSuccess]);

  return (
    <div className="w-11/12 md:w-9/12 xl:w-1/2 container mx-auto pt-36 mb-20">
      <Helmet>
        <title>Forgot Password | Bidyl</title>
      </Helmet>
      <Header
        text="Forget your"
        message="Password"
        info="Don’t worry, happens to best of us."
        info2="Just enter the registered email address to request for password reset."
      />

      <div className="text-center w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-10">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full relative">
                <FormikControl
                  label="Email Address"
                  placeholder="Enter your email"
                  control="input"
                  name="userName"
                  type="email"
                />
                {errMsg && (
                  <Message
                    className=" text-red-500 absolute top-0"
                    message={errMsg}
                    setMessage={setErrMsg}
                  />
                )}
              </div>

              <div className="mt-7">
                <Button
                  type="submit"
                  className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                  value="Request for Password Reset"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPassword;
