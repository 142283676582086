import React, { useEffect, useState } from "react";
import Loading from "../../../Component/Loading/Loading";
import { convertDate, convertTime } from "../../../Utls/Moment";
import noBids from "../../../Assets/Images/empty.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Pagination from "../../../Component/Pagination/Pagination";
import {
  fetchMyBidsOnProduct,
  removeMyBidData,
} from "../../../Redux/MyBids/action";
import SinglePagnination from "../../../Component/Pagination/SinglePagnination";

const headings = [
  {
    id: 1,
    title: "Bid Number",
  },

  {
    id: 2,
    title: "Date",
  },

  {
    id: 3,
    title: "Time",
  },

  {
    id: 4,
    title: "Bid Amount",
  },
];

const MyBid = ({ productId }) => {
  const [page, setPage] = useState(1);
  const loading = useSelector((state) => state.mybid.isloading, shallowEqual);
  const myBids = useSelector((state) => state.mybid.myBids, shallowEqual);
  const totalData = useSelector((state) => state.mybid.totalData, shallowEqual);
  const totalPage = useSelector((state) => state.mybid.totalPage, shallowEqual);

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  useEffect(() => {
    token && dispatch(fetchMyBidsOnProduct(productId, page, token));

    return () => {
      dispatch(removeMyBidData());
    };
  }, [productId, page]);

  if (!productId || loading) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  return (
    <div className="align-middle min-w-full px-5">
      <div className="relative">
        <table className="table-auto w-full">
          <thead className="w-full">
            <tr>
              {headings.map((title) => {
                return (
                  <th
                    key={title.id}
                    scope="col"
                    className="px-2 md:px-6 py-3 text-xs md:text-base text-left font-bold  text-secondary uppercase"
                  >
                    {title.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          {totalData === "" ? (
            <div className="absolute left-1/3 top-20">
              <Loading />
            </div>
          ) : myBids && myBids.length ? (
            <tbody className="bg-white">
              {myBids.map((data, i) => {
                return (
                  <tr key={i}>
                    <td className="px-2 md:px-6 text-sm md:text-base py-4 whitespace-nowrap text-left">
                      {data.bidNumber}
                    </td>

                    <td className="px-2 md:px-6 text-sm md:text-base py-4 whitespace-nowrap">
                      {convertDate(data.bidAddedTime)}
                    </td>

                    <td className="px-2 md:px-6 text-sm md:text-base py-4 whitespace-nowrap">
                      {convertTime(data.bidAddedTime)}
                    </td>

                    <td className="px-2 md:px-6 text-sm md:text-base py-4 whitespace-nowrap">
                      {data.bidAmount}
                    </td>
                  </tr>
                );
              })}

              {/* pagination */}
              <div className="mt-10 w-full absolute justify-center">
                {totalPage > 5 ? (
                  <Pagination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </tbody>
          ) : (
            <div className="text-center w-40 h-80 md:w-80 md:h-80 absolute left-1/3 mx-auto">
              <img src={noBids} alt="no bid image" className="w-full h-full" />
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default MyBid;
