import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import tick from "../../../Assets/Icons/tick-circle.png";
import bidyl from "../../../Assets/Logo/bidyl.png";
import Button from "../../../Component/Button/Button";
import { Helmet } from "react-helmet";

const VerifyEmail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto mt-2 w-11/12 md:w-9/12 xl:w-1/2 mb-20 pt-36">
      <Helmet>
        <title>Verify Email | Bidyl</title>
      </Helmet>
      <div className="flex justify-center">
        <img src={bidyl} alt="bidyl" className="w-40 h-24" />
      </div>
      <div className="flex justify-center mt-4">
        <img src={tick} alt="tick-check" className="w-16 h-16" />
      </div>
      <div className="mt-3 text-center ">
        <h3 className="font-bold text-3xl text-primary mt-3">
          Successfully Sent
        </h3>
        <p className="text-base text-secondary mt-4">
          We have sent you email with a link to reset your password. Verify its
          you and you can reset your password
        </p>
      </div>
      <div className="mt-7 w-full flex justify-center">
        <Link to="/">
          <Button
            type="submit"
            className="border-primary border text-primary font-medium px-8 text-lg w-fit py-3 rounded-2xl"
            value="Back to Home Page"
          />
        </Link>
      </div>
    </div>
  );
};

export default VerifyEmail;
