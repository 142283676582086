import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const winnerLoading = () => ({
  type: ActionTypes.WINNER_LOADING,
});

export const removeWinnerData = () => ({
  type: ActionTypes.GET_DATA,
});

export const fetchGetWinnerData = () => (dispatch) => {
  dispatch(winnerLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/winner-section/get-all-data`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DATA,
        payload: data,
      });
    });
};

export const fetchGetWinnerDataUser = (token) => (dispatch) => {
  dispatch(winnerLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/winner-section/get-all-data-for-user`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DATA_USER,
        payload: data,
      });
    });
};
