import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrum from "../../Component/Breadcrum";
import ProductCardGrid from "../../Component/Card/ProductCardGrid";
import ProductCardList from "../../Component/Card/ProductCardList";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetProductByCategory,
  fetchGetProductByCategoryUser,
  removeViewedProduct,
} from "../../Redux/Product/action";
import Header from "./Header";
import wish from "../../Assets/Icons/wish.svg";
import heartIcon from "../../Assets/Icons/Heart Icon.svg";
import Filter from "./Filter";
import {
  fetchAddToWishlist,
  fetchGetOwnWishlist,
  fetchRemoveFromWishlist,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";

import NoImage from "../../Assets/Images/empty.svg";
import Pagination from "../../Component/Pagination/Pagination";
import SinglePagnination from "../../Component/Pagination/SinglePagnination";
import { Helmet } from "react-helmet";

const ProductListing = () => {
  const [toggle, setToggle] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");

  // sorting and pagination
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("descending");
  const [sortParameter, setSortParameter] = useState("id");

  // retrieve cookie
  const token = Cookies.get("token");

  const search = useLocation().search;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.product.isloading, shallowEqual);
  const products = useSelector((state) => state.product.products, shallowEqual);
  const success = useSelector((state) => state.product.success, shallowEqual);
  const totalData = useSelector(
    (state) => state.product.totalData,
    shallowEqual
  );

  const totalPage = useSelector(
    (state) => state.product.totalPage,
    shallowEqual
  );

  useEffect(() => {
    setCategoryId(new URLSearchParams(search).get("index"));
    setCategoryName(new URLSearchParams(search).get("category"));
  }, [location.search]);

  // dispatch api whenever category id, page, sort data or sort parameter changes
  useEffect(() => {
    categoryId && token
      ? dispatch(
          fetchGetProductByCategoryUser(
            categoryId,
            token,
            page,
            sort,
            sortParameter
          )
        )
      : categoryId &&
        dispatch(
          fetchGetProductByCategory(categoryId, page, sort, sortParameter)
        );

    return () => {
      dispatch(removeViewedProduct());
    };
  }, [categoryId, page, sort, sortParameter]);

  // hanlde add or remove item from cart
  const handleWishlist = (id, isInWishList) => {
    !token && navigate("/sign-in");

    if (isInWishList === true) {
      token && dispatch(fetchRemoveFromWishlist(id, token));
      token &&
        dispatch(
          fetchGetProductByCategoryUser(
            categoryId,
            token,
            page,
            sort,
            sortParameter
          )
        );
      token && dispatch(fetchGetOwnWishlist(1, token));
    } else {
      token && dispatch(fetchAddToWishlist(id, token));
      token &&
        dispatch(
          fetchGetProductByCategoryUser(
            categoryId,
            token,
            page,
            sort,
            sortParameter
          )
        );
      token && dispatch(fetchGetOwnWishlist(1, token));
    }
    token &&
      dispatch(
        fetchGetProductByCategoryUser(
          categoryId,
          token,
          page,
          sort,
          sortParameter
        )
      );

    token && dispatch(fetchGetOwnWishlist(1, token));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!success || !categoryName) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pb-20 pt-2">
      <Helmet>
        <title>{categoryName} | Bidyl</title>
      </Helmet>
      <Filter
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        categoryId={categoryId}
      />

      <div className="w-11/12 lg:w-3/4 min-h-screen mx-auto pt-36">
        <Breadcrum title="Home" subTitle={categoryName} />
        <hr className="py-2 mt-4" />
        <Header
          totalData={totalData}
          setToggle={setToggle}
          toggle={toggle}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setSortParameter={setSortParameter}
          setSort={setSort}
        />

        {loading ? (
          <div className="w-full">
            <Loading />
          </div>
        ) : products.length ? (
          toggle ? (
            <>
              {products.map((data) => {
                return (
                  <div className="mt-12 w-full" key={data.id}>
                    <ProductCardList
                      data={data}
                      icon={`${data.isInWishList === true ? heartIcon : wish}`}
                      handleWishlist={handleWishlist}
                    />
                  </div>
                );
              })}

              <div className=" mt-20 w-full">
                {totalPage > 5 ? (
                  <Pagination
                    setPage={setPage}
                    totalData={totalData}
                    totalPage={totalPage}
                    page={page}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10">
                {products.map((data) => {
                  return (
                    <div key={data.id} className="mt-16">
                      <ProductCardGrid
                        data={data}
                        icon={`${
                          data.isInWishList === true ? heartIcon : wish
                        }`}
                        handleWishlist={handleWishlist}
                      />
                    </div>
                  );
                })}
              </div>
              <div className=" mt-20 w-full">
                {totalPage > 5 ? (
                  <Pagination
                    totalData={totalData}
                    totalPage={totalPage}
                    page={page}
                    setPage={setPage}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </>
          )
        ) : (
          <div className="w-10/12 h-80 mx-auto mt-10">
            <img
              src={NoImage}
              alt="No Product Found"
              className="w-full h-full"
            />
          </div>
        )}
      </div>

      <div
        onClick={() => setShowFilter(false)}
        className={`${
          showFilter &&
          "bg-black z-10 bg-opacity-20 top-0 w-full h-screen fixed"
        }`}
      ></div>
    </div>
  );
};

export default ProductListing;
