import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BidCard, { GridBidCard } from "../../Component/Card/BidCard";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import { fetchGetBidPageDetail } from "../../Redux/BuyBid/action";
import { Helmet } from "react-helmet";

const BuyBids = () => {
  const bidPackages = useSelector(
    (state) => state.buyBid.bidPackages,
    shallowEqual
  );

  const titleText = useSelector(
    (state) => state.buyBid.titleText,
    shallowEqual
  );
  const subText = useSelector((state) => state.buyBid.subText, shallowEqual);
  const footerNote = useSelector(
    (state) => state.buyBid.footerNote,
    shallowEqual
  );
  const loading = useSelector((state) => state.buyBid.isloading, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetBidPageDetail());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="w-11/12 xl:w-3/4 mx-auto pt-28 md:pt-40 pb-20">
      <Helmet>
        <title>Buy Bids | Bidyl</title>
      </Helmet>
      <TitleContainer title={titleText} paragraph={subText} />
      {bidPackages && bidPackages.length > 4 ? (
        <BidCard data={bidPackages} />
      ) : (
        <GridBidCard data={bidPackages} />
      )}

      <p className="text-secondary mt-14">{footerNote}</p>
    </div>
  );
};

export default BuyBids;
