import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const charityLoading = () => ({
  type: ActionTypes.CHARITY_LOADING,
});

export const removeCharityData = () => ({
  type: ActionTypes.REMOVE_PARTNER_DATA,
});

export const fetchGetCharityPage = () => (dispatch) => {
  dispatch(charityLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/charity/partners/page/get`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_CHARITY_PARTNER_PAGE,
        payload: data,
      });
    });
};

export const fetchGetActiveCharityPartners = () => (dispatch) => {
  dispatch(charityLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/charity/partners/get-all-active`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ACTIVE_PARTNERS,
        payload: data,
      });
    });
};
