import React, { useEffect } from "react";
import VideoCarousel from "../../Component/Carousell/VideoCarousel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetGiftCardData,
  removeGiftData,
} from "../../Redux/GiftCard/action";
import Loading from "../../Component/Loading/Loading";
import Button from "../../Component/Button/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BuyGiftCard = () => {
  const loading = useSelector(
    (state) => state.giftCard.isloading,
    shallowEqual
  );

  const secondTitleText = useSelector(
    (state) => state.giftCard.secondTitleText,
    shallowEqual
  );
  const secondSubText = useSelector(
    (state) => state.giftCard.secondSubText,
    shallowEqual
  );
  const howItWorksTitleText = useSelector(
    (state) => state.giftCard.howItWorksTitleText,
    shallowEqual
  );
  const howItWorksSubText = useSelector(
    (state) => state.giftCard.howItWorksSubText,
    shallowEqual
  );
  const footerText = useSelector(
    (state) => state.giftCard.footerText,
    shallowEqual
  );
  const footerText2 = useSelector(
    (state) => state.giftCard.footerText2,
    shallowEqual
  );

  const howItWorks = useSelector(
    (state) => state.giftCard.howItWorks,
    shallowEqual
  );

  const carouselImages = useSelector(
    (state) => state.giftCard.carouselImages,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchGetGiftCardData());

    return () => {
      dispatch(removeGiftData());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pb-20 pt-12">
      <Helmet>
        <title>Send Bids | Bidyl</title>
      </Helmet>
      <VideoCarousel video={carouselImages} />

      {/* gift package */}
      <div
        className={`${
          carouselImages && carouselImages.length ? "mt-0" : "mt-28"
        } w-11/12  xl:w-3/4 mx-auto mt-14`}
      >
        <h5 className="font-bold text-xl md:text-3xl text-center">
          {secondTitleText}
        </h5>
        <Link to="/send-bid">
          <div className="bg-primary text-white rounded-full text-base md:text-xl font-medium w-2/3 md:w-1/4 text-center py-3 mt-10 flex justify-center items-center mx-auto">
            <Button value="Send Bids" />
          </div>
        </Link>

        <h6 className="mt-10 text-secondary text-center">{secondSubText}</h6>
      </div>

      {/* how it works */}
      <div className="mt-20">
        <div className="w-11/12 xl:w-1/2 mx-auto">
          <h3 className="font-bold text-2xl md:text-4xl text-center">
            {howItWorksTitleText}
          </h3>
          <h6 className="text-base md:text-xl font-medium text-center text-secondary mt-5">
            {howItWorksSubText}
          </h6>
        </div>

        <div className="w-11/12 xl:w-3/4 mx-auto mt-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {howItWorks &&
              howItWorks.map((data) => {
                return (
                  <div className="relative mt-10 px-1 sm:px-3" key={data.id}>
                    <img
                      src={data.image}
                      alt=""
                      className="w-full h-52 md:h-40 xl:h-52 rounded-lg"
                    />

                    <h5 className="font-bold text-xl md:text-2xl mt-4 truncate">
                      {data.titleText}
                    </h5>
                    <p className="text-secondary text-sm md:text-base mt-2 truncate5">
                      {data.subText}
                    </p>
                  </div>
                );
              })}
          </div>

          <div className="mt-14">
            <h6 className="text-base md:text-xl text-secondary text-center">
              {footerText}
            </h6>

            <h6 className="font-medium text-base md:text-xl text-red-700 mt-16 text-center">
              {footerText2}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyGiftCard;
