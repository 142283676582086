import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bidHammer from "../../Assets/Icons/White-hammer.svg";
import Loading from "../Loading/Loading";
import timer from "../../Assets/Icons/timer.svg";
import NoImages from "../NoData/NoImages";

const ProductCardGrid = ({ data, icon, handleWishlist }) => {
  // create a new Date object with the UTC date and time
  const utcDate = new Date(
    data.status === "PENDING" ? data.auctionStartDate : data.auctionEndDate
  );

  // get the local timezone offset in minutes
  const offsetMinutes = utcDate.getTimezoneOffset();

  // create a new Date object with the local date and time
  const localDate = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);

  // counter
  const endDate = localDate.getTime();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(endDate));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(getTimeLeft(endDate));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [endDate]);

  const { days, hours, minutes, seconds } = timeLeft;

  function getTimeLeft(endDate) {
    const totalSeconds = Math.floor((endDate - new Date().getTime()) / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return { days, hours, minutes, seconds };
  }

  // ##################

  if (!data) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="w-full h-full relative cursor-pointer">
      <div className="w-full h-10 absolute top-5 px-5 flex justify-between items-center">
        <div className="flex gap-x-1 items-center rounded-full py-2 bg-white w-fit px-4">
          <h6 className="font-medium text-xl">Bid:</h6>
          <h6 className="font-bold text-xl text-primary ">{data.bidCount}</h6>
        </div>
        <div
          className="cursor-pointer"
          onClick={
            data.item
              ? () => handleWishlist(data.item.id, data.item.isInWishList)
              : () => handleWishlist(data.id, data.isInWishList)
          }
        >
          <img src={icon} alt="wish icon" />
        </div>
      </div>
      <Link to={`/product?index=${data.id}&name=${data.title}`}>
        <div className="w-full h-80 md:h-60 lg:h-80 border overflow-hidden rounded-2xl">
          {data.images?.length ? (
            <img
              src={data.images?.[0].image}
              alt="product img"
              className="w-full h-full object-cover"
            />
          ) : (
            <NoImages />
          )}
        </div>

        <h6 className="font-bold text-xl mt-5">{data.title}</h6>
        {/* timer section */}
        <div
          className="w-full flex items-center justify-between mt-4 py-4 px-3 rounded-2xl"
          style={{ background: "#F7F9FC" }}
        >
          <div className="flex gap-x-1 items-center">
            <img src={timer} alt="timer" className="w-4 h-4" />
            <p className="text-xs">{`${
              data.status === "PENDING"
                ? "Starts in"
                : data.status === "IN_PROGRESS"
                ? "Ends in"
                : "Status"
            }`}</p>
          </div>
          <div>
            {data.status === "PENDING" || data.status === "IN_PROGRESS" ? (
              <h6 className="font-bold text-primary text-xs">
                {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
              </h6>
            ) : (
              <h6 className="text-primary font-bold">{data.status}</h6>
            )}
          </div>
        </div>

        <button className="font-medium mt-10 flex gap-x-3 bg-primary py-3 md:py-3.5 w-1/2 md:w-3/4 xl:w-1/2 text-white rounded-2xl justify-center items-center">
          <img src={bidHammer} alt="bidding hammer" />
          Place a Bid
        </button>
      </Link>
    </div>
  );
};

export default ProductCardGrid;
