import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const pageLoading = () => ({
  type: ActionTypes.PAGE_LOADING,
});

export const fetchGetAllPages = () => (dispatch) => {
  dispatch(pageLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/page-management/get-all`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_PAGE,
        payload: data,
      });
    });
};
