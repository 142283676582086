import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const productLoading = () => ({
  type: ActionTypes.PRODUCT_LOADING,
});

export const removeViewedProduct = () => ({
  type: ActionTypes.REMOVE_VIEWED_PRODUCT,
});

export const removeMessage = () => ({
  type: ActionTypes.REMOVE_MESSAGE,
});

export const fetchGetProductByCategory =
  (id, page, sort, sortParameter) => (dispatch) => {
    dispatch(productLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/product/get-all-active-by-category?categoryId=${id}&page=${page}&pageSize=10&sort=${sort}&sortParameter=${sortParameter}`
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PRODUCT_BY_CATEGORY,
          payload: data,
        });
      });
  };

export const fetchGetProductByCategoryUser =
  (id, token, page, sort, sortParameter) => (dispatch) => {
    dispatch(productLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/product/get-all-active-by-category-for-user?categoryId=${id}&page=${page}&pageSize=10&sort=${sort}&sortParameter=${sortParameter}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PRODUCT_BY_CATEGORY_USER,
          payload: data,
        });
      });
  };

export const fetchGetProductById = (id) => (dispatch) => {
  dispatch(productLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/product/get-details?productId=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PRODUCT_BY_ID,
        payload: data,
      });
    });
};

export const fetchGetProductByIdUser = (id, token) => (dispatch) => {
  dispatch(productLoading());
  return axios
    .get(
      `${BaseUrl}/bidyl/v1/api/product/get-details-for-user?productId=${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PRODUCT_BY_ID_USER,
        payload: data,
      });
    });
};

export const fetchPlaceBid = (body, token) => (dispatch) => {
  dispatch(productLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/bid/add-bid`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.PLACE_A_BID,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_PLACE_BID,
        payload: e.response.data,
      });
    });
};

export const fetchPlaceAutoBid = (body, token) => (dispatch) => {
  dispatch(productLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/bid/add-auto-bids`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.PLACE_AUTO_BID,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_PLACE_AUTO_BID,
        payload: e.response.data,
      });
    });
};

export const fetchSearchProductByCategory = (key, categoryId) => (dispatch) => {
  dispatch(productLoading());
  axios
    .get(
      `${BaseUrl}/bidyl/v1/api/product/search-by-category?keyword=${key}&categoryId=${categoryId}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEARCH_PRODUCT_BY_CATEGORIES,
        payload: data,
      });
    });
};

export const fetchSearchProductByCategoryUser =
  (key, categoryId, token) => (dispatch) => {
    dispatch(productLoading());
    axios
      .get(
        `${BaseUrl}/bidyl/v1/api/product/search-by-category-for-user?keyword=${key}&categoryId=${categoryId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PRODUCT_BY_CATEGORY_USER,
          payload: data,
        });
      });
  };

export const fetchGetProductByCountry = (country) => (dispatch) => {
  dispatch(productLoading());
  axios
    .get(`${BaseUrl}/bidyl/v1/api/product/get-by-country?country=${country}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PRODUCT_BY_COUNTRY,
        payload: data,
      });
    });
};

export const fetchGetProductByCountryUser = (country, token) => (dispatch) => {
  dispatch(productLoading());
  axios
    .get(
      `${BaseUrl}/bidyl/v1/api/product/get-by-country-for-user?country=${country}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PRODUCT_BY_COUNTRY_USER,
        payload: data,
      });
    });
};
