import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const twoFactorLoading = () => ({
  type: ActionTypes.TWO_FACTOR_LOADING,
});

export const removeTwoFactor = () => ({
  type: ActionTypes.REMOVE_TWO_FACTOR,
});

export const fetchEnableTwoFactor = (body, token) => (dispatch) => {
  dispatch(twoFactorLoading());

  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/enable-two-fa`, body, config)
    .then(({ data }) => {
      if (data.isSuccess) {
        dispatch({
          type: ActionTypes.ENABLE_TWO_FACTOR,
          payload: data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_ENABLE_TWO_FACTOR,
        payload: e.response.data,
      });
    });
};

export const fetchVerifyEnableTwoFactor = (body, token) => (dispatch) => {
  dispatch(twoFactorLoading());

  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/verify-enable-two-fa`, body, config)
    .then(({ data }) => {
      if (data.isSuccess) {
        dispatch({
          type: ActionTypes.VERIFY_ENABLE_TWO_FACTOR,
          payload: data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_VERIFY_TWO_FACTOR,
        payload: e.response.data,
      });
    });
};

export const fetchDisableTwoFactor = (body, token) => (dispatch) => {
  dispatch(twoFactorLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/disable-two-fa`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DISABLE_TWO_FACTOR,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_DISABLE_TWO_FACTOR,
        payload: e.response.data,
      });
    });
};

export const fetchDisableTwoFactorCode = (body, token) => (dispatch) => {
  dispatch(twoFactorLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/verify-disable-two-fa`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DISABLE_TWO_FACTOR_CODE,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_DISABLE_TWO_FACTOR_CODE,
        payload: e.response.data,
      });
    });
};
