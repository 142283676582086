import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const giftCardLoading = () => ({
  type: ActionTypes.GIFT_CARD_LOADING,
});

export const removeGiftData = () => ({
  type: ActionTypes.REMOVE_GIFT_DATA,
});

export const fetchGetGiftCardData = () => (dispatch) => {
  dispatch(giftCardLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/gift/page/get-all`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_GIFT_CARD_DATA,
        payload: data,
      });
    });
};

export const fetchSendGift = (token, body) => (dispatch) => {
  dispatch(giftCardLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/gift/send`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_GIFT,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_SEND_GIFT,
        payload: e.response.data,
      });
    });
};
