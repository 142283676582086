import React from "react";
import house from "../../Assets/Icons/house-2.svg";
import NoImages from "../NoData/NoImages";

const WrapperCard = ({ data, className, button }) => {
  return (
    <div className={className}>
      <div className="w-full md:w-1/2">
        <img src={house} alt="img" className=" w-8 h-8 md:w-12 md:h-12" />
        <h5 className="font-bold text-base md:text-2xl mt-5 truncate">
          {data.titleText}
        </h5>

        <p className="text-secondary mt-2 md:mt-4 leading-5 md:leading-7 text-xs md:text-lg text-justify">
          {data.subText}
        </p>
        {button}
      </div>
      <div className="w-60 h-36 sm:h-40 md:w-1/2 md:h-80">
        {data.image ? (
          <img
            src={data.image}
            className="h-full w-full rounded-2xl object-fill"
            alt=""
          />
        ) : (
          <NoImages />
        )}
      </div>
    </div>
  );
};

export default WrapperCard;
