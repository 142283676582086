import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  titleText: "",
  subText: "",
  secondTitleText: "",
  secondSubText: "",
  detailsPageData: [],
  carouselImages: [],
  rating: [],
};

export const Aboutus = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ABOUT_US_DATA:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        detailsPageData: action.payload.detailsPageData,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        secondTitleText: action.payload.secondTitleText,
        secondSubText: action.payload.secondSubText,
        rating: action.payload,
        carouselImages: action.payload.carouselImages,
      };

    case ActionTypes.REMOVE_ABOUT_US_DATA:
      return {
        titleText: "",
        subText: "",
        success: "",
        detailsPageData: "",
      };
    default:
      return state;
  }
};
