import React, { useState } from "react";
import logo from "../../Assets/Logo/Main Logo.png";
import hamburger from "../../Assets/Icons/Hamburger_MD.svg";
import wallet from "../../Assets/Icons/empty-wallet.svg";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { shallowEqual, useSelector } from "react-redux";

const Header = () => {
  const [showCategory, setShowCategory] = useState(false);
  const userWallet = useSelector((state) => state.profile.wallet, shallowEqual);
  const [toggle, setToggle] = useState(false);

  const handleMenuToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div className="h-20 md:h-28 border-b border-border-color w-full py-6 fixed z-50 bg-white">
      <div className="w-11/12 md:w-10/12 container mx-auto flex items-center justify-between">
        <Link to="/">
          <div className="w-28 h-8 md:w-48 md:h-16">
            <img src={logo} alt="logo" className="w-full h-full" />
          </div>
        </Link>
        <div className="flex items-center md:gap-x-10">
          <div className="flex items-center gap-x-2">
            <img src={wallet} alt="wallet" />
            <h6 className="font-bold text-sm md:text-lg text-primary">
              {userWallet ? userWallet : "0"} Bids
            </h6>
          </div>
          <img
            src={hamburger}
            alt="menu"
            className="w-16 h-8 md:w-20 md:h-12 cursor-pointer"
            onClick={handleMenuToggle}
          />
        </div>
      </div>
      <Menu
        toggle={toggle}
        setToggle={setToggle}
        showCategory={showCategory}
        setShowCategory={setShowCategory}
      />

      {toggle && (
        <div
          className="absolute top-0 bg-black w-full h-screen bg-opacity-10"
          onMouseEnter={() => setShowCategory(false)}
          onClick={() => setToggle(false)}
        ></div>
      )}
    </div>
  );
};

export default Header;
