import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  bidPackages: [],
  totalPage: "",
  totalData: "",
  pageNumber: "",
  titleText: "",
  subText: "",
  footerNote: "",
};

export const BuyBids = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BIDS_PACKAGE:
      return {
        ...state,
        isloading: false,
        success: true,
        bidPackages: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        pageNumber: action.payload.pageNumber,
      };

    case ActionTypes.GET_DETAILS:
      return {
        ...state,
        isloading: false,
        success: true,
        bidPackages: action.payload.bidPackages?.data,
        titleText: action.payload.titleText,
        subText: action.payload.subText,
        footerNote: action.payload.footerNote,
      };

    default:
      return state;
  }
};
