import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const termAndPolicyLoading = () => ({
  type: ActionTypes.TERMS_PRIVACY_LOADING,
});

export const removeTermsAndPolicy = () => ({
  type: ActionTypes.REMOVE_TERMS_POLICY,
});

export const fetchGetTermsAndPolicy = (query) => (dispatch) => {
  dispatch(termAndPolicyLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/privacy-and-terms/get?textFor=${query}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_TERMS_POLICY,
        payload: data,
      });
    })
    .catch((err) => {
      console.log("catch err", err);
    });
};
