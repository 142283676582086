import React from "react";

const Header = ({
  profileModal,
  myBidsModal,
  historyModal,
  securityModal,
  handleProfileModal,
  handleMyBidsModal,
  handleHistoryModal,
  handleSecurityModal,
}) => {
  return (
    <div className="w-full h-16 flex items-center gap-x-1 md:gap-x-4 lg:gap-x-10 xl:gap-x-20">
      <h6
        className={`${
          profileModal
            ? "text-primary border-b-2 border-b-primary font-bold text-sm md:text-xl xl:text-2xl"
            : "text-secondary text-sm md:text-xs xl:text-xl font-medium"
        } cursor-pointer py-5 md:py-4 px-5 md:px-10 w-fit`}
        onClick={handleProfileModal}
      >
        Profile
      </h6>
      <h6
        className={`${
          myBidsModal
            ? "text-primary border-b-2 border-b-primary font-bold text-sm md:text-xl xl:text-2xl"
            : "text-secondary text-xs md:text-base xl:text-xl font-medium"
        } cursor-pointer py-3 md:py-4 px-5 md:px-10w-fit`}
        onClick={handleMyBidsModal}
      >
        My Bids
      </h6>
      <h6
        className={`${
          historyModal
            ? "text-primary border-b-2 border-b-primary font-bold text-sm md:text-xl xl:text-2xl"
            : "text-secondary text-xs md:text-base xl:text-xl font-medium"
        } cursor-pointer py-3 md:py-4 px-5 md:px-10 w-fit`}
        onClick={handleHistoryModal}
      >
        Order History
      </h6>
      <h6
        className={`${
          securityModal
            ? "text-primary border-b-2 border-b-primary font-bold text-sm md:text-xl xl:text-2xl"
            : "text-secondary text-xs md:text-base xl:text-xl font-medium"
        } cursor-pointer py-5 md:py-4 px-5 md:px-10 w-fit`}
        onClick={handleSecurityModal}
      >
        Security
      </h6>
    </div>
  );
};

export default Header;
