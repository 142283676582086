import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  titleText: "",
  subText: "",
  secondTitleText: "",
  secondSubText: "",
  howItWorksTitleText: "",
  howItWorksSubText: "",
  footerText: "",
  footerText2: "",
  howItWorks: [],
  carouselImages: [],
  message: "",
  errMsg: "",
};

export const GiftCard = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_GIFT_CARD_DATA:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        howItWorks: action.payload?.detailsPageData,
        titleText: action.payload?.titleText,
        subText: action.payload?.subText,
        secondTitleText: action.payload?.secondTitleText,
        secondSubText: action.payload?.secondSubText,
        howItWorksTitleText: action.payload?.howItWorksTitleText,
        howItWorksSubText: action.payload?.howItWorksSubText,
        footerText: action.payload?.footerText,
        footerText2: action.payload?.footerText2,
        carouselImages: action.payload?.carouselImages,
      };

    case ActionTypes.SEND_GIFT:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        message: action.payload?.message,
      };

    case ActionTypes.FAIL_SEND_GIFT:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.REMOVE_GIFT_DATA:
      return {
        ...state,
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
