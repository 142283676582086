import React from "react";
import packageIcon from "../../Assets/Icons/package.svg";
import giftIcon from "../../Assets/Icons/gift.svg";
import deleteIcon from "../../Assets/Icons/bag-cross.svg";
import addCircle from "../../Assets/Icons/add-circle.svg";
import minusCircle from "../../Assets/Icons/minus-circle.svg";
import noCart from "../../Assets/Images/empty.svg";

const Table = ({
  headings,
  data,
  handleDelete,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
}) => {
  return (
    <div className="py-2 align-middle min-w-full mt-2">
      <div className="relative">
        <table
          className={`${!data.length ? "h-96" : "h-full"} table-auto w-full`}
        >
          <thead className="w-full">
            <tr>
              {headings.map((title) => {
                return (
                  <th
                    key={title.id}
                    scope="col"
                    className="px-6 py-3 text-left font-medium  text-secondary uppercase"
                  >
                    {title.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          {data && data.length ? (
            <tbody className="bg-white">
              {data &&
                data.map((data) => {
                  return (
                    <tr key={data.id}>
                      {/* items */}
                      <td className="px-4 py-8 whitespace-nowrap">
                        <div className="flex items-center justify-between">
                          <div className="text-base lg:text-xl font-bold flex gap-x-2 items-center">
                            <img
                              src={`${
                                data.isGiftCard ? giftIcon : packageIcon
                              }`}
                              alt="package icon"
                              className="w-6 h-6 lg:w-8 lg:h-8"
                            />
                            {data.item}
                          </div>
                        </div>
                      </td>
                      {/* quantity */}
                      <td className="px-4 py-8 whitespace-nowrap">
                        <div className="flex items-center gap-x-3">
                          <img
                            src={minusCircle}
                            alt="minus circle"
                            className="cursor-pointer"
                            onClick={() => handleDecreaseQuantity(data.id)}
                          />
                          <span className="font-bold text-base lg:text-xl">
                            {data.quantity}
                          </span>
                          <img
                            src={addCircle}
                            alt="add cirlce"
                            className="cursor-pointer"
                            onClick={() => handleIncreaseQuantity(data.id)}
                          />
                        </div>
                      </td>

                      {/* total */}
                      <td className="px-4 py-8 whitespace-nowrap">
                        <div className="font-bold text-primary">
                          ${" "}
                          <span className="text-base lg:text-2xl ">
                            {data.total}
                          </span>
                        </div>
                      </td>

                      <td className="px-4 py-8 whitespace-nowrap">
                        <div className="flex items-center justify-between">
                          <div
                            className="text-xl font-bold flex gap-x-2 items-center cursor-pointer"
                            onClick={() => handleDelete(data.id)}
                          >
                            <img
                              src={deleteIcon}
                              alt="package icon"
                              className="w-7 h-7"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          ) : (
            <div className="absolute w-80 md:w-96 h-96 top-32  xl:left-1/4">
              <img src={noCart} alt="no Cart data" />
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default Table;
