import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../../Assets/Icons/Right-arrow.svg";
import bidHammer from "../../Assets/Icons/White-hammer.svg";
import Loading from "../Loading/Loading";
import NoImages from "../NoData/NoImages";

const ContainerCard = ({ data, className }) => {
  // const bannerSliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  const navigate = useNavigate();

  const handleNavigate = (id, name) => {
    navigate(`/products?index=${id}&category=${name}`);
  };

  return (
    <Slider {...settings}>
      {data &&
        data.slice(0, 3).map((data) => {
          return (
            <div key={data.id}>
              <div className={className}>
                <div className="w-60 h-32 md:w-1/2 md:h-80 relative">
                  {data.images?.length ? (
                    <img
                      src={data.images?.[0].image}
                      className="h-full w-full rounded-2xl object-fill"
                      alt={data.title}
                    />
                  ) : (
                    <NoImages />
                  )}
                </div>

                <div className="w-full md:w-1/2 xl:w-[48%] px-2">
                  <div className="flex justify-between items-center">
                    <h4 className="font-bold text-base w-1/2 md:w-3/4 md:text-xl xl:text-3xl">
                      {data.category.categoryName}
                    </h4>
                    <p
                      className="text-primary font-medium text-xs xl:text-base flex gap-x-2 xl:gap-x-5 items-center cursor-pointer"
                      onClick={() =>
                        handleNavigate(
                          data.category.id,
                          data.category.categoryName
                        )
                      }
                    >
                      View All{" "}
                      <img
                        src={rightArrow}
                        alt="right arrow"
                        className="w-2 h-2"
                      />
                    </p>
                  </div>
                  <h6 className="font-medium text-sm md:text-base xl:text-xl text-secondary py-0.5">
                    {data.category.totalProductCount} Products
                  </h6>

                  <h5 className="font-bold text-sm md:text-lg xl:text-2xl mt-5">
                    {data.title}
                  </h5>
                  <p className="text-secondary mt-4 text-sm xl:text-base truncate5">
                    {data.description}
                  </p>
                  <Link to={`/product?index=${data.id}&name=${data.title}`}>
                    <div className="mt-5 bg-primary text-white flex justify-center items-center mx-auto py-2.5 md:py-3 xl:py-4 rounded-lg">
                      <button className="font-medium text-sm xl:text-base flex gap-x-3 justify-center items-center">
                        <img src={bidHammer} alt="bidding hammer" />
                        Place a Bid
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </Slider>
  );
};

export default ContainerCard;
