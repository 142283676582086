import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { Login } from "./Login/reducer";
import { Signup } from "./Register/reducer";
import { TermAndPolicy } from "./Terms&Privacy/reducer";
import { Home } from "./Home/reducer";
import { Winner } from "./Winner/reducer";
import { Faq } from "./Faq/reducer";
import { Wishlist } from "./Wishlist/reducer";
import { HowItWorks } from "./HowItWorks/reducer";
import { Aboutus } from "./AboutUs/reducer";
import { Category } from "./Category/reducer";
import { Products } from "./Product/reducer";
import { ContactUs } from "./ContactUs/reducer";
import { Cart } from "./Cart/reducer";
import { BuyBids } from "./BuyBid/reducer";
import { GiftCard } from "./GiftCard/reducer";
import { Charity } from "./Charity/reducer";
import { Profile } from "./Profile/reducer";
import { TwoFactor } from "./TwoFactor/reducer";
import { Qr } from "./QR/reducer";
import { MyBid } from "./MyBids/reducer";
import { Transaction } from "./Transaction/reducer";
import { Footer } from "./Footer/reducer";
import { Pages } from "./PageManagement/reducer";
import { Partners } from "./Partners/reducer";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      login: Login,
      signup: Signup,
      termAndPolicy: TermAndPolicy,
      home: Home,
      winner: Winner,
      faq: Faq,
      wishlist: Wishlist,
      howitworks: HowItWorks,
      aboutus: Aboutus,
      categories: Category,
      product: Products,
      contactus: ContactUs,
      cart: Cart,
      buyBid: BuyBids,
      giftCard: GiftCard,
      charity: Charity,
      profile: Profile,
      twoFactor: TwoFactor,
      qr: Qr,
      mybid: MyBid,
      transaction: Transaction,
      footer: Footer,
      pages: Pages,
      partner: Partners,
    }),
    process.env.NODE_ENV === "development"
      ? compose(composeWithDevTools(applyMiddleware(...[thunk, logger])))
      : compose(applyMiddleware(...[thunk]))
  );

  return store;
};
