import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const profileLoading = () => ({
  type: ActionTypes.PROFILE_LOADING,
});

export const removeProfileData = () => ({
  type: ActionTypes.REMOVE_PROFILE,
});

export const fetchGetMyWallet = (token) => (dispatch) => {
  dispatch(profileLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/client/get-my-wallet`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_WALLET,
        payload: data,
      });
    });
};

export const fetchGetMyOwnInfo = (token) => (dispatch) => {
  dispatch(profileLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/client/get-my-own-information`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_OWN_INORMATION,
        payload: data,
      });
    });
};

export const fetchChangePassword = (body, token) => (dispatch) => {
  dispatch(profileLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/change-password`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_CHANGE_PASSWORD,
        payload: e.response.data,
      });
    });
};

export const fetchUpdateInformation = (body, token) => (dispatch) => {
  dispatch(profileLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .put(
      `${BaseUrl}/bidyl/v1/api/client/add-additional-information`,
      body,
      config
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_INFORMATION,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_UPDATE_INFORMATION,
        payload: e.response.data,
      });
    });
};

export const fetchChangeProfile = (body, token) => (dispatch) => {
  dispatch(profileLoading());
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(`${BaseUrl}/bidyl/v1/api/client/change-profile-picture`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_PROFILE,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_CHANGE_PROFILE,
        payload: e.response.data,
      });
    });
};

export const fetchGetMyBid =
  (page, sort, sortParameter, token) => (dispatch) => {
    dispatch(profileLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/bid/get-mine?page=${page}&pageSize=10&sort=${sort}&sortParameter=${sortParameter}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_MY_BID,
          payload: data,
        });
      });
  };

export const fetchSearchMyBid = (key, token) => (dispatch) => {
  dispatch(profileLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/bid/search-mine?keyWord=${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEARCH_MY_BID,
        payload: data,
      });
    });
};

export const fetchGetMyOrderHistory =
  (page, sort, sortParameter, token) => (dispatch) => {
    dispatch(profileLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/client/get-order-history?page=${page}&pageSize=10&sort=${sort}&sortParameter=${sortParameter}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_MY_ORDER_HISTORY,
          payload: data,
        });
      });
  };

export const fetchSearchMyOrderHistory = (key, token) => (dispatch) => {
  dispatch(profileLoading());
  return axios
    .get(
      `${BaseUrl}/bidyl/v1/api/checkout/search-order-details?keyword=${key}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEARCH_ORDER_HISTORY,
        payload: data,
      });
    });
};

export const fetchAddBiddingPassword = (body, token) => (dispatch) => {
  dispatch(profileLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/add-bidding-password`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_BIDDING_PASSWORD,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_ADD_BIDDING_PASSWORD,
        payload: e.response.data,
      });
    });
};

export const fetchChangeBiddingPassword = (body, token) => (dispatch) => {
  dispatch(profileLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .put(`${BaseUrl}/bidyl/v1/api/client/update-bidding-password`, body, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_BIDDING_PASSWORD,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_CHANGE_BIDDING_PASSWORD,
        payload: e.response.data,
      });
    });
};

export const fetchRemoveBiddingPassword = (token, password) => (dispatch) => {
  dispatch(profileLoading());
  return axios
    .delete(
      `${BaseUrl}/bidyl/v1/api/client/remove-bidding-password?password=${password}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REMOVE_BIDDING_PASSWORD,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_REMOVE_BIDDING_PASSWORD,
        payload: e.response.data,
      });
    });
};
