import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../../Component/Loading/Loading";
import { fetchGetTermsAndPolicy } from "../../Redux/Terms&Privacy/action";
import Body from "./Body";
import Header from "./Header";
import { Helmet } from "react-helmet";

const Policy = () => {
  const text = useSelector(
    (state) => state.termAndPolicy.termAndPolicy,
    shallowEqual
  );

  const effective = useSelector(
    (state) => state.termAndPolicy.effective,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.termAndPolicy.isloading,
    shallowEqual
  );

  const query = "privacy";
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(fetchGetTermsAndPolicy(query));
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="w-11/12 xl:w-3/4 pt-28 md:pt-40 mx-auto mb-20">
      <Helmet>
        <title>Privacy & Policy | Bidyl</title>
      </Helmet>
      <Header
        heading="Privacy Policy"
        text="Please read these Terms carefully and check them regularly as they may change from time to time"
        heading2="Privacy Notice"
        effective={effective}
      />
      <Body text={text} />
    </div>
  );
};

export default Policy;
