import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import bidHammer from "../../Assets/Icons/White-hammer.svg";
import Loading from "../Loading/Loading";
import NoImages from "../NoData/NoImages";

const ProductCardList = ({ data, icon, handleWishlist }) => {
  if (!data) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="flex gap-x-5 md:gap-x-20 w-full">
      <div className="w-1/2 h-40 md:h-80 relative">
        {data?.images?.length || data.item?.images?.length ? (
          <img
            src={
              data.item ? data.item.images?.[0]?.image : data.images?.[0].image
            }
            alt="product img"
            className="w-full h-full rounded-2xl"
          />
        ) : (
          <NoImages />
        )}

        <div className="w-full h-10 absolute top-5 px-2 md:px-5 flex justify-between items-center">
          <div className="flex gap-x-1 items-center rounded-full py-2 bg-white w-fit px-4">
            <h6 className="font-medium text-xs md:text-xl">Bid:</h6>
            <h6 className="font-bold text-xs md:text-xl text-primary ">
              {data.item ? data.item.bidCount : data.bidCount}
            </h6>
          </div>
          <div
            className="cursor-pointer"
            onClick={
              data.item
                ? () => handleWishlist(data.item.id, data.item.isInWishList)
                : () => handleWishlist(data.id, data.isInWishList)
            }
          >
            <img
              src={icon}
              alt="wish icon"
              className="w-7 h-7 md:w-full md:h-full"
            />
          </div>
        </div>
      </div>

      <div className="w-1/2">
        <h6 className="text-lg md:text-xl text-secondary tracking-widest">
          {data.item
            ? data.item.category.categoryName
            : data.category.categoryName}
        </h6>
        <h5 className="font-bold text-xl md:text-2xl mt-3">
          {data.item ? data.item.title : data.title}
        </h5>
        <p className="text-secondary mt-3 text-sm md:text-base truncate5">
          {data.item ? data.item.description : data.description}
        </p>

        <Link
          to={`${
            data.item
              ? `/product?index=${data.item.id}&name=${data.item.title}`
              : `/product?index=${data.id}&name=${data.title}`
          }`}
        >
          <button className="font-medium mt-6 flex gap-x-3 bg-primary py-2.5 md:py-3.5 w-full text-sm md:text-base md:w-1/2 text-white rounded-2xl justify-center items-center">
            <img src={bidHammer} alt="bidding hammer" />
            Place a Bid
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCardList;
