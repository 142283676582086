import React from "react";

const TitleContainer = ({ title, paragraph }) => {
  return (
    <div>
      <h2 className="font-bold text-xl xl:text-4xl text-center">{title}</h2>
      <h6 className="text-sm md:text-lg xl:text-xl mt-5 text-center text-secondary">
        {paragraph}
      </h6>
    </div>
  );
};

export default TitleContainer;
