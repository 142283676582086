import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../Component/Button/Button";
import WrapperCard from "../../Component/Card/WrapperCard";
import VideoCarousel from "../../Component/Carousell/VideoCarousel";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import { fetchGetAboutusData } from "../../Redux/AboutUs/action";
import Partners, { PartnersCard } from "../../Component/Card/PartnerCard";
import {
  fetchGetAllPartners,
  fetchGetPartnerData,
  removePartnerData,
} from "../../Redux/Partners/action";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const loading = useSelector((state) => state.aboutus.isloading, shallowEqual);
  const detailsPageData = useSelector(
    (state) => state.aboutus.detailsPageData,
    shallowEqual
  );
  const secondTitleText = useSelector(
    (state) => state.aboutus.secondTitleText,
    shallowEqual
  );
  const secondSubText = useSelector(
    (state) => state.aboutus.secondSubText,
    shallowEqual
  );

  const carouselImages = useSelector(
    (state) => state.aboutus.carouselImages,
    shallowEqual
  );

  const partnerLoading = useSelector(
    (state) => state.partner.isloading,
    shallowEqual
  );
  const partners = useSelector((state) => state.partner.partners, shallowEqual);
  const partnerData = useSelector(
    (state) => state.partner.partnerData,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetAboutusData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGetAllPartners());
    dispatch(fetchGetPartnerData());
    return () => {
      dispatch(removePartnerData());
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || partnerLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="pb-20 pt-12">
      <Helmet>
        <title>About us | Bidyl</title>
      </Helmet>
      <VideoCarousel video={carouselImages} />
      {/* live draw section */}
      <div
        className={`py-8 md:py-10 ${
          carouselImages && carouselImages.length ? "mt-0" : "mt-8 md:mt-16"
        }`}
        style={{ background: "#FAFBFB" }}
      >
        <div className="w-11/12 xl:w-1/2 md:mt-5 lg:mt-10 mx-auto flex justify-center items-center flex-col">
          <TitleContainer title={secondTitleText} paragraph={secondSubText} />
          <Link to="/categories">
            <Button
              className="bg-primary text-white font-bold text-sm md:text-xl px-10 text-center py-3 md:py-3.5 w-fit rounded-full mt-12"
              value="Enter Our Live Draw Now"
            />
          </Link>
        </div>
      </div>

      {/* About us section */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-16">
        {detailsPageData &&
          detailsPageData.map((item, i) => {
            return (
              <div key={item.id} className="mt-16">
                <WrapperCard
                  className={`${
                    i % 2 === 0 ? "" : "flex-row-reverse"
                  } flex items-center gap-x-10 xl:gap-x-20 `}
                  data={item}
                />
              </div>
            );
          })}
      </div>

      {/* rating */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-20 xl:mt-28">
        <TitleContainer
          title={partnerData.titleText}
          paragraph={partnerData.subText}
        />
        {partners.length > 4 ? (
          <Partners data={partners} />
        ) : (
          <PartnersCard data={partners} />
        )}
      </div>
    </div>
  );
};

export default AboutUs;
