import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  partners: [],
  partnerData: "",
};

export const Partners = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_PARTNERS:
      return {
        ...state,
        isloading: false,
        success: true,
        partners: action.payload?.data,
      };

    case ActionTypes.GET_PARTNER_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        partnerData: action.payload,
      };

    case ActionTypes.REMOVE_PARTNER_DATA:
      return {
        ...state,
        isloading: true,
        success: false,
        partners: [],
        partnerData: "",
      };

    default:
      return state;
  }
};
