import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const transactionLoading = () => ({
  type: ActionTypes.TRANSACTION_LOADING,
});

export const removeTransactionDetail = () => ({
  type: ActionTypes.REMOVE_TRANSACTION_DETAIL,
});

export const fetchAddBillingInfo = (body, token) => (dispatch) => {
  dispatch(transactionLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/checkout/add-billing-information`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_BILLING_INFORMATION,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_ADD_BILLING_INFORMATION,
        payload: e.response.data,
      });
    });
};

export const fetchGetPaypalSuccess =
  (paymentId, payerId, token) => (dispatch) => {
    dispatch(transactionLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/transaction/paypal-success?paymentId=${paymentId}&payerId=${payerId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PAYPAL_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.FAIL_PAYPAL,
          payload: e.response.data,
        });
      });
  };

export const fetchGetStripeSuccess = (key, token) => (dispatch) => {
  dispatch(transactionLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/transaction/stripe-success?orderId=${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_STRIPE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_STRIPE,
        payload: e.response.data,
      });
    });
};

export const fetchAddTempBillingInfo = (body, cartId) => (dispatch) => {
  dispatch(transactionLoading());
  return axios
    .post(
      `${BaseUrl}/bidyl/v1/api/checkout/temporary/add-billing-information?cartId=${cartId}`,
      body
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TEMP_BILLING_INFO,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_ADD_TEMP_BILLING_INFO,
        payload: e.response.data,
      });
    });
};

export const fetchGetTempPaypalSuccess =
  (paymentId, payerId, cartId) => (dispatch) => {
    dispatch(transactionLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/transaction/temporary/paypal-success?paymentId=${paymentId}&payerId=${payerId}&cartId=${cartId}`
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_TEMP_PAYPAL_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.FAIL_TEMP_PAYPAL,
          payload: e.response.data,
        });
      });
  };

export const fetchGetTempStripeSuccess = (orderId, cartId) => (dispatch) => {
  dispatch(transactionLoading());
  return axios
    .get(
      `${BaseUrl}/bidyl/v1/api/transaction/temporary/stripe-success?orderId=${orderId}&cartId=${cartId}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_TEMP_STRIPE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TEMP_STRIPE,
        payload: e.response.data,
      });
    });
};
