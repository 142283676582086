import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import Message from "../../../Component/Message/Message";
import { fetchDisableTwoFactor } from "../../../Redux/TwoFactor/action";

const DisableTwoFactor = ({
  setDisableOtpPopupModal,
  setTwoFactorDisablePopupModal,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const errMessage = useSelector(
    (state) => state.twoFactor.errMsg,
    shallowEqual
  );
  const otpSent = useSelector((state) => state.twoFactor.sent, shallowEqual);
  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);
  // set formik initial values
  const initialValues = {
    password: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required *"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchDisableTwoFactor(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 2000);
    errMessage && setErrMsg(errMessage);
  };

  useEffect(() => {
    if (otpSent === true) {
      setTwoFactorDisablePopupModal(false);
      setDisableOtpPopupModal(true);
    }
  }, [otpSent]);

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 left-4 md:left-[17%] lg:left-1/4 pt-10 pb-16">
      <div className="w-11/12 md:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-3xl text-primary text-center">
          Disable Two Factor Authentication
        </h4>
        <p className="text-center text-secondary py-1">
          To continue, please enter your password.
        </p>

        {errMessage && (
          <Message
            className="text-red-500 mt-6"
            message={errMsg}
            setMessage={setErrMsg}
          />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="mt-5">
                <FormikControl
                  label="Password"
                  placeholder="Enter your Password"
                  control="password"
                  name="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>
              <div className="flex justify-between mt-6 gap-x-20">
                <div
                  className="w-full rounded-2xl text-center cursor-pointer"
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setTwoFactorDisablePopupModal(false)}
                >
                  <Button
                    value="Cancel"
                    className="py-3.5 md:py-4 px-5 md:px-7 text-sm md:text-base text-primary font-medium "
                  />
                </div>

                <Button
                  value="Continue"
                  type="submit"
                  className="py-3.5 md:text-base text-sm md:py-4 px-5 md:px-7 w-full rounded-2xl text-center bg-primary cursor-pointer text-white "
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DisableTwoFactor;
