export const TRANSACTION_LOADING = "TRANSACTION_LOADING";
export const ADD_BILLING_INFORMATION = "ADD_BILLING_INFORMATION";
export const FAIL_ADD_BILLING_INFORMATION = "FAIL_ADD_BILLING_INFORMATION";
export const GET_PAYPAL_SUCCESS = "GET_PAYPAL_SUCCESS";
export const FAIL_PAYPAL = "FAIL_PAYPAL";
export const GET_STRIPE_SUCCESS = "GET_STRIPE_SUCCESS";
export const FAIL_STRIPE = "FAIL_STRIPE";

export const ADD_TEMP_BILLING_INFO = "ADD_TEMP_BILLING_INFO";
export const FAIL_ADD_TEMP_BILLING_INFO = "FAIL_ADD_TEMP_BILLING_INFO";
export const GET_TEMP_PAYPAL_SUCCESS = "GET_TEMP_PAYPAL_SUCCESS";
export const FAIL_TEMP_PAYPAL = "FAIL_TEMP_PAYPAL";
export const GET_TEMP_STRIPE_SUCCESS = "GET_TEMP_STRIPE_SUCCESS";
export const FAIL_TEMP_STRIPE = "FAIL_TEMP_STRIPE";

export const REMOVE_TRANSACTION_DETAIL = "REMOVE_TRANSACTION_DETAIL";
