import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../Component/Button/Button";
import BidCard, { GridBidCard } from "../../Component/Card/BidCard";
import WrapperCard from "../../Component/Card/WrapperCard";
import VideoCarousel from "../../Component/Carousell/VideoCarousel";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import { fetchGetHowItWorksData } from "../../Redux/HowItWorks/action";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  const loading = useSelector(
    (state) => state.howitworks.isloading,
    shallowEqual
  );

  const carouselImages = useSelector(
    (state) => state.howitworks.carouselImages,
    shallowEqual
  );

  const secondTitleText = useSelector(
    (state) => state.howitworks.secondTitleText,
    shallowEqual
  );
  const secondSubText = useSelector(
    (state) => state.howitworks.secondSubText,
    shallowEqual
  );
  const bidTitleText = useSelector(
    (state) => state.howitworks.bidTitleText,
    shallowEqual
  );
  const bidSubText = useSelector(
    (state) => state.howitworks.bidSubText,
    shallowEqual
  );
  const howItWorksTitleText = useSelector(
    (state) => state.howitworks.howItWorksTitleText,
    shallowEqual
  );
  const howItWorksSubText = useSelector(
    (state) => state.howitworks.howItWorksSubText,
    shallowEqual
  );
  const detailsPageData = useSelector(
    (state) => state.howitworks.detailsPageData,
    shallowEqual
  );

  const bidPackages = useSelector(
    (state) => state.howitworks.bidPackages,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetHowItWorksData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="pb-20 pt-12">
      <Helmet>
        <title>How It Works | Bidyl</title>
      </Helmet>
      <VideoCarousel video={carouselImages} />

      {/* live draw section */}
      <div
        className={`${
          carouselImages && carouselImages.length ? "mt-0" : "mt-8 md:mt-10"
        } py-10`}
        style={{ background: "#FAFBFB" }}
      >
        <div className="px-3 w-11/12 xl:w-1/2 mt-2 md:mt-7 mx-auto flex justify-center items-center flex-col">
          <TitleContainer title={secondTitleText} paragraph={secondSubText} />
          <Link to="/categories">
            <Button
              className="bg-primary text-white font-bold text-sm xl:text-xl px-10 text-center py-3 md:py-3.5 w-fit rounded-full mt-12"
              value="Enter Our Live Draw Now"
            />
          </Link>
        </div>
      </div>

      {/* how it works section */}
      <div className="w-11/12 xl:w-1/2 mx-auto mt-10">
        <TitleContainer
          title={howItWorksTitleText}
          paragraph={howItWorksSubText}
        />
      </div>

      <div className="w-11/12 md:px-0 xl:w-3/4 mx-auto mt-16">
        {detailsPageData &&
          detailsPageData.map((item, i) => {
            return (
              <div key={item.id} className="mt-16">
                <WrapperCard
                  className={`${
                    i % 2 === 0 ? "" : "flex-row-reverse"
                  } flex items-center gap-x-10 `}
                  data={item}
                />
              </div>
            );
          })}
      </div>

      {/* live draw section */}
      <div
        className="py-5 border pb-10 mt-10"
        style={{ background: "#FAFBFB" }}
      >
        <div className="w-11/12 xl:w-1/2 mt-7 px-3 mx-auto flex justify-center items-center flex-col">
          <TitleContainer title={secondTitleText} paragraph={secondSubText} />
          <Link to="/categories">
            <Button
              className="bg-primary text-white font-bold text-sm xl:text-xl px-10 text-center py-3.5 w-fit rounded-full mt-12"
              value="Enter Our Live Draw Now"
            />
          </Link>
        </div>
      </div>

      {/* buy bid section */}
      <div className="mx-auto flex items-center justify-center flex-col text-center mt-10 md:mt-20 w-11/12 xl:w-1/2">
        <TitleContainer title={bidTitleText} paragraph={bidSubText} />
      </div>
      {/* bid packages */}
      <div className="w-11/12 xl:w-9/12 mx-auto">
        {bidPackages && bidPackages.length > 4 ? (
          <BidCard data={bidPackages} />
        ) : (
          <GridBidCard data={bidPackages} />
        )}
      </div>
    </div>
  );
};

export default HowItWorks;
