export const PROFILE_LOADING = "PROFILE_LOADING";
export const GET_MY_WALLET = "GET_MY_WALLET";
export const GET_MY_OWN_INORMATION = "GET_MY_OWN_INFORMATION";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FAIL_TO_CHANGE_PASSWORD = "FAIL_TO_CHANGE_PASSWORD";
export const UPDATE_INFORMATION = "UPDATE_INFORMATION";
export const FAIL_UPDATE_INFORMATION = "FAIL_UPDATE_INFORMATION";
export const ADD_BIDDING_PASSWORD = "ADD_BIDDING_PASSWORD";
export const FAIL_ADD_BIDDING_PASSWORD = "FAIL_ADD_BIDDING_PASSWORD";
export const CHANGE_BIDDING_PASSWORD = "CHANGE_BIDDING_PASSWORD";
export const FAIL_CHANGE_BIDDING_PASSWORD = "FAIL_CHANGE_BIDDING_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const FAIL_CHANGE_PROFILE = "FAIL_CHANGE_PROFILE";
export const GET_MY_BID = "GET_MY_BID";
export const SEARCH_MY_BID = "SEARCH_MY_BID";
export const GET_MY_ORDER_HISTORY = "GET_MY_ORDER_HISTORY";
export const SEARCH_ORDER_HISTORY = "SEARCH_ORDER_HISTORY";
export const REMOVE_BIDDING_PASSWORD = "REMOVE_BIDDING_PASSWORD";
export const FAIL_REMOVE_BIDDING_PASSWORD = "FAIL_REMOVE_PASSWORD";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
