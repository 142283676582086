import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  category: [],
};

export const Category = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CATEGORY:
      return {
        ...state,
        isloading: false,
        success: action.payload.isSuccess,
        category: action.payload.data,
      };

    case ActionTypes.REMOVE_CATEGORY:
      return {
        success: "",
        category: "",
      };
    default:
      return state;
  }
};
