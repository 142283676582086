import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const faqLoading = () => ({
  type: ActionTypes.FAQ_LOADING,
});

export const removeFaqData = () => ({
  type: ActionTypes.REMOVE_FAQ,
});

export const fetchGetFaq = () => (dispatch) => {
  dispatch(faqLoading);
  return axios
    .get(
      `${BaseUrl}/bidyl/v1/api/admin/frequently-asked-question/get-all-active`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_FAQ,
        payload: data,
      });
    });
};

export const fetchGetFaqDetailPage = () => (dispatch) => {
  dispatch(faqLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/frequently-asked-page/get`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_FAQ_DETAIL_PAGE,
        payload: data,
      });
    });
};
