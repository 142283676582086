import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  titleText: "",
  subText: "",
  secondTitleText: "",
  secondSubText: "",
  bidTitleText: "",
  bidSubText: "",
  howItWorksTitleText: "",
  howItWorksSubText: "",
  bidPackages: [],
  detailsPageData: [],
  carouselImages: [],
};

export const HowItWorks = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_HOW_iT_WORKS_DATA:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        bidPackages: action.payload?.bidPackage?.data,
        detailsPageData: action.payload?.detailsPageData,
        titleText: action.payload?.titleText,
        subText: action.payload?.subText,
        secondTitleText: action.payload?.secondTitleText,
        secondSubText: action.payload?.secondSubText,
        bidTitleText: action.payload?.bidTitleText,
        bidSubText: action.payload?.bidSubText,
        howItWorksTitleText: action.payload?.howItWorksTitleText,
        howItWorksSubText: action.payload?.howItWorksSubText,
        carouselImages: action.payload?.carouselImages,
      };

    case ActionTypes.REMOVE_HOW_IT_WORKS_DATA:
      return {
        bidPackages: "",
        titleText: "",
        subText: "",
        success: "",
        detailsPageData: "",
        carouselImages: [],
      };
    default:
      return state;
  }
};
