import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { GoCheck } from "react-icons/go";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Component/Button/Button";
import {
  fetchGetCartData,
  fetchGetTempCartData,
} from "../../Redux/Cart/action";
import { fetchGetMyWallet } from "../../Redux/Profile/action";

const ConfirmationModule = () => {
  const dispatch = useDispatch();

  const token = Cookies.get("token");
  const cartId = localStorage.getItem("cartId");

  const titleText = useSelector(
    (state) => state.transaction.titleText,
    shallowEqual
  );
  const message = useSelector(
    (state) => state.transaction.subText,
    shallowEqual
  );
  const orderId = useSelector(
    (state) => state.transaction.orderId,
    shallowEqual
  );
  const navigate = useNavigate();

  const handleContinueShopping = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    token && orderId && dispatch(fetchGetCartData(token));
    token && orderId && dispatch(fetchGetMyWallet(token));
    !token && orderId && dispatch(fetchGetTempCartData(cartId));
  }, [orderId]);

  return (
    <div className="w-full text-center">
      <h4 className="font-bold text-3xl">{titleText}</h4>
      <div
        className="border rounded-full px-5 py-5 mt-10 w-fit mx-auto"
        style={{ background: "#219653" }}
      >
        <GoCheck className="text-2xl text-white font-bold" />
      </div>

      <h4 className="text-primary font-bold text-2xl mt-10">{message}</h4>

      <div className="flex items-center gap-x-2 justify-center mt-8">
        <h6 className="font-medium text-lg">Your order ID is :</h6>
        <h5 className="text-primary font-bold text-xl">{orderId}</h5>
      </div>
      <h6 className="text-secondary text-lg mt-8">
        You will receive an order confirmation email with details of your order.
      </h6>

      <div
        className=" py-4 text-center rounded-2xl mt-10 bg-primary text-white w-1/3 mx-auto"
        onClick={handleContinueShopping}
      >
        <Button value="Continue Shopping" />
      </div>
    </div>
  );
};

export default ConfirmationModule;
