import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const footerLoading = () => ({
  type: ActionTypes.FOOTER_LOADING,
});

export const fetchGetFooterData = () => (dispatch) => {
  dispatch(footerLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/footer/get`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_FOOTER_DATA,
        payload: data,
      });
    });
};
