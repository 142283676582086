import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import * as Yup from "yup";
import Header from "./Header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchLogin, removeLogin } from "../../../Redux/Login/action";
import Message from "../../../Component/Message/Message";
import { fetchGetMyWallet } from "../../../Redux/Profile/action";
import { removeRegister } from "../../../Redux/Register/action";
import Cookies from "js-cookie";
import TwoFactor from "../../../Component/TwoFactor/TwoFactor";
import { Helmet } from "react-helmet";
import { fetchGetTempCartData } from "../../../Redux/Cart/action";

const Login = () => {
  // state to show and hide password
  const [showPassword, setShowPassword] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [signupMsg, setSignupMsg] = useState("");
  const [twoFactorCheck, setTwoFactorCheck] = useState(false);

  // state for checking the url if it is redirect rom other page or not for authentication
  const [checkUrl, setCheckUrl] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [authMsg, setAuthMsg] = useState("");

  const search = useLocation().search;
  const location = useLocation();

  useEffect(() => {
    setCheckUrl(new URLSearchParams(search).get("authenticate"));
    setRedirectUrl(new URLSearchParams(search).get("path"));
  }, [location.search]);

  useEffect(() => {
    if (checkUrl === "false") {
      return setAuthMsg("You have been redirected, Please login! ");
    }

    setTimeout(() => {
      setAuthMsg("");
    }, [10000]);
  }, [checkUrl]);

  // get email and paswword form local storage
  const userName = localStorage.getItem("userName");
  const password = localStorage.getItem("password");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const twoFactor = useSelector((state) => state.login.twoFactor, shallowEqual);

  useEffect(() => {
    twoFactor && setTwoFactorCheck(true);
    window.scrollTo(0, 0);
  }, [twoFactor]);

  useEffect(() => {
    if (twoFactorCheck === true) {
      setErrMsg("");
      setSuccMsg("");
      setAuthMsg("");
      setSignupMsg("");
    }
  }, [twoFactorCheck]);

  // retrive token
  const token = Cookies.get("token");
  const cartId = localStorage.getItem("cartId");

  useEffect(() => {
    token && navigate(-1);
  }, []);

  // retrieve successMessage from redux
  const successMessage = useSelector(
    (state) => state.login.message,
    shallowEqual
  );

  useEffect(() => {
    setSuccMsg(successMessage);
    setAuthMsg("");
  }, [successMessage]);

  // retrieve errorMessgae from redux
  const errMessage = useSelector((state) => state.login.errMsg, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
    setAuthMsg("");
  }, [errMessage]);

  const signupMessage = useSelector(
    (state) => state.signup.message,
    shallowEqual
  );

  useEffect(() => {
    setSignupMsg(signupMessage);
    setTimeout(() => {
      dispatch(removeRegister());
    }, [10000]);
  }, [signupMessage]);

  useEffect(() => {
    if (twoFactor === false) {
      token && redirectUrl
        ? navigate(redirectUrl)
        : token && !redirectUrl && navigate(-1);
      token && dispatch(fetchGetMyWallet(token));
      !token && dispatch(fetchGetTempCartData(cartId));
    }
  }, [token]);

  // set formik initial values
  const initialValues = {
    userName: userName ? userName : "",
    password: password ? password : "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    userName: Yup.string().email("Invalid userName").required("Required *"),
    password: Yup.string().required("Required *"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchLogin(values));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeLogin());
    }, 10000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSuccMsg("");
    setErrMsg("");
  }, []);

  useEffect(() => {
    if (successMessage) {
      setErrMsg("");
    }
  }, [successMessage]);

  return (
    <div className="relative h-full">
      <Helmet>
        <title>Login | Bidyl</title>
      </Helmet>
      <div className="pt-28 mb-20">
        {twoFactorCheck && (
          <TwoFactor
            setTwoFactorCheck={setTwoFactorCheck}
            redirectUrl={redirectUrl}
          />
        )}

        {succMsg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-green-500 text-white"
            message={succMsg}
            setMessage={setSuccMsg}
          />
        )}

        {authMsg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-red-500 text-white"
            message={authMsg}
            setMessage={setAuthMsg}
          />
        )}

        {signupMsg && (
          <Message
            className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-green-500 text-white"
            message={signupMsg}
            setMessage={setSignupMsg}
          />
        )}

        {errMsg && (
          <Message
            className="border rounded-md w-fit mt-6 px-16 flex justify-between gap-x-8 py-2.5 bg-red-500 text-white"
            message={errMsg}
            setMessage={setErrMsg}
          />
        )}

        <div className=" w-11/12 md:w-9/12 xl:w-1/2 container mx-auto mt-10">
          <Header
            text="Welcome"
            message="Back"
            info="You can log into your bidyl account"
          />

          <div className="text-center w-full sm:w-9/12 xl:w-1/2 container mx-auto mt-10">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className=" w-full">
                    <FormikControl
                      label="Email Address"
                      placeholder="Enter your email"
                      control="input"
                      name="userName"
                      type="email"
                    />
                    <div className="mt-5">
                      <FormikControl
                        label="Password"
                        placeholder="Enter your password"
                        control="password"
                        name="password"
                        type={`${showPassword ? "text" : "password"}`}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                      />
                    </div>
                    <div className="flex justify-between mt-3 items-center">
                      <div className="flex items-center gap-x-2">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          onClick={() => {
                            localStorage.setItem(
                              "userName",
                              formik.values.userName
                            );
                            localStorage.setItem(
                              "password",
                              formik.values.password
                            );
                          }}
                        />
                        <p className="text-sm text-secondary">Remember me</p>
                      </div>
                      <Link to="/forget-password">
                        <p className="text-primary text-sm font-bold hover:underline cursor-pointer">
                          Forgotten Password?
                        </p>
                      </Link>
                    </div>

                    <div className="mt-5">
                      <Button
                        type="submit"
                        className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                        value="Login"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="flex items-center justify-center gap-x-2 mt-3">
              <p>Don't have an account?</p>
              <Link to="/sign-up">
                <p className="text-primary font-bold">Sign up</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {twoFactorCheck && (
        <div
          className="w-full absolute top-0 h-screen bg-black z-30 bg-opacity-10"
          onClick={() => {
            setTwoFactorCheck(false);
            Cookies.remove("token");
            window.location.reload(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default Login;
