import React from "react";

const Body = ({ text }) => {
  return (
    <div>
      <h6 className="text-lg text-secondary mt-8">{text}</h6>
    </div>
  );
};

export default Body;
