import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../../Component/Loading/Loading";
import Message from "../../Component/Message/Message";
import {
  fetchGetMyOwnInfo,
  removeProfileData,
} from "../../Redux/Profile/action";
import BiddingPasswordModalPopup from "./PopupModal/BiddingPasswordModalPopup";
import DisableTwoFactor from "./PopupModal/DisableTwoFactor";
import Header from "./Header";
import HistoryModal from "./HistoryModal";
import ImagePopup from "./ImagePopup";
import LocationDetailModalPopup from "./PopupModal/LocationDetailModalPopup";
import MyBidsModal from "./MyBidsModal";
import PasswordModalPopup from "./PopupModal/PasswordModalPopup";
import PersonalDetailModalPopup from "./PopupModal/PersonalDetailModalPopup";
import ProfileModal from "./ProfileModal";
import SecurityModal from "./SecurityModal";
import TwoFactorModalPopup from "./PopupModal/TwoFactorModalPopup";
import RemoveBiddingPassword from "./PopupModal/RemoveBiddingPassword";
import DisableTwoFactorOTP from "../../Component/TwoFactor/DisableTwoFactorOTP";
import EnableTwoFactorOTP from "../../Component/TwoFactor/EnableTwoFactorOTP";
import EditBiddingPasswordModalPopup from "./PopupModal/EditBiddingPasswordModalPopup";
import { Helmet } from "react-helmet";

const Profile = () => {
  const [profileModal, setProfileModal] = useState(true);
  const [myBidsModal, setMyBidsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);

  // popup modal states
  const [personalDetailPopupModal, setPersonalDetailPopupModal] =
    useState(false);
  const [locationDetailPopupModal, setLocationDetailPopupModal] =
    useState(false);
  const [imagePopupModal, setImagePopupModal] = useState(false);

  // bidding password popup modals
  const [passwordPopupModal, setPasswordPopupModal] = useState(false);
  const [addBiddingPasswordPopupModal, setAddBiddingPasswordPopupModal] =
    useState(false);
  const [editBiddingPasswordPopupModal, setEditBiddingPasswordPopupModal] =
    useState(false);
  const [removeBiddingPasswordPopupModal, setRemoveBiddingPasswordPopupModal] =
    useState(false);

  // 2-fa popup modals
  const [twoFactorPopupModal, setTwoFactorPopupModal] = useState(false);
  const [otpPopupModal, setOtpPopupModal] = useState(false);
  const [twoFactorSuccessPopupModal, setTwoFactorSuccessPopupModal] =
    useState(false);
  const [twoFactorDisablePopupModal, setTwoFactorDisablePopupModal] =
    useState(false);
  const [disableOtpPopupModal, setDisableOtpPopupModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");

  // retrieve cookie
  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);
  const myInfo = useSelector((state) => state.profile.myInfo, shallowEqual);
  const msg = useSelector((state) => state.profile.message, shallowEqual);
  const twoFactorMsg = useSelector(
    (state) => state.twoFactor.message,
    shallowEqual
  );

  const handleProfileModal = () => {
    setProfileModal(true);
    setMyBidsModal(false);
    setHistoryModal(false);
    setSecurityModal(false);
  };

  const handleMyBidsModal = () => {
    setMyBidsModal(true);
    setProfileModal(false);
    setHistoryModal(false);
    setSecurityModal(false);
    dispatch(removeProfileData());
  };

  const handleHistoryModal = () => {
    setHistoryModal(true);
    setProfileModal(false);
    setMyBidsModal(false);
    setSecurityModal(false);
  };

  const handleSecurityModal = () => {
    setSecurityModal(true);
    setProfileModal(false);
    setMyBidsModal(false);
    setHistoryModal(false);
  };

  // close backdrop function
  const handleClosePopup = () => {
    setPersonalDetailPopupModal(false);
    setLocationDetailPopupModal(false);
    setImagePopupModal(false);
    setTwoFactorPopupModal(false);
    setOtpPopupModal(false);
    setTwoFactorSuccessPopupModal(false);
    setTwoFactorDisablePopupModal(false);
    setDisableOtpPopupModal(false);
    setPasswordPopupModal(false);
    setAddBiddingPasswordPopupModal(false);
    setEditBiddingPasswordPopupModal(false);
    setRemoveBiddingPasswordPopupModal(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(fetchGetMyOwnInfo(token));
    setTimeout(() => {
      dispatch(removeProfileData());
    }, [2000]);
  }, [token]);

  useEffect(() => {
    if (twoFactorMsg || msg) {
      dispatch(fetchGetMyOwnInfo(token));
      setTimeout(() => {
        dispatch(removeProfileData());
      }, [2000]);
    }
  }, [twoFactorMsg, msg]);

  useEffect(() => {
    setSuccessMsg(msg);
  }, [msg]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!myInfo || loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-28 md:pt-40 relative">
      <Helmet>
        <title>Profile | Bidyl</title>
      </Helmet>
      {successMsg && (
        <Message
          className="border rounded-md w-fit px-7 flex justify-between gap-x-8 py-2.5 bg-green-500 text-white"
          message={successMsg}
          setMessage={setSuccessMsg}
        />
      )}
      <div className="w-11/12 xl:w-3/4 pb-20 mx-auto">
        <Header
          profileModal={profileModal}
          myBidsModal={myBidsModal}
          historyModal={historyModal}
          securityModal={securityModal}
          handleProfileModal={handleProfileModal}
          handleMyBidsModal={handleMyBidsModal}
          handleHistoryModal={handleHistoryModal}
          handleSecurityModal={handleSecurityModal}
        />
        <hr />
        {/* profile modal */}
        {profileModal && (
          <ProfileModal
            data={myInfo}
            setPersonalDetailPopupModal={setPersonalDetailPopupModal}
            setLocationDetailPopupModal={setLocationDetailPopupModal}
            setImagePopupModal={setImagePopupModal}
          />
        )}
        {/* mybids modal */}
        {myBidsModal && <MyBidsModal />}
        {/* order history modal */}
        {historyModal && <HistoryModal />}
        {/* security modal */}
        {securityModal && (
          <SecurityModal
            setTwoFactorModal={setTwoFactorPopupModal}
            setPasswordModal={setPasswordPopupModal}
            setAddBiddingPasswordModal={setAddBiddingPasswordPopupModal}
            setEditBiddingPasswordModal={setEditBiddingPasswordPopupModal}
            setTwoFactorDisablePopupModal={setTwoFactorDisablePopupModal}
            setRemoveBiddingPasswordPopupModal={
              setRemoveBiddingPasswordPopupModal
            }
            myInfo={myInfo}
          />
        )}

        {/* image popop modal */}
        {imagePopupModal && (
          <ImagePopup setImagePopupModal={setImagePopupModal} myInfo={myInfo} />
        )}

        {/* profile detail Popup */}
        {personalDetailPopupModal && (
          <PersonalDetailModalPopup
            data={myInfo}
            setPersonalDetailPopupModal={setPersonalDetailPopupModal}
          />
        )}
        {/* location detail popup */}
        {locationDetailPopupModal && (
          <LocationDetailModalPopup
            data={myInfo}
            setLocationDetailPopupModal={setLocationDetailPopupModal}
          />
        )}

        {/* change password modal popup */}
        {passwordPopupModal && (
          <PasswordModalPopup setPasswordModal={setPasswordPopupModal} />
        )}
      </div>

      {/* add bidding password popup modal */}
      {addBiddingPasswordPopupModal && (
        <BiddingPasswordModalPopup
          setBiddingPasswordModal={setAddBiddingPasswordPopupModal}
        />
      )}

      {/* change bidding password modal popup */}
      {editBiddingPasswordPopupModal && (
        <EditBiddingPasswordModalPopup
          setBiddingPasswordModal={setEditBiddingPasswordPopupModal}
        />
      )}

      {/* remove bidding password popup */}
      {removeBiddingPasswordPopupModal && (
        <RemoveBiddingPassword
          setRemoveBiddingPasswordPopupModal={
            setRemoveBiddingPasswordPopupModal
          }
        />
      )}

      {/* two factor modal popup */}
      {twoFactorPopupModal && (
        <TwoFactorModalPopup
          setTwoFactorModal={setTwoFactorPopupModal}
          setOtpPopupModal={setOtpPopupModal}
        />
      )}
      {/* Otp popup Modal */}
      {otpPopupModal && (
        <EnableTwoFactorOTP
          setOtpPopupModal={setOtpPopupModal}
          myInfo={myInfo}
          setTwoFactorSuccessPopupModal={setTwoFactorSuccessPopupModal}
        />
      )}

      {/* two factor disable popup modal */}
      {twoFactorDisablePopupModal && (
        <DisableTwoFactor
          setTwoFactorDisablePopupModal={setTwoFactorDisablePopupModal}
          setDisableOtpPopupModal={setDisableOtpPopupModal}
        />
      )}

      {/* disable otp popup modal */}
      {disableOtpPopupModal && (
        <DisableTwoFactorOTP
          setDisableOtpPopupModal={setDisableOtpPopupModal}
          myInfo={myInfo}
        />
      )}

      {/* backdrop */}
      {personalDetailPopupModal ||
      locationDetailPopupModal ||
      imagePopupModal ||
      twoFactorPopupModal ||
      otpPopupModal ||
      twoFactorSuccessPopupModal ||
      twoFactorDisablePopupModal ||
      disableOtpPopupModal ||
      passwordPopupModal ||
      addBiddingPasswordPopupModal ||
      editBiddingPasswordPopupModal ||
      removeBiddingPasswordPopupModal ? (
        <div
          className="absolute top-0 w-full z-30 h-full bg-black bg-opacity-10"
          onClick={handleClosePopup}
        ></div>
      ) : null}
    </div>
  );
};

export default Profile;
