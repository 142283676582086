import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const howItWorksLoading = () => ({
  type: ActionTypes.HOW_IT_WORKS_LOADING,
});

export const removeData = () => ({
  type: ActionTypes.REMOVE_HOW_IT_WORKS_DATA,
});

export const fetchGetHowItWorksData = () => (dispatch) => {
  dispatch(howItWorksLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/admin/how-it-works/get-all`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_HOW_iT_WORKS_DATA,
        payload: data,
      });
    });
};
