import React, { useEffect, useState } from "react";
import CheckoutModule from "./CheckoutModule";
import Header from "./Header";
import { Helmet } from "react-helmet";

const Checkout = () => {
  const [showCountry, setShowCountry] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative">
      <Helmet>
        <title>Checkout | Bidyl</title>
      </Helmet>
      <div className="w-11/12 xl:w-3/4 mx-auto pt-28 border md:pt-40 pb-20">
        <Header />

        <div className="mt-10">
          <CheckoutModule
            showCountry={showCountry}
            setShowCountry={setShowCountry}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
