import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import close from "../../Assets/Icons/Close_MD.svg";
import Button from "../../Component/Button/Button";
import Loading from "../../Component/Loading/Loading";
import { fetchGetCategory } from "../../Redux/Category/action";
import {
  fetchGetProductByCountry,
  fetchGetProductByCountryUser,
  fetchSearchProductByCategory,
  fetchSearchProductByCategoryUser,
} from "../../Redux/Product/action";
import Cookies from "js-cookie";
import arrowDown from "../../Assets/Icons/arrow-down.svg";
import { fetchGetAllCountry } from "../../Redux/ContactUs/action";

const Filter = ({ showFilter, setShowFilter, categoryId }) => {
  const [searchValue, setSearchValue] = useState("");
  const [showCountry, setShowCountry] = useState(false);
  const [country, setCountry] = useState("");

  console.log("show country", showCountry);

  // retieveing token
  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const categories = useSelector(
    (state) => state.categories.category,
    shallowEqual
  );

  const countries = useSelector(
    (state) => state.contactus.countries,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetAllCountry(country));
  }, [dispatch, country]);

  // store the country in state
  const onClickDropdownOption = (country) => {
    setCountry(country);
    setShowCountry(false);
  };

  //   handle close filter
  const handleClose = () => {
    setShowFilter(false);
  };

  // search button function
  const handleSearch = () => {
    if (searchValue) {
      token &&
        dispatch(
          fetchSearchProductByCategoryUser(searchValue, categoryId, token)
        );
      !token && dispatch(fetchSearchProductByCategory(searchValue, categoryId));
    }
  };

  // country search button
  const handleSearchCountry = () => {
    if (country) {
      token
        ? dispatch(fetchGetProductByCountryUser(country, token))
        : dispatch(fetchGetProductByCountry(country));
    }
  };

  useEffect(() => {
    dispatch(fetchGetCategory());
  }, [dispatch]);

  return (
    <div
      className={` bg-white fixed left-0 top-0 h-full w-11/12 sm:w-1/2 lg:w-2/5 2xl:w-1/4 z-50 px-7 py-5 ease-in-out duration-500 overflow-y-auto ${
        showFilter ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="h-24 flex justify-between items-center">
        <h4 className="font-bold text-3xl">Filters</h4>
        <div
          className="flex justify-end mt-1 h-10 w-10 cursor-pointer"
          onClick={handleClose}
        >
          <img src={close} alt="close" className="h-full w-full" />
        </div>
      </div>

      {/* auction Search */}
      <div className="border rounded-xl mt-12 p-5">
        <h6 className="font-bold text-xl">Auction Search</h6>
        <div className="mt-5 py-1 rounded-md border">
          <input
            type="text"
            placeholder="Search here..."
            className="w-full py-2 px-3 outline-none"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div
          className="bg-primary text-white cursor-pointer rounded-md px-7 w-full text-center py-3.5 mt-7"
          onClick={handleSearch}
        >
          <Button value="Search" />
        </div>
      </div>

      {/* Categories */}
      <div className="border rounded-xl mt-12">
        <h6 className="font-bold text-xl p-5">Categories</h6>
        {loading ? (
          <div className="w-full h-screen">
            <Loading />
          </div>
        ) : (
          categories.map((category) => {
            return (
              <Link
                to={`/products?index=${category.id}&category=${category.categoryName}`}
                key={category.id}
              >
                <div className="mt-2 py-2 flex justify-between font-medium text-secondary px-7 text-sm cursor-pointer">
                  <p>{category.categoryName}</p>

                  <p>({category.totalProductCount})</p>
                </div>
              </Link>
            );
          })
        )}
      </div>

      {/* Country Search */}
      <div
        className={`border rounded-xl mt-12 pb-5 ${
          countries.length < 5 ? "h-72" : "h-96"
        } ${showCountry ? "" : "h-10"}`}
      >
        <h6 className="font-bold text-xl p-5">Country Search</h6>
        <div className="w-full px-7">
          <div
            className="border border-gray-300 w-full cursor-pointer p-1.5 text-base rounded-lg outline-gray-300 flex justify-between"
            onClick={() => setShowCountry(!showCountry)}
          >
            <input
              type="text"
              className="w-full py-1 outline-none px-3 cursor-pointer"
              placeholder="Search Countries"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <img src={arrowDown} alt="arrow" />
          </div>

          {/* country search button */}
          {!showCountry && (
            <div
              className="bg-primary text-white cursor-pointer rounded-md px-7 w-full text-center py-3.5 mt-7"
              onClick={handleSearchCountry}
            >
              <Button value="Search" />
            </div>
          )}

          {showCountry ? (
            <div
              className={`absolute border w-8/12 mt-1 rounded-md border-gray-400 mb-5 bg-white shadow-md z-50 overflow-auto ${
                countries.length < 5 ? "h-fit" : "h-56"
              }`}
            >
              {countries?.map((item) => {
                return (
                  <div
                    className=" cursor-pointer p-3 hover:bg-gray-200 text-sm"
                    key={item.code}
                    onClick={(e) => {
                      return (
                        e.preventDefault(), onClickDropdownOption(item.name)
                      );
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Filter;
