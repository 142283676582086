export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const GET_PRODUCT_BY_CATEGORY_USER = "GET_PRODUCT_BY_CATEGORY_USER";
export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const GET_PRODUCT_BY_ID_USER = "GET_PRODUCT_BY_ID_USER";
export const PLACE_A_BID = "PLACE_A_BID";
export const FAIL_TO_PLACE_BID = "FAIL_TO_PLACE_BID";
export const PLACE_AUTO_BID = "PLACE_AUTO_BID";
export const FAIL_PLACE_AUTO_BID = "FAIL_PLACE_AUTO_BID";
export const SEARCH_PRODUCT_BY_CATEGORIES = "SEARCH_PRODUCT_BY_CATEGORIES";
export const SEARCH_PRODUCT_BY_CATEGORIES_USER =
  "SEARCH_PRODUCT_BY_CATEGORIES_USER";
export const GET_PRODUCT_BY_COUNTRY = "GET_PRODUCT_BY_COUNTRY";
export const GET_PRODUCT_BY_COUNTRY_USER = "GET_PRODUCT_BY_COUNTRY_USER";
export const GET_BIDS_ON_PRODUCT = "GET_BIDS_ON_PRODUCT";
export const REMOVE_VIEWED_PRODUCT = "REMOVE_VIEWED_PRODUCT";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
