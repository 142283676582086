import React from "react";
import bidyl from "../../../Assets/Logo/bidyl.png";

const Header = ({ text, message, info, info2 }) => {
  return (
    <>
      <div className="flex justify-center">
        <img src={bidyl} alt="bidyl" className="w-36 h-20 md:w-40 md:h-24" />
      </div>
      <div className="mt-3 text-center ">
        <h1 className="font-bold text-3xl">
          {text} <span className="text-primary">{message}</span>
        </h1>
        <p className="text-base text-secondary mt-3">{info}</p>
        <p className="text-base text-secondary mt-1">{info2}</p>
      </div>
    </>
  );
};

export default Header;
