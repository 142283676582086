import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "../../Component/Button/Button";
import Loading from "../../Component/Loading/Loading";
import Image from "../../Component/UploadImage/Image";
import {
  fetchChangeProfile,
  removeProfileData,
} from "../../Redux/Profile/action";

const ImagePopup = ({ setImagePopupModal, myInfo }) => {
  // retrieve token
  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);
  const initialValues = myInfo || {
    image: "",
  };

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(removeProfileData());
    const formData = new FormData();
    formData.append("image", values.image);
    dispatch(fetchChangeProfile(formData, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeProfileData());
    }, 5000);

    !loading && setImagePopupModal(false);
  };

  if (loading || !myInfo) {
    return (
      <div className="w-full h-full">
        <Loading />
      </div>
    );
  }
  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-1/2 xl:w-1/3 pt-10 pb-16 md:left-1/4 xl:left-1/3">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-3xl text-primary text-center mb-8">
          Change Profile{" "}
        </h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Image
                onChange={(e) =>
                  formik.setFieldValue("image", e.target.files[0])
                }
                file={formik.values.image}
              />

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setImagePopupModal(false)}
                >
                  <Button value="Cancel" />
                </div>

                <div className="border w-full rounded-2xl bg-primary cursor-pointer text-white font-medium py-4 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ImagePopup;
