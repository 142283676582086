import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../Component/Button/Button";
import FormikControl from "../../Component/Formik/FormikControl";
import arrowLeft from "../../Assets/Icons/Arrow_Right_LG.svg";
import { Link, matchPath, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllCountry } from "../../Redux/ContactUs/action";
import arrowDown from "../../Assets/Icons/arrow-down.svg";
import {
  fetchAddBillingInfo,
  fetchAddTempBillingInfo,
  removeTransactionDetail,
} from "../../Redux/Transaction/action";
import Cookies from "js-cookie";
import Message from "../../Component/Message/Message";
import Redirecting from "../../Component/Loading/Redirecting";

const CheckoutModule = ({ showCountry, setShowCountry }) => {
  const [msg, setMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const cartId = localStorage.getItem("cartId");

  const { pathname } = useLocation();
  const pathroad = matchPath("/checkout", pathname);

  const token = Cookies.get("token");

  const countries = useSelector(
    (state) => state.contactus.countries,
    shallowEqual
  );

  const redirectUrl = useSelector(
    (state) => state.transaction.url,
    shallowEqual
  );

  const message = useSelector(
    (state) => state.transaction.message,
    shallowEqual
  );
  const errMessage = useSelector(
    (state) => state.transaction.errMsg,
    shallowEqual
  );

  useEffect(() => {
    setMsg(message);
  }, [message]);

  useEffect(() => {
    setErrMsg(errMessage);
  }, [errMessage]);

  useEffect(() => {
    dispatch(fetchGetAllCountry(country));
  }, [dispatch, country]);

  const handleShowCountry = () => {
    country && country.length && setShowCountry(true);
    !country && !country.length && setShowCountry(!showCountry);
  };

  // store the country in state
  const onClickDropdownOption = (country) => {
    setCountry(country);
    const data = countries.find((item) => item.name === country);
    setCountryCode(data.calling_code);
    setShowCountry(false);
  };
  // set formik initial values
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    state: "",
    zipCode: "",
    paymentMethod: "",
    transferEmail: "",
  };

  // check formik validation
  const validationSchema = token
    ? Yup.object().shape({
        firstName: Yup.string().required("Required *"),
        lastName: Yup.string().required("Required *"),
        email: Yup.string().email("Invalid userName").required("Required *"),
        state: Yup.string().required("Required *"),
        zipCode: Yup.string().required("Required *"),
      })
    : Yup.object().shape({
        firstName: Yup.string().required("Required *"),
        lastName: Yup.string().required("Required *"),
        email: Yup.string().email("Invalid userName").required("Required *"),
        transferEmail: Yup.string()
          .email("Invalid Email")
          .required("Required *"),
        state: Yup.string().required("Required *"),
        zipCode: Yup.string().required("Required *"),
      });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    // !countryCode && !countryCode.length && setErrMsg("Please Select a country");
    const data = {
      ...values,
      country,
      callingCode: countryCode,
      paymentMethod,
    };

    !token && dispatch(fetchAddTempBillingInfo(data, cartId));
    token && dispatch(fetchAddBillingInfo(data, token));

    setRedirect(true);

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeTransactionDetail());
    }, [2000]);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  if (pathroad && redirect) {
    return (
      <div className="w-full">
        <Redirecting />
      </div>
    );
  }

  return (
    <>
      {message && (
        <Message
          message={msg}
          setMessage={setMsg}
          className="text-green-500 text-3xl font-bold"
        />
      )}

      {errMessage && (
        <Message
          message={errMsg}
          setMessage={setErrMsg}
          className="text-red-500 font-bold text-3xl"
        />
      )}

      {errMsg && (
        <Message
          message={errMsg}
          setMessage={setErrMsg}
          className="text-red-500 font-bold text-3xl"
        />
      )}

      <div className="w-11/12 md:w-3/4 lg:w-2/4 mx-auto mt-10">
        <h4 className="font-bold text-xl md:text-3xl text-center">
          Checkout Information
        </h4>
        <h5 className="text-center font-bold text-base md:text-2xl text-primary mt-10">
          Billing Informations
        </h5>

        <div className="mt-12 md:mt-16">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="flex gap-x-10 flex-col md:flex-row">
                  <div className=" w-full">
                    <FormikControl
                      label="First Name"
                      placeholder="first name"
                      control="input"
                      name="firstName"
                    />
                  </div>

                  <div className=" w-full mt-7 md:mt-0">
                    <FormikControl
                      label="Last Name"
                      placeholder="last name"
                      control="input"
                      name="lastName"
                    />
                  </div>
                </div>

                <div className=" w-full mt-7">
                  <FormikControl
                    label="Email Address"
                    placeholder="Enter email address"
                    control="input"
                    name="email"
                    type="email"
                  />
                </div>

                <div className="w-full mt-7">
                  <label className="pb-2 font-medium flex items-center justify-between">
                    Country
                  </label>
                  <div
                    className="border border-gray-300 w-full cursor-pointer p-1.5 text-base rounded-lg outline-gray-300 flex justify-between"
                    onClick={handleShowCountry}
                  >
                    <input
                      type="text"
                      className="w-full py-1 outline-none px-3 cursor-pointer"
                      placeholder="Search Countries"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        setShowCountry(true);
                      }}
                    />
                    <img src={arrowDown} alt="arrow" />
                  </div>

                  {showCountry ? (
                    <div
                      className={`absolute border w-3/4 md:w-[37%] mt-1 rounded-md border-gray-400 mb-5 bg-white shadow-md z-50 overflow-auto ${
                        countries.length < 5 ? "h-fit" : "h-56"
                      }`}
                    >
                      {countries?.map((item) => {
                        return (
                          <div
                            className=" cursor-pointer p-3 hover:bg-gray-200 text-sm"
                            key={item.code}
                            onClick={(e) => {
                              return (
                                e.preventDefault(),
                                onClickDropdownOption(item.name)
                              );
                            }}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>

                <div className="flex gap-x-10 mt-7 flex-col md:flex-row">
                  <div className=" w-full">
                    <FormikControl
                      label="State / City"
                      placeholder="state / city"
                      control="input"
                      name="state"
                    />
                  </div>

                  <div className=" w-full mt-7 md:mt-0">
                    <FormikControl
                      label="Zip / Postal Code"
                      placeholder="zip / postal code"
                      control="input"
                      name="zipCode"
                    />
                  </div>
                </div>

                {!token && (
                  <div className="w-full mt-7">
                    <FormikControl
                      label="Transfer Email Address"
                      placeholder="Enter transfer email address"
                      control="input"
                      name="transferEmail"
                      type="email"
                    />
                  </div>
                )}

                <div className=" w-full border rounded-lg px-3 mt-9 py-4 ">
                  <div className="flex items-center gap-x-4">
                    <input
                      type="radio"
                      name="payment"
                      value="CREDIT_CARD"
                      className="border border-gray-300 w-4 h-4 text-center uppercase rounded-md outline-gray-300"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="font-medium text-sm">Credit Card</label>
                  </div>
                  <p className="mt-3 text-sm" style={{ color: "#8B9DAD" }}>
                    Safe money transfer using your bank account. Visa, Maestro,
                    Discover, American Express
                  </p>
                </div>

                <div className=" w-full border rounded-lg px-3 mt-9 py-4 ">
                  <div className="flex items-center gap-x-4">
                    <input
                      type="radio"
                      name="payment"
                      value="STRIPE"
                      className="border border-gray-300 w-4 h-4 text-center uppercase rounded-md outline-gray-300"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="font-medium text-sm">Stripe</label>
                  </div>
                  <p className="mt-3 text-sm" style={{ color: "#8B9DAD" }}>
                    You will be redirected to Stripe website to complete your
                    purchase securely.
                  </p>
                </div>

                <div className=" w-full border rounded-lg px-3 mt-9 py-4 ">
                  <div className="flex items-center gap-x-4">
                    <input
                      type="radio"
                      name="payment"
                      value="PAYPAL"
                      className="border border-gray-300 w-4 h-4 text-center uppercase rounded-md outline-gray-300"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="font-medium text-sm">Paypal</label>
                  </div>
                  <p className="mt-3 text-sm" style={{ color: "#8B9DAD" }}>
                    You will be redirected to PayPal website to complete your
                    purchase securely.
                  </p>
                </div>

                <div className="mt-12 flex items-center gap-x-10">
                  <Link
                    to="/my-cart"
                    className="flex items-center gap-x-4 w-full cursor-pointer"
                  >
                    <img src={arrowLeft} alt="left arrow" />
                    <p className="font-medium text-sm md:text-base text-primary">
                      Back to Shopping Cart
                    </p>
                  </Link>

                  <div className="w-full">
                    <Button
                      type="submit"
                      className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                      value="Place Order"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CheckoutModule;
