export const TWO_FACTOR_LOADING = "TWO_FACTOR_LOADING";
export const ENABLE_TWO_FACTOR = "ENABLE_TWO_FACTOR";
export const FAIL_ENABLE_TWO_FACTOR = "FAIL_ENABLE_TWO_FACTOR";
export const VERIFY_ENABLE_TWO_FACTOR = "VERIFY_ENABLE_TWO_FACTOR";
export const FAIL_VERIFY_TWO_FACTOR = "FAIL_VERIFY_TWO_FACTOR";
export const DISABLE_TWO_FACTOR = "DISABLE_TWO_FACTOR";
export const FAIL_DISABLE_TWO_FACTOR = "FAIL_DISABLE_TWO_FACTOR";
export const DISABLE_TWO_FACTOR_CODE = "DISABLE_TWO_FACTOR_CODE";
export const FAIL_DISABLE_TWO_FACTOR_CODE = "FAIL_DISABLE_TWO_FACTOR_CODE";
export const REMOVE_TWO_FACTOR = "REMOVE_TWO_FACTOR";
