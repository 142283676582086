import React, { useEffect, useState } from "react";
import close from "../../Assets/Icons/Close_MD.svg";
import cart from "../../Assets/Icons/shopping-cart.svg";
import heart from "../../Assets/Icons/heart.svg";
import arrowdown from "../../Assets/Icons/arrow-down.svg";
import instagram from "../../Assets/Logo/Instagram-Color.png";
import facebook from "../../Assets/Logo/Facebook-Color.png";
import twitter from "../../Assets/Logo/Twitter-Color.png";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetCategory } from "../../Redux/Category/action";
import {
  fetchGetCartData,
  fetchGetTempCartData,
} from "../../Redux/Cart/action";
import { fetchGetOwnWishlist } from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";

const Menu = ({ toggle, setToggle, showCategory, setShowCategory }) => {
  // retrieve cookie
  const token = Cookies.get("token");

  const category = useSelector(
    (state) => state.categories.category,
    shallowEqual
  );

  const cartData = useSelector((state) => state.cart.orders, shallowEqual);
  const tempCartData = useSelector(
    (state) => state.cart.tempOrders,
    shallowEqual
  );
  const myWishData = useSelector(
    (state) => state.wishlist.totalData,
    shallowEqual
  );

  const pages = useSelector((state) => state.pages.pages, shallowEqual);
  const facebookLink = useSelector(
    (state) => state.footer.facebook,
    shallowEqual
  );
  const instagramLink = useSelector(
    (state) => state.footer.instagram,
    shallowEqual
  );
  const twitterLink = useSelector(
    (state) => state.footer.twitter,
    shallowEqual
  );

  const cartId = localStorage.getItem("cartId");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleClose = () => {
    setToggle(false);
  };

  // logout function
  const handleLogout = () => {
    setToggle(false);
    Cookies.remove("token");
    Cookies.remove("biddingPassword");
    window.location.reload(false);
  };

  const handleClickCategory = (id, name) => {
    navigate(`/products?index=${id}&category=${name}`);
    setShowCategory(false);
    setToggle(false);
  };

  useEffect(() => {
    dispatch(fetchGetCategory());
  }, [dispatch]);

  useEffect(() => {
    token && dispatch(fetchGetCartData(token));
    token && dispatch(fetchGetOwnWishlist(1, token));
  }, [token]);

  useEffect(() => {
    !token && cartId && dispatch(fetchGetTempCartData(cartId));
  }, [cartId]);

  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-3/4 md:w-2/6 lg:w-1/5 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        toggle ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="h-24 flex justify-end items-center px-5">
        <div
          className="flex justify-end h-10 w-10 cursor-pointer"
          onClick={handleClose}
        >
          <img src={close} alt="close" className="h-full w-full" />
        </div>
      </div>
      <div className="mt-2">
        <div className="px-7">
          <h6
            className="py-2 font-bold cursor-pointer w-full"
            onMouseEnter={() => setShowCategory(false)}
            onClick={() => setToggle(false)}
          >
            <Link to="/buy-bids">Buy Bids</Link>
          </h6>
          <h6
            className="py-2 font-bold cursor-pointer"
            onMouseEnter={() => setShowCategory(false)}
            onClick={() => setToggle(false)}
          >
            <Link to="/send-bids">Send Bids</Link>
          </h6>
          <Link to="/categories">
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onMouseEnter={() => setShowCategory(!showCategory)}
            >
              <h6 className="py-2 font-bold ">Categories</h6>
              <img src={arrowdown} alt="down arrow" />
            </div>
          </Link>

          {showCategory &&
            category.map((category) => {
              return (
                <h6
                  className="text-sm cursor-pointer mt-3 font-medium"
                  key={category.id}
                  onClick={() =>
                    handleClickCategory(category.id, category.categoryName)
                  }
                >
                  {category.categoryName}
                </h6>
              );
            })}
        </div>
        <hr className="mt-4" />
        <div className="px-7 mt-2">
          {pages.aboutUs && (
            <h6
              className="py-2 font-bold cursor-pointer"
              onClick={() => setToggle(false)}
              onMouseEnter={() => setShowCategory(false)}
            >
              <Link to="/about-us">About BIDYL</Link>
            </h6>
          )}

          {pages.charity && (
            <h6
              className="py-2 font-bold cursor-pointer"
              onClick={() => setToggle(false)}
              onMouseEnter={() => setShowCategory(false)}
            >
              <Link to="/charity-partner">Charity</Link>
            </h6>
          )}

          {pages.howItWorks && (
            <h6
              className="py-2 font-bold cursor-pointer"
              onClick={() => setToggle(false)}
              onMouseEnter={() => setShowCategory(false)}
            >
              <Link to="/how-it-works">How it Works</Link>
            </h6>
          )}

          {pages.ourWinners && (
            <h6
              className="py-2 font-bold cursor-pointer"
              onClick={() => setToggle(false)}
              onMouseEnter={() => setShowCategory(false)}
            >
              <Link to="/winners">Our Winners</Link>
            </h6>
          )}
        </div>
        <hr className="mt-4" />
        {/* icons */}
        <div
          className="px-7 mt-6"
          onClick={() => setToggle(false)}
          onMouseEnter={() => setShowCategory(false)}
        >
          {token ? (
            <Link
              to="/my-cart"
              className="flex items-center gap-x-4 cursor-pointer"
            >
              <div className="relative w-fit px-2 py-1">
                <img src={cart} alt="shopping cart" className="w-7 h-7" />
                <span
                  className="absolute top-1 right-0 text-white text-sm bg-blue-300 rounded-full px-1.5"
                  style={{ background: "#23478F" }}
                >
                  {cartData?.length ? cartData?.length : "0"}
                </span>
              </div>

              <h1 className="font-bold">Cart</h1>
            </Link>
          ) : (
            <Link
              to="/user-cart"
              className="flex items-center gap-x-4 cursor-pointer"
            >
              <div className="relative w-fit px-2 py-1">
                <img src={cart} alt="shopping cart" className="w-7 h-7" />
                <span
                  className="absolute top-1 right-0 text-white text-sm bg-blue-300 rounded-full px-1.5"
                  style={{ background: "#23478F" }}
                >
                  {tempCartData?.length ? tempCartData?.length : "0"}
                </span>
              </div>

              <h1 className="font-bold">My Cart</h1>
            </Link>
          )}
        </div>
        <div
          className="px-7 mt-6 "
          onClick={() => setToggle(false)}
          onMouseEnter={() => setShowCategory(false)}
        >
          <Link
            to="/wish-list"
            className=" flex items-center gap-x-4 cursor-pointer"
          >
            <div className="relative w-fit px-2 py-1">
              <img src={heart} alt="shopping cart" className="w-7 h-7" />
              <span
                className="absolute top-1 right-0 text-white text-sm bg-blue-300 rounded-full px-1.5"
                style={{ background: "#23478F" }}
              >
                {token ? myWishData : "0"}
              </span>
            </div>

            <h1 className="font-bold">Wishlist</h1>
          </Link>
        </div>

        {/* buttons */}
        <div className="mt-5 px-7">
          {token ? (
            <>
              <div
                onClick={() => setToggle(false)}
                onMouseEnter={() => setShowCategory(false)}
              >
                <Link to="/my-profile">
                  <Button
                    className="bg-primary font-medium py-3 rounded-lg text-white w-full"
                    value="My Account"
                  />
                </Link>
              </div>

              <button
                className="border-primary border text-primary font-medium py-3 rounded-lg mt-4 w-full"
                onClick={handleLogout}
                onMouseEnter={() => setShowCategory(false)}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <div
                onClick={() => setToggle(false)}
                onMouseEnter={() => setShowCategory(false)}
              >
                <Link to="/sign-up">
                  <Button
                    className="bg-primary font-medium py-3 rounded-lg text-white w-full"
                    value="Sign Up"
                  />
                </Link>
              </div>

              <div
                onClick={() => setToggle(false)}
                onMouseEnter={() => setShowCategory(false)}
              >
                <Link to="/sign-in">
                  <Button
                    className="border-primary border text-primary font-medium py-3 rounded-lg mt-4 w-full"
                    value="Log In"
                  />
                </Link>
              </div>
            </>
          )}
        </div>

        {/* follow us */}
        <div
          className="px-7 mt-7 flex justify-between items-center"
          onMouseEnter={() => setShowCategory(false)}
        >
          <h6 className="font-bold">Follow Us</h6>
          <div className="flex items-center gap-x-4">
            <a href={facebookLink}>
              <img
                src={facebook}
                alt="Facebook"
                className="h-6 w-6 cursor-pointer"
              />
            </a>

            <a href={twitterLink}>
              <img
                src={twitter}
                alt="Twitter"
                className="h-6 w-6 cursor-pointer"
              />
            </a>

            <a href={instagramLink}>
              <img
                src={instagram}
                alt="Instagram"
                className="h-6 w-6 cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
