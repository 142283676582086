import React from "react";
import shop from "../../../Assets/Icons/shop.svg";

const VendorInfo = ({ product }) => {
  return (
    <div className="px-5">
      <div className="flex items-center mt-5">
        <div className="flex gap-x-5 w-60 ">
          <img src={shop} alt="vendor shop" className="w-5 h-5 md:w-8 md:h-8" />
          <p className="text-secondary text-sm md:text-lg">Store Name:</p>
        </div>
        <h6 className="font-bold text-sm md:text-xl">{product.storeName}</h6>
      </div>

      <div className="flex items-center mt-10">
        <div className="flex gap-x-5 w-60 ">
          <img src={shop} alt="vendor shop" className="w-5 h-5 md:w-8 md:h-8" />
          <p className="text-secondary text-sm md:text-lg">Vendor Name:</p>
        </div>
        <h6 className="font-bold text-sm md:text-xl">{product.vendorName}</h6>
      </div>

      <div className="flex items-center mt-10">
        <div className="flex gap-x-5 w-60">
          <img src={shop} alt="vendor shop" className="w-5 h-5 md:w-8 md:h-8" />
          <p className="text-secondary text-sm md:text-lg">Address:</p>
        </div>
        <h6 className="font-bold text-sm md:text-xl">{product.storeAddress}</h6>
      </div>
    </div>
  );
};

export default VendorInfo;
