import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "../../Component/Button/Button";
import CharityCard from "../../Component/Card/CharityCard";
import TitleContainer from "../../Component/Header/TitleContainer";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetActiveCharityPartners,
  fetchGetCharityPage,
} from "../../Redux/Charity/action";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const CharityPartner = () => {
  const loading = useSelector((state) => state.charity.isloading, shallowEqual);
  const partners = useSelector((state) => state.charity.partners, shallowEqual);
  const detailsPageData = useSelector(
    (state) => state.charity.detailsPageData,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchGetCharityPage());
    dispatch(fetchGetActiveCharityPartners());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Charity Partner | Bidyl</title>
      </Helmet>
      <div className="w-11/12 xl:w-1/2 mx-auto pt-28 md:pt-40">
        <TitleContainer
          title={detailsPageData.titleText}
          paragraph={detailsPageData.subText}
        />
      </div>

      <div
        className={`${
          detailsPageData.footer ? "mb-0" : "mb-10"
        } w-11/12 xl:w-3/4 mx-auto mt-5`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-10">
          {partners.map((data) => {
            return (
              <div className=" mt-14">
                <CharityCard data={data} />
              </div>
            );
          })}
        </div>
      </div>

      {/* live draw section */}
      {detailsPageData.footer && (
        <div className="mt-20 bg-primary">
          <div className="w-11/12 xl:w-1/2 mt-7 mx-auto flex justify-center items-center py-12 flex-col">
            <h4 className="font-bold text-xl xl:text-3xl text-white text-center">
              {detailsPageData.footer}
            </h4>
            <Link to="/categories">
              <Button
                className="bg-white text-primary font-bold text-base xl:text-xl px-10 text-center py-3.5 w-fit rounded-full mt-12"
                value="Enter Our Live Draw Now"
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CharityPartner;
