import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  titleText: "",
  subText: "",
  bidTitleText: "",
  bidSubText: "",
  winnerTitleText: "",
  winnerSubText: "",
  ourProductTitleText: "",
  ourProductSubText: "",
  bidPackages: [],
  productWinners: [],
  products: [],
  rating: [],
  carouselImages: [],
};

export const Home = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_DATA:
      return {
        ...state,
        isloading: false,
        success: action.payload?.otherData?.isSuccess,
        bidPackages: action.payload?.otherData?.bidPackages?.data,
        productWinners: action.payload?.otherData?.productWinners,
        products: action.payload?.otherData?.products?.data,
        titleText: action.payload?.titleText,
        subText: action.payload?.subText,
        bidTitleText: action.payload?.bidTitleText,
        bidSubText: action.payload?.bidSubText,
        winnerTitleText: action.payload?.winnerTitleText,
        winnerSubText: action.payload?.winnerSubText,
        ourProductTitleText: action.payload?.ourProductTitleText,
        ourProductSubText: action.payload?.ourProductSubText,
        rating: action.payload,
        carouselImages: action.payload?.images,
      };

    case ActionTypes.REMOVE_DATA:
      return {
        isloading: true,
        success: false,
        bidPackages: [],
        products: [],
        titleText: "",
        subText: "",
        bidTitleText: "",
        bidSubText: "",
        winnerTitleText: "",
        winnerSubText: "",
        rating: [],
        carouselImages: [],
      };
    default:
      return state;
  }
};
