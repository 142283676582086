import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TbMessageCircle2Filled } from "react-icons/tb";
import {
  fetchAddToCartFromTempCart,
  fetchGetCartData,
  fetchRemoveAllTempOrder,
} from "../../Redux/Cart/action";
import Cookies from "js-cookie";

const Header = ({ data, showMsg, setShowMsg }) => {
  const tempCartData = useSelector(
    (state) => state.cart.tempOrders,
    shallowEqual
  );

  const isAddedToCart = useSelector(
    (state) => state.cart.isAdded,
    shallowEqual
  );

  const token = Cookies.get("token");

  const cartId = localStorage.getItem("cartId");

  const dispatch = useDispatch();

  // handle add to users cart from temp cart
  const handleAddToCart = () => {
    const body = [];
    tempCartData.map((data) => {
      return body.push({
        bidPackageId: data.bidPackageId,
        quantity: data.quantity,
      });
    });

    console.log("body", body);
    const test = {
      items: body,
    };
    console.log("body test", test);
    dispatch(fetchAddToCartFromTempCart(test, token));
  };

  useEffect(() => {
    token && isAddedToCart && dispatch(fetchGetCartData(token));
    isAddedToCart && setShowMsg(false);
    isAddedToCart && dispatch(fetchRemoveAllTempOrder(cartId));
  }, [dispatch, token, isAddedToCart]);

  return (
    <div className="w-full h-20 relative">
      <div className="w-full h-full">
        <h3 className="font-bold text-2xl md:text-4xl">My Cart</h3>
        <div className="flex items-center gap-x-2 mt-2">
          <p className="text-secondary">You have</p>
          <h6 className="font-bold text-primary text-sm md:text-xl">
            {data?.length ? data?.length : "0"} Items
          </h6>
          <p className="text-secondary">in your cart</p>
        </div>
      </div>

      <div className="absolute top-0 right-0 flex items-center gap-x-5">
        {tempCartData.length
          ? showMsg && (
              <div className="bg-white border rounded-lg py-4 px-5 shadow-md z-30">
                <h1 className="font-bold text-sm md:text-xl text-red-500">
                  You have Item in your temporary Cart. Do you want to retrieve?
                </h1>

                <div className="w-full flex justify-between gap-x-10 mt-10 text-sm md:text-basee">
                  <button
                    className="w-full bg-red-500 text-white py-2 md:py-3 rounded-lg font-medium"
                    onClick={() => setShowMsg(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-full bg-primary text-white py-2 md:py-3 rounded-lg font-medium"
                    onClick={handleAddToCart}
                  >
                    Retrieve
                  </button>
                </div>
              </div>
            )
          : null}
        {token && tempCartData.length ? (
          <div
            className="relative cursor-pointer"
            onClick={() => setShowMsg(!showMsg)}
          >
            <TbMessageCircle2Filled
              size={45}
              className="text-primary cursor-pointer"
            />
            <p className="absolute top-2 right-5 font-medium text-lg text-white">
              1
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
