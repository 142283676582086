import React from "react";
import { BsCheck } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";

const Header = () => {
  return (
    <div className="flex items-center gap-x-5 w-full">
      <div className="flex items-center gap-x-10 w-full">
        <div className="flex flex-col gap-y-1 justify-center items-center">
          <div className="border rounded-full w-fit px-0.5 py-0.5 bg-primary">
            <BsCheck className="text-white text-xl" />
          </div>
          <h6 className="text-primary font-medium text-lg">Shopping Cart</h6>
        </div>
        <hr className="w-2/3 border border-primary" />
      </div>

      <div className="flex items-center gap-x-10 w-full">
        <div className="flex flex-col gap-y-1 justify-center items-center">
          <div className="border rounded-full w-fit px-0.5 py-0.5 bg-primary">
            <BsCheck className="text-white text-xl" />
          </div>

          <h6 className="text-primary font-medium text-lg">Checkout</h6>
        </div>
        <hr className="w-2/3 border border-primary" />
      </div>

      <div className="flex flex-col gap-y-1 justify-center items-center">
        <div className="border-2 rounded-full w-fit px-0.5 py-0.5 border-primary">
          <GoPrimitiveDot className="text-primary text-lg" />
        </div>
        <h6 className="text-primary font-medium text-lg">Checkout</h6>
      </div>
    </div>
  );
};

export default Header;
