import { Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Button from "../../../Component/Button/Button";
import FormikControl from "../../../Component/Formik/FormikControl";
import {
  fetchUpdateInformation,
  removeProfileData,
} from "../../../Redux/Profile/action";

const PersonalDetailModalPopup = ({ setPersonalDetailPopupModal, data }) => {
  // retrieve token
  const token = Cookies.get("token");

  const dispatch = useDispatch();
  // set formik initial values
  const initialValues = data || {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dob: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required *"),
    lastName: Yup.string().required("Required *"),
    email: Yup.string().email("Invalid email").required("Required *"),
  });

  //  handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchUpdateInformation(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeProfileData());
    }, 5000);
    setPersonalDetailPopupModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute top-32 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-3/4 xl:w-1/2 pt-10 pb-16 md:left-[17%] xl:left-1/4">
      <h4 className="font-bold text-3xl text-primary text-center">
        Personal Details
      </h4>

      <div className="w-11/12 lg:w-3/4 mx-auto mt-16">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="flex justify-between flex-wrap md:flex-nowrap items-center gap-x-14">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    placeholder="Your First Name"
                    control="input"
                    name="firstName"
                  />
                </div>

                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Last Name"
                    placeholder="Your Last Name"
                    control="input"
                    name="lastName"
                  />
                </div>
              </div>

              <div className="mt-6 w-full">
                <FormikControl
                  label="Email Address"
                  placeholder="Your Email Address"
                  control="input"
                  name="email"
                  type="email"
                />
              </div>

              <div className="w-full mt-6 flex flex-wrap md:flex-nowrap justify-between items-center gap-x-14">
                <div className="w-full">
                  <FormikControl
                    label="Phone Number"
                    placeholder="Your phone Number"
                    control="input"
                    name="phoneNumber"
                    type="phoneNumber"
                  />
                </div>
                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Date of Birth (DD/MM/YYYY)"
                    placeholder="Your Date of birth"
                    control="input"
                    name="dob"
                    type="date"
                  />
                </div>
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl cursor-pointer text-primary font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setPersonalDetailPopupModal(false)}
                >
                  <Button value="Cancel" />
                </div>

                <div className="border w-full rounded-2xl bg-primary cursor-pointer text-white font-medium py-4 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalDetailModalPopup;
