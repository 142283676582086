import React, { useState } from "react";
import closeIcon from "../../../../Assets/Icons/Close_MD.svg";
import dollarIcon from "../../../../Assets/Icons/dollar-circle.svg";
import informationIcon from "../../../../Assets/Icons/information.svg";
import Button from "../../../../Component/Button/Button";

const AutoBidPopup = ({
  setAutoBidPopup,
  setAutoBidPreview,
  minBidOnchangeHandle,
  numBidOnchangeHandler,
  bidIncrementOnchangeHandler,
  minimunBidValue,
  numOfBid,
  bidIncrement,
  setMinimumBidValue,
  setNumOfBid,
  setBidIncrement,
}) => {
  const handleAppyButton = () => {
    setAutoBidPopup(false);
    setAutoBidPreview(true);
    window.scrollTo(0, 0);
  };

  const handleClickResetButton = () => {
    setMinimumBidValue("");
    setBidIncrement("");
    setNumOfBid("");
  };

  return (
    <div className="absolute w-1/2 pb-10 pt-5 mx-auto shadow-lg left-1/4 border top-28 rounded-lg bg-white z-30">
      <div className="flex justify-between items-center px-10">
        <h4 className="text-primary font-bold text-3xl">Auto Bid</h4>
        <img
          src={closeIcon}
          alt="close Icon"
          className="w-10 h-10 cursor-pointer"
          onClick={() => setAutoBidPopup(false)}
        />
      </div>
      <p className="text-secondary px-10 py-1">
        Please fill out the form below to initiate auto bid
      </p>

      <hr className="mt-5" />

      <div className="w-3/4 mx-auto mt-10">
        <div className="flex justify-between items-center">
          <h6 className="text-xl font-bold">What is Auto Bid?</h6>
          <div className="flex items-center gap-x-1">
            <img
              src={informationIcon}
              alt="information Icon"
              className="w-8 h-8"
            />
            <p className="font-bold text-primary">Get Help</p>
          </div>
        </div>

        <div className=" mt-14">
          <div>
            <label className="font-medium">
              Minimum Bid Value{" "}
              <span className="text-sm text-secondary">
                (All prices in AUD)
              </span>
            </label>
            <div className="border rounded-xl py-2 mt-2 w-full flex gap-x-1 px-2 items-center">
              <img src={dollarIcon} className="w-5 h-5" alt="dollar icon" />
              <input
                type="number"
                className="w-full py-1 outline-none px-2"
                placeholder="Enter minimum Bid Value"
                value={minimunBidValue}
                onChange={minBidOnchangeHandle}
                min="0"
              />
            </div>
          </div>

          <div className="mt-8">
            <label className="font-medium">Number of Bids</label>
            <div className="border rounded-xl py-2 mt-2 w-full flex gap-x-1 px-2 items-center">
              <img src={dollarIcon} className="w-5 h-5" alt="dollar icon" />
              <input
                type="number"
                className="w-full py-1 outline-none px-2"
                placeholder="Enter the number of bids you want to place"
                value={numOfBid}
                onChange={numBidOnchangeHandler}
                min="1"
              />
            </div>
          </div>

          <div className="mt-8">
            <label className="font-medium">
              Bid Increment {""}
              <span className="text-sm text-secondary">
                (Bidding increases by this amount with each bid)
              </span>
            </label>
            <div className="border rounded-xl py-2 mt-2 w-full flex gap-x-1 px-2 items-center">
              <img src={dollarIcon} className="w-5 h-5" alt="dollar icon" />
              <input
                type="number"
                className="w-full py-1 outline-none px-2"
                placeholder="Enter the value by which you want to increase your bid"
                value={bidIncrement}
                onChange={bidIncrementOnchangeHandler}
                min="0.1"
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-between mt-10 gap-x-20">
            <div
              className="border rounded-2xl border-red-500 text-red-500 py-4 text-center w-full cursor-pointer"
              onClick={handleClickResetButton}
            >
              <Button value="Reset" />
            </div>
            <div
              className="border bg-primary text-white py-4 text-center w-full rounded-2xl cursor-pointer"
              onClick={handleAppyButton}
            >
              <Button value="Apply" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoBidPopup;
