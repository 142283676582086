import React from "react";

const Breadcrum = ({ title, subTitle }) => {
  return (
    <div>
      <p>
        {title} <span className="text-sm">/</span>
      </p>
      <h4 className="font-bold text-3xl py-2">{subTitle}</h4>
    </div>
  );
};

export default Breadcrum;
