export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN = "LOGIN";
export const VERIY_TWO_FACTOR = "VERIFY_TWO_FACTOR";
export const FAIL_VERIFY_TWO_FACTOR = "FAIL_VERIFY_TWO_FACTOR";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const FAIL_VERIFY_EMAIL = "FAIL_VERIFY_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_QR = "GET_QR";
export const LOGOUT = "LOGOUT";
export const REMOVE_LOGIN = "REMOVE_LOGIN";
