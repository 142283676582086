import React from "react";
import arrowRight from "../../Assets/Icons/arrow-right.svg";
import { Link } from "react-router-dom";
import NoImages from "../NoData/NoImages";

const CharityCard = ({ data }) => {
  return (
    <>
      <div className="w-full h-40 2xl:h-56">
        {data.image ? (
          <img
            src={data.image}
            alt="card img"
            className="w-full h-full rounded-lg"
          />
        ) : (
          <NoImages />
        )}
      </div>
      <div className="mt-5">
        <h5 className="font-bold text-base xl:text-2xl">{data.titleText}</h5>
        <p className="text-secondary text-sm xl:text-base mt-3 truncate">
          {data.subText}
        </p>
        <Link to={`/charity-section?index=${data.id}`}>
          <div className="flex items-center gap-x-3 mt-5 cursor-pointer">
            <h1 className="text-primary font-medium">Read More</h1>
            <img src={arrowRight} alt="right-arrow" className="h-5 w-5" />
          </div>
        </Link>
      </div>
    </>
  );
};

export default CharityCard;
