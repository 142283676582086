import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import tick from "../../../Assets/Icons/tick-circle.png";
import bidyl from "../../../Assets/Logo/bidyl.png";
import Button from "../../../Component/Button/Button";
import {
  fetchVerifyUserEmail,
  removeRegister,
} from "../../../Redux/Register/action";
import { Helmet } from "react-helmet";

const VerifyUser = () => {
  // storing the key using slice
  const location = useLocation();
  const params = location.search;
  const key = params.slice(5);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verify = useSelector((state) => state.signup.verify, shallowEqual);

  const handleVerify = () => {
    const data = {
      key: key,
    };
    dispatch(fetchVerifyUserEmail(data));
  };

  useEffect(() => {
    if (verify === true) {
      navigate("/sign-in?path=/");
      setTimeout(() => {
        dispatch(removeRegister());
      }, [10000]);
    }
  }, [verify]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto mt-2 w-11/12 md:w-9/12 xl:w-1/2 mb-20 pt-36">
      <Helmet>
        <title>Veriy User | Bidyl</title>
      </Helmet>
      <div className="flex justify-center">
        <img src={bidyl} alt="bidyl" className="w-40 h-24" />
      </div>
      <div className="flex justify-center mt-4">
        <img src={tick} alt="tick-check" className="w-16 h-16" />
      </div>
      <div className="mt-3 text-center ">
        <h3 className="font-bold text-3xl text-primary mt-3">
          Email Verification
        </h3>
        <p className="text-base text-secondary mt-4">Please Verify</p>
      </div>
      <div
        className="mt-7 w-full flex justify-center border-primary border text-primary font-medium px-8 text-lg py-3 rounded-2xl"
        onClick={handleVerify}
      >
        <Button type="submit" value="Login" />
      </div>
    </div>
  );
};

export default VerifyUser;
