import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  wallet: "",
  myInfo: "",
  message: "",
  errMsg: "",
  myBid: [],
  totalPage: "",
  totalData: "",
  myOrder: [],
};

export const Profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MY_WALLET:
      return {
        ...state,
        isloading: false,
        success: true,
        wallet: action.payload?.wallet,
      };

    case ActionTypes.GET_MY_OWN_INORMATION:
      return {
        ...state,
        isloading: false,
        success: true,
        myInfo: action.payload,
      };

    case ActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.FAIL_TO_CHANGE_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.UPDATE_INFORMATION:
      return {
        ...state,
        isloading: false,
        success: true,
        myInfo: action.payload,
      };

    case ActionTypes.FAIL_TO_CHANGE_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.CHANGE_PROFILE:
      return {
        ...state,
        isloading: false,
        success: true,
        myInfo: action.payload,
      };

    case ActionTypes.FAIL_CHANGE_PROFILE:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.GET_MY_BID:
      return {
        ...state,
        isloading: false,
        success: true,
        myBid: action.payload?.data,
        totalData: action.payload?.totalData,
        totalPage: action.payload?.totalPage,
      };

    case ActionTypes.SEARCH_MY_BID:
      return {
        ...state,
        isloading: false,
        success: true,
        myBid: action.payload?.data,
        totalData: action.payload?.totalData,
        totalPage: action.payload?.totalPage,
      };

    case ActionTypes.GET_MY_ORDER_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        myOrder: action.payload?.data,
        totalData: action.payload?.totalData,
        totalPage: action.payload?.totalPage,
      };

    case ActionTypes.SEARCH_ORDER_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        myOrder: action.payload?.data,
        totalData: action.payload?.totalData,
        totalPage: action.payload?.totalPage,
      };

    case ActionTypes.ADD_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.FAIL_ADD_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.CHANGE_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.FAIL_CHANGE_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.REMOVE_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload?.message,
      };

    case ActionTypes.FAIL_REMOVE_BIDDING_PASSWORD:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.REMOVE_PROFILE:
      return {
        ...state,
        isloading: "",
        success: false,
        message: "",
        errMsg: "",
        myBid: [],
        totalData: "",
        totalPage: "",
      };

    default:
      return state;
  }
};
