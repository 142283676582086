import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: "",
  termAndPolicy: "",
  effective: "",
  title: "",
};

export const TermAndPolicy = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TERMS_POLICY:
      return {
        ...state,
        isloading: false,
        success: action.payload?.isSuccess,
        termAndPolicy: action.payload?.text,
        effective: action.payload?.effective,
        title: action.payload?.textFor,
      };

    case ActionTypes.REMOVE_TERMS_POLICY:
      return {
        ...state,
        isloading: false,
        termAndPolicy: "",
        effective: "",
        title: "",
      };

    default:
      return state;
  }
};
