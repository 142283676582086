import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bidHammer from "../../Assets/Icons/bid-hammer.svg";
import { Link } from "react-router-dom";

const Carousel = ({ images }) => {
  const bannerSliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customImage">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <div className="w-full h-[400px] md:h-[768px] relative">
      <Slider {...settings} ref={bannerSliderRef}>
        {images &&
          images.map((banner) => {
            return (
              <div
                className="relative h-[400px] md:h-[768px] overflow-hidden"
                key={banner.id}
              >
                <div className="h-[400px] md:h-[768px]">
                  <img
                    src={banner.image}
                    alt={banner.name}
                    className="object-fill w-full h-full"
                  />
                </div>
                <div className="absolute top-0 bg-black z-40 bg-opacity-40 w-full h-full">
                  <div className="flex flex-col justify-center xl:-mt-20 items-center text-center w-full h-full text-white">
                    <h1 className="font-bold text-2xl text-center xl:text-5xl">
                      {banner.titleText}
                    </h1>
                    <p className="font-bold text-lg text-center xl:text-3xl py-7">
                      {banner.subText}
                    </p>
                  </div>
                </div>
                <div className="absolute top-0 w-full mt-20 transform translate-x-1/4 md:translate-x-1/3 xl:translate-x-40%  translate-y-1/2 h-full z-50">
                  <div className="flex justify-center items-center w-60 xl:w-80 bg-white py-4 px-12 xl:px-20 rounded-lg cursor-pointer">
                    <Link to={`/product?index=${banner.bidButtonId}`}>
                      <button className="font-medium text-sm xl:text-base text-primary flex gap-x-3 items-center">
                        <img src={bidHammer} alt="biding hammer" />
                        Place a Bid
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Carousel;
