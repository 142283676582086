import React from "react";
import facebookIcon from "../../../Assets/Logo/Facebook-Color.png";
import instagramIcon from "../../../Assets/Logo/Instagram-Color.png";
import twitterIcon from "../../../Assets/Logo/Twitter-Color.png";
import { shallowEqual, useSelector } from "react-redux";

const Breadcrum = ({ title, subTitle, productName }) => {
  const facebook = useSelector((state) => state.footer.facebook, shallowEqual);
  const twitter = useSelector((state) => state.footer.twitter, shallowEqual);
  const instagram = useSelector(
    (state) => state.footer.instagram,
    shallowEqual
  );
  return (
    <>
      <div className="flex items-center gap-x-3 text-secondary">
        <p>{title}</p>
        <p>/</p>
        <p>{subTitle}</p>
        <p>/</p>
      </div>
      <div className="flex justify-between mt-3 items-center w-full flex-wrap">
        <h4 className="font-bold text-xl md:text-2xl w-fit mt-4">
          {productName}
        </h4>
        <div className="flex items-center gap-x-4 w-fit mt-4">
          <p>Share:</p>
          <a href={facebook}>
            <img src={facebookIcon} alt="facebook" className="w-7 h-7" />
          </a>

          <a href={twitter}>
            <img src={twitterIcon} alt="twitter" className="w-7 h-7" />
          </a>

          <a href={instagram}>
            <img src={instagramIcon} alt="instagram" className="w-7 h-7" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Breadcrum;
