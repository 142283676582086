import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const wishlistLoading = () => ({
  type: ActionTypes.WISHLIST_LOADING,
});

export const removeWishlistData = () => ({
  type: ActionTypes.REMOVE_DATA,
});

export const fetchAddToWishlist = (productId, token) => (dispatch) => {
  dispatch(wishlistLoading());

  const config = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(`${BaseUrl}/bidyl/v1/api/wishList/add/${productId}`, config)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TO_WISHLIST,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_ADD,
        payload: e.response.data,
      });
    });
};

export const fetchRemoveFromWishlist = (id, token) => (dispatch) => {
  dispatch(wishlistLoading());
  return axios
    .delete(`${BaseUrl}/bidyl/v1/api/wishList/remove/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REMOVE_FROM_WISHLIST,
        payload: data,
      });
    });
};

export const fetchGetOwnWishlist = (page, token) => (dispatch) => {
  dispatch(wishlistLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/wishList/get-own?page=${page}&pageSize=2`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_OWN_WISHLIST,
        payload: data,
      });
    });
};

export const fetchGetOwnWishlistByCategory =
  (token, categoryId) => (dispatch) => {
    dispatch(wishlistLoading());
    return axios
      .get(
        `${BaseUrl}/bidyl/v1/api/wishList/get-own-by-category/${categoryId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_OWN_WISHLIST_BY_CATEGORY,
          payload: data,
        });
      });
  };

export const fetchSearchWishlist = (token, key) => (dispatch) => {
  dispatch(wishlistLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/wishList/search?keyword=${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEARCH_WISHLIST,
        payload: data,
      });
    });
};
