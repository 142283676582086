import React, { useEffect, useState } from "react";
import Button from "../../../../Component/Button/Button";
import FormikControl from "../../../../Component/Formik/FormikControl";
import { Form, Formik } from "formik";
import Header from "../../../Login/Register/Header";
import * as Yup from "yup";
import closeIcon from "../../../../Assets/Icons/Close_MD.svg";
import { fetchLogin, removeLogin } from "../../../../Redux/Login/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { removeRegister } from "../../../../Redux/Register/action";
import { fetchGetMyWallet } from "../../../../Redux/Profile/action";
import { fetchGetProductByIdUser } from "../../../../Redux/Product/action";
import Message from "../../../../Component/Message/Message";
import Cookies from "js-cookie";
import TwoFactor from "../../../../Component/TwoFactor/TwoFactor";

const LoginPopupModal = ({
  setAuthenticateCheck,
  setSignupPopup,
  product,
  twoFactorCheck,
  setTwoFactorCheck,
}) => {
  // state to show and hide password
  const [showPassword, setShowPassword] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [signupMsg, setSignupMsg] = useState("");
  const [authMsg, setAuthMsg] = useState(
    "You have been redirected, Please login! "
  );

  const dispatch = useDispatch();

  const twoFactor = useSelector((state) => state.login.twoFactor, shallowEqual);

  useEffect(() => {
    twoFactor && setTwoFactorCheck(true);
    twoFactor && setAuthenticateCheck(false);
    window.scrollTo(0, 0);
  }, [twoFactor]);

  useEffect(() => {
    if (twoFactorCheck === true) {
      setErrMsg("");
      setSuccMsg("");
      setAuthMsg("");
      setSignupMsg("");
    }
  }, [twoFactorCheck]);

  // retrieve cookie
  const token = Cookies.get("token");

  // retrieve successMessage from redux
  const successMessage = useSelector(
    (state) => state.login.message,
    shallowEqual
  );

  useEffect(() => {
    setSuccMsg(successMessage);
    successMessage && setAuthMsg("");
    successMessage &&
      setTimeout(() => {
        setAuthenticateCheck(false);
      }, [2000]);
  }, [successMessage]);

  // retrieve errorMessgae from redux
  const errMessage = useSelector((state) => state.login.errMsg, shallowEqual);

  useEffect(() => {
    setErrMsg(errMessage);
    errMessage && setAuthMsg("");
  }, [errMessage]);

  const signupMessage = useSelector(
    (state) => state.signup.message,
    shallowEqual
  );

  useEffect(() => {
    setSignupMsg(signupMessage);
    signupMessage && setAuthMsg("");
    signupMessage &&
      setTimeout(() => {
        dispatch(removeRegister());
      }, [10000]);
  }, [signupMessage]);

  useEffect(() => {
    token && dispatch(fetchGetMyWallet(token));
    token && dispatch(fetchGetProductByIdUser(product.id, token));
  }, [token]);

  // get email and paswword form local storage
  const userName = localStorage.getItem("userName");
  const password = localStorage.getItem("password");

  // set formik initial values
  const initialValues = {
    userName: userName ? userName : "",
    password: password ? password : "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    userName: Yup.string().email("Invalid userName").required("Required *"),
    password: Yup.string().required("Required *"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchLogin(values));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeLogin());
    }, 10000);
  };

  const showSignupModal = () => {
    setAuthenticateCheck(false);
    setSignupPopup(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="absolute w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 px-10 pb-10 pt-5 mx-auto shadow-lg left-4 md:left-[14%] lg:left-[27%] xl:left-1/3 border top-28 md:top-32 rounded-lg bg-white z-30">
      {succMsg && (
        <Message
          className="w-fit text-green-500 font-bold text-xl"
          message={succMsg}
          setMessage={setSuccMsg}
        />
      )}

      {authMsg && (
        <Message
          className="text-red-500 font-bold text-xl"
          message={authMsg}
          setMessage={setAuthMsg}
        />
      )}

      {signupMsg && (
        <Message
          className="text-green-500 font-bold text-xl"
          message={signupMsg}
          setMessage={setSignupMsg}
        />
      )}

      {errMsg && (
        <Message
          className="text-red-500 font-bold text-xl"
          message={errMsg}
          setMessage={setErrMsg}
        />
      )}
      <div className="flex justify-end">
        <img
          src={closeIcon}
          alt="close Icon"
          className="w-10 h-10 cursor-pointer"
          onClick={() => setAuthenticateCheck(false)}
        />
      </div>
      <Header
        text="Welcome"
        message="Back"
        info="You can log into your bidyl account"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <div className=" w-full">
              <FormikControl
                label="Email Address"
                placeholder="Enter your email"
                control="input"
                name="userName"
                type="email"
              />
              <div className="mt-5">
                <FormikControl
                  label="Password"
                  placeholder="Enter your password"
                  control="password"
                  name="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>

              <div className="mt-5">
                <Button
                  type="submit"
                  className="bg-primary font-medium py-3 rounded-2xl text-white w-full"
                  value="Login"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="flex items-center justify-center gap-x-2 mt-3">
        <p>Don't have an account?</p>

        <p
          className="text-primary font-bold cursor-pointer"
          onClick={showSignupModal}
        >
          Sign up
        </p>
      </div>
    </div>
  );
};

export default LoginPopupModal;
