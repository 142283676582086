import React from "react";

const Header = ({ heading, text, heading2, effective }) => {
  return (
    <div className="text-center">
      <h3 className="font-bold text-2xl md:text-4xl">{heading}</h3>
      <h6 className="text-base md:text-xl text-secondary mt-5">{text}</h6>

      <h4 className="uppercase font-bold text-xl md:text-3xl text-primary mt-10 md:mt-14">
        {heading2}
      </h4>
      <h5 className="text-primary text-base md:text-2xl font-bold mt-3">
        Effective: {effective}
      </h5>
    </div>
  );
};

export default Header;
