import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const cartLoading = () => ({
  type: ActionTypes.CART_LOADING,
});

export const removeCartData = () => ({
  type: ActionTypes.REMOVE_CART_DATA,
});

export const fetchAddItemToCart = (body, token) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/car/add-item`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_CART,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_ADD_ITEM,
        payload: e.response.data,
      });
    });
};

export const fetchGetCartData = (token) => async (dispatch) => {
  try {
    dispatch(cartLoading());
    const data = await axios.get(`${BaseUrl}/bidyl/v1/api/car/get-my-own`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const response = dispatch({
      type: ActionTypes.GET_CART_DATA,
      payload: data.data,
    });
    return response;
  } catch (error) {
    console.log("error from get cart data action");
  }
};

export const fetchRemoveOrder = (orderId, token) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .delete(`${BaseUrl}/bidyl/v1/api/car/delete-order/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REMOVE_ORDER,
        payload: data,
      });
    });
};

export const fetchRemoveAllOrder = (token) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .delete(`${BaseUrl}/bidyl/v1/api/car/delete-all`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REMOVE_ALL_ORDER,
        payload: data,
      });
    });
};

export const fetchIncreaseQuantity = (orderId, token) => (dispatch) => {
  dispatch(cartLoading());
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(
    `${BaseUrl}/bidyl/v1/api/car/update-order-quantity/${orderId}?quantity=1`,
    config
  ).then(({ data }) => {
    dispatch({
      type: ActionTypes.INCREASE_ORDER_QUANTITY,
      payload: data,
    });
  });
};

export const fetchDecreaseQuantity = (orderId, token) => (dispatch) => {
  dispatch(cartLoading());

  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(
    `${BaseUrl}/bidyl/v1/api/car/subtract-order-quantity/${orderId}?quantity=1`,
    config
  ).then(({ data }) => {
    dispatch({
      type: ActionTypes.DECREASE_ORDER_QUANTITY,
      payload: data,
    });
  });
};

// temporary cart API
export const fetchAddItemToTempCart = (body, cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .post(
      `${
        cartId
          ? `${BaseUrl}/bidyl/v1/api/car/temporary/add-item?cartId=${cartId}`
          : `${BaseUrl}/bidyl/v1/api/car/temporary/add-item`
      } `,
      body
    )
    .then(({ data }) => {
      localStorage.setItem("cartId", data.id);
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_TEMP_CART,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_TO_ADD_ITEM_IN_TEMP_CART,
        payload: e.response.data,
      });
    });
};

export const fetchGetTempCartData = (cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .get(`${BaseUrl}/bidyl/v1/api/car/temporary/get-by-id/${cartId}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_TEMP_CART_DATA,
        payload: data,
      });
    });
};

export const fetchRemoveTempOrder = (orderId, cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .delete(
      `${BaseUrl}/bidyl/v1/api/car/temporary/delete-order/${cartId}?orderId=${orderId}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_TEMP_ORDER,
        payload: data,
      });
    });
};

export const fetchRemoveAllTempOrder = (cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .delete(`${BaseUrl}/bidyl/v1/api/car/temporary/delete-all/${cartId}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_ALL_TEMP_ORDER,
        payload: data,
      });
    });
};

export const fetchIncreaseTempQuantity = (orderId, cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .patch(
      `${BaseUrl}/bidyl/v1/api/car/temporary/update-order-quantity/${cartId}?quantity=1&orderId=${orderId}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.INCREASE_TEMP_ORDER_QUANTITY,
        payload: data,
      });
    });
};

export const fetchDecreaseTempQuantity = (orderId, cartId) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .patch(
      `${BaseUrl}/bidyl/v1/api/car/temporary/subtract-order-quantity/${cartId}?quantity=1&orderId=${orderId}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DECREASE_TEMP_ORDER_QUANTITY,
        payload: data,
      });
    });
};

// temporary cart API end

export const fetchAddToCartFromTempCart = (body, token) => (dispatch) => {
  dispatch(cartLoading());
  return axios
    .post(`${BaseUrl}/bidyl/v1/api/car/add-temporary-item`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TO_CART_FROM_TEMP_CART,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_ADD_TO_CART_FROM_TEMP_CART,
        payload: e.response.data,
      });
    });
};
