import moment from "moment/moment";

export const convertTime = (time) => {
  return moment.utc(time).local().format("HH:mm");
};

export const convertDate = (date) => {
  return moment.utc(date).local().format("DD-MM-YYYY");
};

export const convertDateTime = (data) => {
  return moment.utc(data).local().format("DD-MM-YYYY HH:MM");
};
