import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const VideoCarousel = ({ video }) => {
  const bannerSliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customImage">
        <div className="loading" />
      </div>
    ),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-full">
      <Slider {...settings} ref={bannerSliderRef}>
        {video &&
          video.map((banner) => {
            return (
              <div
                key={banner.id}
                className="w-full  h-[400px] md:h-[768px] relative"
              >
                <video
                  controls
                  className=" h-[400px] md:h-[768px] w-full object-cover px-2"
                >
                  <source src={banner.image} />
                </video>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default VideoCarousel;
