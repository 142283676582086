import React from "react";

const SinglePagnination = ({
  setPage,
  page,
  totalData,
  totalPage,
  navigateTop,
}) => {
  const pageNumber = [];

  // pushing page number indexing in array
  if (totalData) {
    for (let i = 1; i <= totalPage; i++) {
      pageNumber.push(i);
    }
  }

  const handlePageChangler = (data) => {
    setPage(data);
    navigateTop();
  };
  return (
    <div className="flex gap-x-4 w-80 mx-auto justify-center items-center">
      {pageNumber.map((data, i) => {
        return (
          <div key={data}>
            <div
              className={`${
                page === data
                  ? "bg-primary text-white"
                  : "bg-gray-100 text-primary"
              } w-10 cursor-pointer rounded-lg text-center py-2 h-full text-base`}
              onClick={() => handlePageChangler(data)}
            >
              <h1 className="cursor-pointer">{data}</h1>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SinglePagnination;
